export const DEPARTURE_TYPE_MAPPING = {
    'DEP-HAM': 'Hamburg',
    'DEP-HAJ': 'Hannover',
    'DEP-BRE': 'Bremen',
    'DEP-LBC': 'Lübeck',
    'DEP-TXL': 'Berlin-Tegel',
    'DEP-SXF': 'Berlin-Schönefeld',
    'DEP-LEJ': 'Leipzig',
    'DEP-DRS': 'Dresden',
    'DEP-RLG': 'Rostock-Laage',
    'DEP-ERF': 'Erfurt',
    'DEP-CSO': 'Cochstedt',
    'DEP-MUC': 'München',
    'DEP-STR': 'Stuttgart',
    'DEP-NUE': 'Nürnberg',
    'DEP-FDH': 'Friedrichshafen',
    'DEP-FKB': 'Karlsruhe-Baden',
    'DEP-FMM': 'Memmingen',
    'DEP-FRA': 'Frankfurt',
    'DEP-DUS': 'Düsseldorf',
    'DEP-CGN': 'Köln/Bonn',
    'DEP-FMO': 'Münster/Osnabrück',
    'DEP-DTM': 'Dortmund',
    'DEP-HHN': 'Hahn',
    'DEP-NRN': 'Weeze',
    'DEP-PAD': 'Paderborn',
    'DEP-SCN': 'Saarbrücken',
    'DEP-KSF': 'Kassel',
    'DEP-VIE': 'Wien',
    'DEP-LUG': 'Lugano (CH)',
    'DEP-SZG': 'Salzburg',
    'DEP-INN': 'Innsbruck',
    'DEP-LNZ': 'Linz',
    'DEP-GRZ': 'Graz',
    'DEP-KLU': 'Klagenfurt',
    'DEP-ZRH': 'Zürich',
    'DEP-GVA': 'Genf',
    'DEP-BSL': 'Basel',
    'DEP-BRN': 'Bern',
    'DEP-BRU': 'Brüssel (B)',
    'DEP-AMS': 'Amsterdam (NL)',
    'DEP-RTM': 'Rotterdam (NL)',
    'DEP-MST': 'Maastricht (NL)',
    'DEP-EIN': 'Eindhoven (NL)',
    'DEP-GRQ': 'Groningen (NL)',
    'DEP-LUX': 'Luxemburg (L)',
    'DEP-TLS': 'Toulouse',
    'DEP-NTE': 'Nantes',
    'DEP-NCE': 'Nizza',
    'DEP-MRS': 'Marseille',
    'DEP-MPL': 'Montpellier',
    'DEP-LYS': 'Lyon',
    'DEP-LIL': 'Lille',
    'DEP-ETZ': 'Metz/Nancy',
    'DEP-BOD': 'Bordeaux',
    'DEP-RNS': 'Rennes',
    'DEP-CDG': 'Paris',
    'DEP-BES': 'Brest',
    'DEP-SXB': 'Strassburg (F)',
    'DEP-MLH': 'Mühlhausen (F)',
    'DEP-PRG': 'Prag (CZ)',
    'DEP-WAW': 'Warschau (PL)',
    'DEP-LIN': 'Mailand Linate (IT)',
    'DEP-MXP': 'Mailand Malpensa (IT)',
    'DEP-POZ': 'Poznan (PL)',
    'DEP-GDN': 'Gdansk (PL)',
    'DEP-KRK': 'Krakow (PL)',
    'DEP-WRO': 'Wroclaw (PL)',
    'DEP-RZE': 'Rzeszów-Jasionka (PL)',
    'DEP-SZZ': 'Stettin-Goleniów (PL)',
    'DEP-KTW': 'Katowice (PL)',
    'DEP-BUD': 'Budapest (HU)',
    'DEP-OTP': 'Bukarest (RO)',
    'DEP-CLJ': 'Cluj (RO)',
    'DEP-LHR': 'London (GB)',
    'DEP-LJU': 'Ljubljana (SI)',
    'DEP-SOF': 'Sofia (BG)',
    'DEP-SJJ': 'Sarajevo (BA)',
    'DEP-ZAG': 'Zagreb (HR)',
    'DEP-BEG': 'Belgrad (SR)'
}
