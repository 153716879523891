import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'button-group',
	templateUrl: './button-group.component.html',
	styleUrls: ['./button-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupComponent {
	@HostBinding('class.expand-first')
	@Input() public expandFirst: boolean = false;
}
