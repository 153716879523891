<bar-loader #loadingBar *ngIf="vanessaService.showGroupsLoader$ | async" class="bar-loader-segment"></bar-loader>

<h2 class="heading" *ngIf="source.length && !(vanessaService.showGroupsLoader$ | async)">Hotelauswahl</h2>

<wj-flex-grid
	id="intro_hotel_multiselect"
	#groups
	[headersVisibility]="'Column'"
	[isReadOnly]="true"
	[itemsSource]="source"
	[selectionMode]="'Row'"
	[showAlternatingRows]="true"
	(selectionChanged)="selectRow(grid.selectedRows)"
	(itemsSourceChanged)="setSelection()"
	(loadingRows)="setSelection()"
	(loadedRows)="setSelection()"
	(updatedLayout)="updatedLayout(groups)"
	(initialized)="initGrid(grid, $event)"
	class="custom-flexgrid selection-grid"
>
	<wj-flex-grid-column
		#hotelCell
		[binding]="'hotel.name'"
		header="Hotelname"
		[minWidth]="100"
		[width]="'2*'"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<div class="custom-cell">
				<span class="truncate">{{cell.item.hotel?.name}}</span>
			</div>
		</ng-template>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#categoryCell
		[binding]="'hotel.tags.CAT'"
		header="Kategorie"
		[minWidth]="100"
		[width]="100"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<div class="custom-cell">
				<category-stars [value]="cell.item.hotel?.tags?.CAT"></category-stars>
			</div>
		</ng-template>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#ratingCell
		[align]="'right'"
		[binding]="'hotel.tags.RATING'"
		header="%"
		[minWidth]="60"
		[width]="60"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr title="Hotelbewertung"
			>
				{{cell.col.header}}
			</abbr>
		</ng-template>

		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<span *ngIf="cell.item.hotel?.tags?.RATING"
				  class="rating"
			>
				{{cell.item.hotel?.tags?.RATING}} %
			</span>
		</ng-template>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#destinationCell
		[binding]="'transports.outbound.destinationCode'"
		header="Ziel"
		[minWidth]="70"
		[width]="70"
	>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#cityCell
		[binding]="'hotel.city._'"
		header="Ort"
		[minWidth]="100"
		[width]="'*'"
	>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#tourOperatorCell
		[align]="'right'"
		[binding]="'alternateTourOperatorsCount'"
		header="VA"
		[minWidth]="60"
		[width]="60"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr title="Veranstalter">
				{{cell.col.header}}
			</abbr>
		</ng-template>

	</wj-flex-grid-column>


	<wj-flex-grid-column
		#priceCell
		[align]="'right'"
		[allowResizing]="false"
		[binding]="'price'"
		header="€"
		[minWidth]="70"
		[width]="'*'"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr title="Preis pro Person"
			>
				{{cell.col.header}}
			</abbr>
		</ng-template>
	</wj-flex-grid-column>
</wj-flex-grid>
<span *ngIf="!source.length && !(vanessaService.showGroupsLoader$ | async)">Zu den ausgewählten Parametern wurden keine Hotels gefunden. Bitte ändern Sie ihre Auswahl.</span>

