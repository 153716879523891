export const SPORT_TYPE_MAPPING = {
    'GT03-AERO': 'Aerobic',
    'GT03-ARCH': 'Bogenschießen',
    'GT03-BABO': 'Bananenboot',
    'GT03-BADM': 'Badminton',
    'GT03-BASK': 'Basketball',
    'GT03-BESP': 'Strandsport',
    'GT03-BEVO': 'Strandvolleyball',
    'GT03-BILL': 'Billard',
    'GT03-BOAL': 'Bowlingbahn',
    'GT03-BOCC': 'Boccia',
    'GT03-CANO': 'Kanu',
    'GT03-CATA': 'Katamaran',
    'GT03-DART': 'Dart',
    'GT03-FITN': 'FItness',
    'GT03-GOLF': 'Golf',
    'GT03-GYMN': 'Gymnastik',
    'GT03-HORI': 'Reiten',
    'GT03-MINI': 'Minigolf',
    'GT03-PEBO': 'Tretboot',
    'GT03-SAIL': 'Segeln',
    'GT03-SQUA': 'Squash',
    'GT03-SURF': 'Wellenreiten/Surfen',
    'GT03-TATE': 'Tischtennis',
    'GT03-TENN': 'Tennis',
    'GT03-WATE': 'Wasserski',
    'GT03-WIND': 'Windsurfen',
}