import { AuthService } from './services/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
		location.hash = '';
		return true;

        /*if (this.authService.isLoggedIn() && (!location.hash || location.hash == '')) {
            return true;
        }

        if (location.hash !== '') {
            let token = this.authService.getHashFragment('access_token'),
                expires = this.authService.getHashFragment('expires_in'),
                expiration = moment().add(expires);

            if (expiration.format() !== '' && expiration.isValid()) {
                this.authService.setExpiration(expires);
            }

            if (token !== '') {
                this.authService.setToken(token);
            }

            location.hash = '';
            this.router.navigateByUrl(location.pathname);
            return true;
        }

        this.authService.login();
        return false;*/
    }
}
