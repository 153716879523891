<!-- Modal Structure -->
<div id="wizard" class="modal-fixed-footer" materialize="modal" [materializeParams]="[{dismissible: false}]" [materializeActions]="modalActions$">


	<div class="box" style="overflow: hidden">
		<div [hidden]="wizardService.isSaving" style="overflow: hidden">
			<ul class="tabs tabs-fixed-width" materialize="tabs" [materializeParams]="wizardService.tabParam">
				<li class="tab" [class.disabled]="!wizardService.stepChangeIsAllowed(1)"
					(click)="wizardService.changeStep(1)"><a href="#topbox-select">Systemangebot
					wählen</a></li>
				<li class="tab" [class.disabled]="!wizardService.stepChangeIsAllowed(2)"
					(click)="wizardService.changeStep(2)"><a href="#travel-period-hotel">Reisezeitraum
					& Unterkunft</a></li>
				<li class="tab" [class.disabled]="!wizardService.stepChangeIsAllowed(3)"
					(click)="wizardService.changeStep(3)">
					<a *ngIf="wizardService.currentStep != 4" href="#hotel-selection">Hotel- & Angebotsauswahl</a>
					<a *ngIf="wizardService.currentStep != 3" href="#offer-selection">Hotel- & Angebotsauswahl</a>
				</li>
				<li class="tab" [class.disabled]="!wizardService.stepChangeIsAllowed(5)"
					(click)="wizardService.changeStep(5)"><a href="#deeplink-preview">Angebotsvorschau</a>
				</li>
			</ul>
		</div>
	</div>

	<div class="box">
		<div class="modal-content">
			<div id="topbox-select" class="tab-content">
				<app-select-topbox></app-select-topbox>
			</div>

			<div id="travel-period-hotel" class="tab-content">
				<app-travel-period-hotel *ngIf="topboxService.deeplinkSet$ | async" (disableNextButton$)="onDisableNextButton($event)"></app-travel-period-hotel>
			</div>

			<div id="hotel-selection" class="tab-content">
				<app-hotel-selection (disableNextButton$)="onDisableNextButton($event)"></app-hotel-selection>
			</div>

			<div id="offer-selection" class="tab-content">
				<app-offer-selection (disableNextButton$)="onDisableNextButton($event)"></app-offer-selection>
			</div>

			<div id="deeplink-preview" class="tab-content">
				<app-deeplink-preview *ngIf="!wizardService.isSaving && (vanessaService.groupIsSelected$ | async)"></app-deeplink-preview>
				<div class="preloader-wrapper active" *ngIf="wizardService.isSaving">
					<div class="spinner-layer spinner-smg-only">
						<div class="circle-clipper left">
							<div class="circle"></div>
						</div>
						<div class="gap-patch">
							<div class="circle"></div>
						</div>
						<div class="circle-clipper right">
							<div class="circle"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="box">
		<div class="modal-footer" *ngIf="wizardService.showFooter">
			<gosuite-button
				class="btn-next"
				[disabled]="_disableNextButton"
				[primary]="true"
				type="button"
				label="Weiter"
				(onClick)="wizardService.nextStep()"
			></gosuite-button>
			<gosuite-button
				class="btn-back"
				[primary]="false"
				type="button"
				label="Zurück"
				(onClick)="wizardService.previousStep(); _disableNextButton = false"
			></gosuite-button>
			<div style="clear:both"></div>
		</div>
	</div>

	<div class="box">
		<div class="modal-footer" *ngIf="wizardService.showFinishFooter">
			<gosuite-button
				class="btn-next"
				[disabled]="_disableNextButton"
				[primary]="true"
				type="button"
				label="Speichern"
				(onClick)="wizardService.nextStep()"
			></gosuite-button>
			<gosuite-button
				class="btn-back"
				[primary]="false"
				type="button"
				label="Zurück"
				(onClick)="wizardService.previousStep()"
			></gosuite-button>
			<div style="clear:both"></div>
		</div>
	</div>

</div>
