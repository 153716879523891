import { MetropolitanAreaCodeModel } from '../models/metropolitan-area-code.model';

export const METROPOLITAN_AREA_CODES: Array<MetropolitanAreaCodeModel> = [
	{
		code: 'BKK',
		translation: 'Bangkok',
		airports: [
			'BKK',
			'DMK'
		]
	},
	{
		code: 'BJS',
		translation: 'Beijing',
		airports: [
			'PEK',
			'NAY'
		]
	},
	{
		code: 'OSA',
		translation: 'Osaka',
		airports: [
			'KIX',
			'ITM',
			'UKB'
		]
	},
	{
		code: 'SPK',
		translation: 'Sapporo',
		airports: [
			'CTS',
			'OKD'
		]
	},
	{
		code: 'SEL',
		translation: 'Seoul',
		airports: [
			'ICN',
			'GMP'
		]
	},
	{
		code: 'SHA',
		translation: 'Shanghai',
		airports: [
			'SHA',
			'PVG'
		]
	},
	{
		code: 'TYO',
		translation: 'Tokyo',
		airports: [
			'NRT',
			'HND'
		]
	},
	{
		code: 'JKT',
		translation: 'Jakarta',
		airports: [
			'CGK',
			'HLP'
		]
	},
	{
		code: 'BER',
		translation: 'Berlin',
		airports: [
			// 'BER',
			'TXL',
			'SXF'
		]
	},
	{
		code: 'BUH',
		translation: 'Bucharest',
		airports: [
			'OTP',
			'BBU'
		]
	},
	{
		code: 'BRU',
		translation: 'Brussels',
		airports: [
			'BRU',
			'CRL'
		]
	},
	{
		code: 'LON',
		translation: 'London',
		airports: [
			'BQH',
			'LCY',
			'LGW',
			'LTN',
			'LHR',
			'SEN',
			'STN'
		]
	},
	{
		code: 'MIL',
		translation: 'Milan',
		airports: [
			'BGY',
			'MXP',
			'LIN',
			'PMF'
		]
	},
	{
		code: 'MOW',
		translation: 'Moskow',
		airports: [
			'SVO',
			'DME',
			'VKO',
			'BKA'
		]
	},
	{
		code: 'OSL',
		translation: 'Oslo',
		airports: [
			'OSL',
			'TRF',
			'RYG'
		]
	},
	{
		code: 'PAR',
		translation: 'Paris',
		airports: [
			'CDG',
			'ORY',
			'LBG'
		]
	},
	{
		code: 'REK',
		translation: 'Reykjavik',
		airports: [
			'KEF',
			'RKV'
		]
	},
	{
		code: 'ROM',
		translation: 'Rome',
		airports: [
			'FCO',
			'CIA'
		]
	},
	{
		code: 'STO',
		translation: 'Stockholm',
		airports: [
			'ARN',
			'NYO',
			'BMA',
			'VST'
		]
	},
	{
		code: 'TCI',
		translation: 'Tenerife',
		airports: [
			'TFN',
			'TFS'
		]
	},
	{
		code: 'CHI',
		translation: 'Chicago',
		airports: [
			'ORD',
			'MDW',
			'RFD'
		]
	},
	{
		code: 'DFW',
		translation: 'Dallas',
		airports: [
			'DAL',
			'DFW'
		]
	},
	{
		code: 'DTT',
		translation: 'Detroit',
		airports: [
			'DTW',
			'DET',
			'YIP'
		]
	},
	{
		code: 'YEA',
		translation: 'Edmonton',
		airports: [
			'YEG'
		]
	},
	{
		code: 'HOU',
		translation: 'Houston',
		airports: [
			'IAH',
			'HOU'
		]
	},
	{
		code: 'LAX',
		translation: 'Los Angeles',
		airports: [
			'LAX'
		]
	},
	{
		code: 'QMI',
		translation: 'Miami',
		airports: [
			'MIA',
			'FLL',
			'PBI'
		]
	},
	{
		code: 'YMQ',
		translation: 'Montreal',
		airports: [
			'YUL',
			'YMY'
		]
	},
	{
		code: 'NYC',
		translation: 'New York City',
		airports: [
			'JFK',
			'EWR',
			'LGA',
			'HPN'
		]
	},
	{
		code: 'YTO',
		translation: 'Toronto',
		airports: [
			'YYZ',
			'YTZ',
			'YKF'
		]
	},
	{
		code: 'WAS',
		translation: 'Washington DC',
		airports: [
			'IAD',
			'DCA',
			'BWI'
		]
	},
	{
		code: 'BHZ',
		translation: 'Belo Horizonte',
		airports: [
			'CNF',
			'PLU'
		]
	},
	{
		code: 'BUE',
		translation: 'Buenos Aires',
		airports: [
			'EZE',
			'AEP'
		]
	},
	{
		code: 'RIO',
		translation: 'Rio de Janeiro',
		airports: [
			'GIG',
			'SDU'
		]
	},
	{
		code: 'SAO',
		translation: 'São Paulo',
		airports: [
			'GRU',
			'CGH',
			'VCP'
		]
	}
];
