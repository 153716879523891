import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {VanessaService} from '../services/vanessa.service';
import {WizardService} from '../wizard/wizard.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchFormParameters, parseToDeeplinkModel} from '../shared/models/query/search-form.model';
import {Deeplink} from '../shared/models/deeplink/deeplink.model';
import {isString} from 'util';
import {Observable, Subscription} from 'rxjs';
import * as moment from 'moment';
import {Dates} from '@gosuite/range-datepicker';
import * as _ from 'lodash';
import {QueryTypes} from '../shared/models/query/query-type.model';
import {StateManagerService} from '../services/state-manager.service';

@Component({
	selector: 'app-travel-period-hotel',
	templateUrl: './travel-period-hotel.component.html',
	styleUrls: ['./travel-period-hotel.component.scss']
})

export class TravelPeriodHotelComponent implements OnDestroy {
	private _step: number = 2;
	public _queryTypes = QueryTypes;
	private _subscriptions: Subscription[];

	@Output() disableNextButton$: EventEmitter<boolean> = new EventEmitter();

	public searchform: FormGroup;

	constructor(public vanessaService: VanessaService, private wizardService: WizardService,
				private formBuilder: FormBuilder, private stateManagerService: StateManagerService) {
		this._subscriptions = [];

		const initialSearchFormState: SearchFormParameters = this.stateManagerService.getActiveSearchFormState(this._step);

		this.searchform = this.formBuilder.group({
			selectDate: [initialSearchFormState.selectDate],
			date: [initialSearchFormState.date, [Validators.required]],
			relativeDateFrom: [initialSearchFormState.relativeDateFrom, [Validators.min(0)]],
			relativeDateTo: [initialSearchFormState.relativeDateTo, [Validators.min(1)]],
			minDuration: [initialSearchFormState.minDuration, [Validators.required, Validators.min(1), Validators.max(21)]],
			maxDuration: [initialSearchFormState.maxDuration, [Validators.min(1), Validators.max(21)]],
			destination: [initialSearchFormState.destination, [Validators.required]],
			departure: [initialSearchFormState.departure, []],
			adultCount: [initialSearchFormState.adultCount, [Validators.required]],
			category: [initialSearchFormState.category, [Validators.pattern(new RegExp(/^[1-7]([,.][5])?$/))]],
			board: [initialSearchFormState.board, []],
			childAge1: [initialSearchFormState.childAge1, [Validators.min(0), Validators.max(17)]],
			childAge2: [initialSearchFormState.childAge2, [Validators.min(0), Validators.max(17)]],
			childAge3: [initialSearchFormState.childAge3, [Validators.min(0), Validators.max(17)]],
			childAge4: [initialSearchFormState.childAge4, [Validators.min(0), Validators.max(17)]],
			rating: [initialSearchFormState.rating, [Validators.pattern(new RegExp(/^100$|^[7-9]0$/))]],
			operator: [initialSearchFormState.operator, []],
			roomType: [initialSearchFormState.roomType, []],
			attributes: [initialSearchFormState.attributes, []],
			offset: [initialSearchFormState.offset],
			type: [initialSearchFormState.type],
			transfer: [initialSearchFormState.transfer],
			hotelName: [initialSearchFormState.hotelName],
			twoWay: [initialSearchFormState.twoWay],
			wellnessAttributes: [initialSearchFormState.wellnessAttributes],
			sportAttributes: [initialSearchFormState.sportAttributes],
			targetGroupAttributes: [initialSearchFormState.targetGroupAttributes],
			hotelChain: [initialSearchFormState.hotelChain],
			nonStopFlight: [initialSearchFormState.nonStopFlight],
			railAndFly: [initialSearchFormState.railAndFly],
			seaView: [initialSearchFormState.seaView],
			maxPrice: [initialSearchFormState.maxPrice],
		});

		this.searchform.statusChanges.map((status) => {
			if (!this.stateManagerService.isEqual(this.toSearchFormModel(),
					this.stateManagerService.getActiveSearchFormState(this._step))) {
				this.vanessaService.groupIsSelected$.next(false);
			} else {
				if (this.vanessaService.selectedGroup) {
					this.vanessaService.groupIsSelected$.next(true);
				}
			}

			return status;
		}).distinctUntilChanged().subscribe((status) => {
			if (status === 'INVALID') {
				this.disableNextButton$.emit(true);
			} else if (status === 'VALID') {
				this.disableNextButton$.emit(false);
			}
		});

		this._subscriptions.push(this.wizardService.stepChange$.filter(step => step === this._step)
			.subscribe(() => this.updateStates()));
	}

	private toSearchFormModel(): SearchFormParameters {
		const searchFormState: SearchFormParameters = this.stateManagerService.getActiveSearchFormState(this._step);
		let searchFormModel: SearchFormParameters = this.searchform.getRawValue();

		searchFormModel.date = new Dates(new Date(searchFormModel.date.startDate), new Date(searchFormModel.date.endDate));

		searchFormModel = _.assign(searchFormState, searchFormModel);

		return searchFormModel;
	}

	private updateStates(): void {
		const searchFormModel: SearchFormParameters = this.toSearchFormModel();
		let deeplink: Deeplink = this.stateManagerService.getActiveDeeplinkState(this._step);

		if (!this.stateManagerService.isEqual(searchFormModel, this.stateManagerService.getActiveSearchFormState(this._step))) {
			this.vanessaService.showAllOffers = true;
			deeplink.parameters.offerId = null;

			deeplink = parseToDeeplinkModel(searchFormModel, deeplink);
		}

		searchFormModel.tags = '';

		this.stateManagerService.setCurrentSearchFormState(searchFormModel, this._step);
		this.stateManagerService.setDeeplinkState(deeplink, this._step);
	}

	public onDepartureSearchTextChange(searchStr: string): void {
		this.vanessaService.departures$ = this.vanessaService.getDeparturesBySearchStr(searchStr);
	}

	public onDestinationSearchTextChange(searchStr: string): void {
		if (isString(searchStr) && searchStr.length >= 3) {
			this.vanessaService.destinations$ = this.vanessaService.getDestinationsBySearchStr(searchStr);
		} else {
			this.vanessaService.destinations$ = Observable.of([]);
		}
	}

	public onHotelNameSearchTextChange(searchStr: string): void {
		searchStr = searchStr.trim();

		this.vanessaService.hotels$ = searchStr.length
			? this.vanessaService.getHotelGiataIdOrCode(searchStr)
			: Observable.of([]);
	}

	public onHotelChainSearchTextChange(searchStr: string): void {
		searchStr = searchStr.trim();

		if (isString(searchStr) && searchStr.length >= 3) {
			this.vanessaService.hotelChains$ = this.vanessaService.getHotelChainsBySearchStr(searchStr);
		} else {
			this.vanessaService.hotelChains$ = Observable.of([]);
		}
	}

	public onTypeChange(type: string): void {
		this.vanessaService.changeType(type);

		if (type === QueryTypes.NH) {
			this.searchform.patchValue({'departure': []});
		}
	}

	public onSelectDateChange(selectDate: string): void {
		this.vanessaService.currentSelectDate = selectDate;
	}

	public onAbsoluteDateChange(absoluteDate: Dates): void {
		let daysFrom, daysTo: number;

		daysFrom = Math.ceil(moment(absoluteDate.startDate).diff(moment(), 'days', true));
		daysTo = Math.ceil(moment(absoluteDate.endDate).diff(moment(), 'days', true));

		this.searchform.patchValue({
			'relativeDateFrom': daysFrom,
			'relativeDateTo': daysTo
		});
	}

	public onRelativeDateFromChange(relativeDate: number): void {
		this.searchform.controls.date.value.startDate = moment().add(relativeDate, 'days').format('YYYY-MM-DD');
	}

	public onRelativeDateToChange(relativeDate: number): void {
		this.searchform.controls.date.value.endDate = moment().add(relativeDate, 'days').format('YYYY-MM-DD');
	}

	ngOnDestroy() {
		this._subscriptions.forEach(val => val.unsubscribe());
	}
}
