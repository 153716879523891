import {Component, OnDestroy} from '@angular/core';
import {TopboxService} from '../services/topbox.service';
import {VanessaService} from '../services/vanessa.service';
import {Deeplink, DeeplinkImage} from '../shared/models/deeplink/deeplink.model';
import {HotelviewService} from '../services/hotelview.service';
import {StateManagerService} from '../services/state-manager.service';
import {WizardService} from '../wizard/wizard.service';
import {Subscription} from "rxjs/Rx";

@Component({
    selector: 'app-deeplink-preview',
    templateUrl: './deeplink-preview.component.html',
    styleUrls: ['./deeplink-preview.component.scss']
})

export class DeeplinkPreviewComponent implements OnDestroy {
    public _deeplink: Deeplink;

    private _step: number = 5;
    public _availableGroupOperators = [];
    private _selectedGroupOperator = '';
    private _subscriptions: Subscription[];

    constructor(public topboxService: TopboxService,
                public vanessaService: VanessaService,
                public hotelviewService: HotelviewService,
                private stateManagerService: StateManagerService,
                private wizardService: WizardService) {
        this._subscriptions = [];

        this._deeplink = stateManagerService.getActiveDeeplinkState(this._step);

        this._subscriptions.push(vanessaService.groupIsSelected$.filter(val => val === true).subscribe(() => {
            this._deeplink = stateManagerService.getActiveDeeplinkState(this._step);

            this._availableGroupOperators = this.vanessaService.selectedGroup.alternateTourOperators.length
                ? this.vanessaService.selectedGroup.alternateTourOperators
                : new Array(this.vanessaService.selectedGroup.tourOperator);

            this._selectedGroupOperator = this.vanessaService.selectedGroup.tourOperator;
            this.hotelviewService.getImages(this.vanessaService.selectedGroup.hotel.locationId,
                this.vanessaService.selectedGroup.tourOperator);
        }));

        this._subscriptions.push(wizardService.stepChange$.filter(step => step === this._step)
            .subscribe(() => this.stateManagerService.setDeeplinkState(this._deeplink, this._step)));
    }

    onSelectedImageChange(image: DeeplinkImage) {
        this.topboxService.newImage = image;
    }

    onCheckDeeplinkClick() {
        this.topboxService.deeplinkChecked = true;
    }

    onSelectedOperatorChange(operator: string) {
        this.hotelviewService.getImages(this.vanessaService.selectedGroup.hotel.locationId, operator);
    }

    ngOnDestroy() {
        this._subscriptions.forEach(val => val.unsubscribe());
    }
}
