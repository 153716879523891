import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGroupComponent } from './button-group/button-group.component';

@NgModule({
	imports: [ CommonModule ],
	exports: [ ButtonGroupComponent ],
	declarations: [ ButtonGroupComponent ]
})
export class ButtonGroupModule {}
