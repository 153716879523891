<h2 class="heading">Reisedaten</h2>

<form [formGroup]="searchform">
    <div class="l-grid">
        <div class="l-grid-cell l-grid-cell-1-4">
            <button-group>
                <input (click)="onTypeChange($event.target.value)" id="pa" type="radio" class="searchform-radio-btn"
                       formControlName="type" value="PA">
                <label for="pa" class="btn">Pauschalreise</label>
                <input (click)="onTypeChange($event.target.value)" id="nh" type="radio" class="searchform-radio-btn"
                       formControlName="type" value="NH">
                <label for="nh" class="btn">Hotel</label>
            </button-group>
        </div>
    </div>
    <div class="l-grid" [ngSwitch]="vanessaService.currentQueryType">
        <ng-container *ngSwitchCase="_queryTypes.PA">
            <div class="l-grid-cell l-grid-cell-1-4">
                <gosuite-autocomplete
                        formControlName="departure"
                        placeholder="Abflughafen"
                        placeholderNoResults="Leider haben wir zu Ihrem gesuchten Begriff nichts gefunden"
                        [items]="vanessaService.departures$ | async"
                        [showPreloader]="vanessaService.showDeparturePreloader$ | async"
                        (searchTextChange)="onDepartureSearchTextChange($event)"
                        [enableValueLabel]="true"
                ></gosuite-autocomplete>
            </div>
            <div class="l-grid-cell l-grid-cell-1-4">
                <button-group [expandFirst]="true">
                    <gosuite-autocomplete
                            formControlName="destination"
                            [showIcon]="false"
                            [enableValueLabel]="true"
                            (searchTextChange)="onDestinationSearchTextChange($event)"
                            [items]="vanessaService.destinations$ | async"
                            [showPreloader]="vanessaService.showDestinationPreloader$ | async"
                            placeholder="Region / Stadt* (PFLICHTFELD)"
                            placeholderText="Geben Sie einen Suchbegriff ein"
                            placeholderNoResults="Leider haben wir zu Ihrem gesuchten Begriff nichts gefunden"
							required="required"
                    >
                    </gosuite-autocomplete>
                </button-group>
            </div>
        </ng-container>
        <div class="l-grid-cell l-grid-cell-1-2" *ngSwitchCase="_queryTypes.NH">
            <button-group [expandFirst]="true">
                <gosuite-autocomplete
                        formControlName="destination"
                        [showIcon]="false"
                        [enableValueLabel]="true"
                        (searchTextChange)="onDestinationSearchTextChange($event)"
                        [items]="vanessaService.destinations$ | async"
                        [showPreloader]="vanessaService.showDestinationPreloader$ | async"
                        placeholder="Region / Stadt* (PFLICHTFELD)"
                        placeholderText="Geben Sie einen Suchbegriff ein"
                        placeholderNoResults="Leider haben wir zu Ihrem gesuchten Begriff nichts gefunden"
						required="required"
                >
                </gosuite-autocomplete>
            </button-group>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-input
                    type="number"
                    formControlName="category"
                    [min]="1"
                    [max]="7"
                    [step]="0.5"
                    placeholder="Kategorie"
            ></gosuite-input>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="operator"
                    placeholder="Veranstalter"
                    [enableSelectAllButton]="true"
                    [enableValueLabel]="true"
                    [defaultGt]="'TOP-'"
                    selectAllButtonText="Alle auswählen"
                    [items]="vanessaService.touroperators$ | async"
                    [filterSets]="vanessaService.filters"
                    [enableIcons]="true"
            ></gosuite-multi-select>
        </div>
    </div>
    <div class="l-grid" [ngSwitch]="vanessaService.currentSelectDate">
        <div class="l-grid-cell l-grid-cell-1-8">
            <button-group>
                <input (click)="onSelectDateChange($event.target.value)" id="absolute" type="radio"
                       class="searchform-radio-btn" formControlName="selectDate" value="1">
                <label for="absolute" class="btn">Absolutes Datum</label>
                <input (click)="onSelectDateChange($event.target.value)" id="relative" type="radio"
                       class="searchform-radio-btn" formControlName="selectDate" value="2">
                <label for="relative" class="btn">Relatives Datum</label>
            </button-group>
        </div>
        <div class="l-grid-cell l-grid-cell-1-3" *ngSwitchCase="1">
            <range-datepicker
                    formControlName="date"
                    placeholderStart="Hinreise"
                    placeholderEnd="Rückreise"
                    [defaultMin]="true"
                    (ngModelChange)="onAbsoluteDateChange($event)"
            >
            </range-datepicker>
        </div>
        <ng-container *ngSwitchCase="2">
            <div class="l-grid-cell l-grid-cell-1-3">
                <div class="l-grid">
                    <div class="l-grid-cell l-grid-cell-1-2">
                        <gosuite-input
                                type="number"
                                formControlName="relativeDateFrom"
                                [min]="1"
                                placeholder="Hinreise in Tagen"
                                (onChange)="onRelativeDateFromChange($event.target.value)"></gosuite-input>
                    </div>
                    <div class="l-grid-cell l-grid-cell-1-2 l-grid-cell-connected">
                        <gosuite-input
                                type="number"
                                formControlName="relativeDateTo"
                                [min]="1"
                                placeholder="Rückreise in Tagen"
                                (onChange)="onRelativeDateToChange($event.target.value)"></gosuite-input>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="l-grid-cell l-grid-cell-1-3">
            <div class="l-grid">
                <div class="l-grid-cell l-grid-cell-1-2">
                    <gosuite-input
                            type="number"
                            formControlName="minDuration"
                            placeholder="Reisedauer von"
                            [min]="1"
                            [max]="21"
                            [required]="true"
                    ></gosuite-input>
                </div>
                <div class="l-grid-cell l-grid-cell-1-2 l-grid-cell-connected">
                    <gosuite-input
                            type="number"
                            formControlName="maxDuration"
                            placeholder="Bis"
                            [min]="1"
                            [max]="21"
                    ></gosuite-input>
                </div>
            </div>
        </div>
    </div>
    <div class="l-grid">
        <div class="l-grid-cell l-grid-cell-1-8">
            <div class="l-grid">
                <div class="l-grid-cell l-grid-cell-1-2">
                    <gosuite-input
                            type="number"
                            formControlName="adultCount"
                            [required]="true"
                            [min]="1"
                            [max]="6"
                            placeholder="Erwachsene"
                    ></gosuite-input>
                </div>
            </div>
        </div>
        <div class="l-grid-cell l-grid-cell-1-8">
            <gosuite-input
                    type="number"
                    formControlName="childAge1"
                    [min]="0"
                    [max]="17"
                    placeholder="Kind 1">
            </gosuite-input>
        </div>
        <div class="l-grid-cell l-grid-cell-1-8">
            <gosuite-input
                    type="number"
                    formControlName="childAge2"
                    [min]="0"
                    [max]="17"
                    placeholder="Kind 2">
            </gosuite-input>
        </div>
        <div class="l-grid-cell l-grid-cell-1-8">
            <gosuite-input
                    type="number"
                    formControlName="childAge3"
                    [min]="0"
                    [max]="17"
                    placeholder="Kind 3">
            </gosuite-input>
        </div>
        <div class="l-grid-cell l-grid-cell-1-8">
            <gosuite-input
                    type="number"
                    formControlName="childAge4"
                    [min]="0"
                    [max]="17"
                    placeholder="Kind 4">
            </gosuite-input>
        </div>
        <div class="l-grid-cell l-grid-cell-1-8">
            <gosuite-input
                    type="number"
                    formControlName="maxPrice"
                    placeholder="Max. Preis"
                    [min]="1"
            ></gosuite-input>
        </div>
        <div class="l-grid-cell">
            <checkbox
                    formControlName="nonStopFlight"
                    label="Direktflug"
            ></checkbox>
        </div>
        <div class="l-grid-cell">
            <checkbox
                    formControlName="railAndFly"
                    label="Rail&amp;Fly"
            ></checkbox>
        </div>
        <div class="l-grid-cell">
            <checkbox
                    formControlName="seaView"
                    label="Meerblick"
            ></checkbox>
        </div>
    </div>
    <div class="l-grid">
        <div class="l-grid-cell l-grid-cell-1-4">
            <div class="l-grid">
                <div class="l-grid-cell l-grid-cell-1-2">
                    <gosuite-multi-select
                            formControlName="board"
                            placeholder="Verpflegung"
                            [enableSelectArrow]="true"
                            [enableValueLabel]="true"
                            [items]="vanessaService.boardTypes"
                    ></gosuite-multi-select>
                </div>
            </div>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="transfer"
                    placeholder="Transfer"
                    [items]="vanessaService.transferTypes"
            ></gosuite-multi-select>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-autocomplete
                    formControlName="hotelName"
                    placeholder="Hotelname / #Hotelcode / Giata ID"
                    (searchTextChange)="onHotelNameSearchTextChange($event)"
                    [items]="vanessaService.hotels$ | async"
                    [showPreloader]="vanessaService.showHotelNamePreloader$ | async"
                    [showIcon]="false"
                    [enableValueLabel]="true"
                    [useAsSingleSelect]="true"
                    [preferCustomValues]="true"
                    placeholderText="Geben Sie einen Suchbegriff ein"
                    placeholderNoResults=""
            ></gosuite-autocomplete>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="roomType"
                    placeholder="Zimmertyp"
                    [defaultGt]="'GT04-'"
                    [items]="vanessaService.roomTypes"
            ></gosuite-multi-select>
        </div>
    </div>
    <div class="l-grid">
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="attributes"
                    placeholder="Hotelausstattung"
                    [defaultGt]="'GT03-'"
                    [items]="vanessaService.attributesTypes"
            ></gosuite-multi-select>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-input
                    type="number"
                    formControlName="rating"
                    [min]="70"
                    [max]="100"
                    [step]="10"
                    placeholder="Bewertung ab %">
            </gosuite-input>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-autocomplete
                    formControlName="hotelChain"
                    placeholder="Hotelkette"
                    [showIcon]="false"
                    (searchTextChange)="onHotelChainSearchTextChange($event)"
                    [items]="vanessaService.hotelChains$ | async"
                    [showPreloader]="vanessaService.showHotelChainPreloader$ | async"
                    [defaultGt]="'BT12-'"
                    placeholderText="Geben Sie einen Suchbegriff ein"
                    placeholderNoResults="Leider haben wir zu Ihrem gesuchten Begriff nichts gefunden"
            ></gosuite-autocomplete>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="wellnessAttributes"
                    placeholder="Wellness und Gesundheit"
                    [items]="vanessaService.wellnessTypes"
            ></gosuite-multi-select>
        </div>
    </div>
    <div class="l-grid">
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="targetGroupAttributes"
                    placeholder="Zielgruppe / Urlaubsart"
                    [items]="vanessaService.targetGroupTypes"
            ></gosuite-multi-select>
        </div>
        <div class="l-grid-cell l-grid-cell-1-4">
            <gosuite-multi-select
                    formControlName="sportAttributes"
                    placeholder="Sport und Fitness"
                    [items]="vanessaService.sportTypes"
            ></gosuite-multi-select>
        </div>
    </div>
</form>
