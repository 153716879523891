import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {ButtonModule} from '@gosuite/button';
import {SelectModule, MultiSelectModule, AutocompleteModule} from '@gosuite/selects';
import {InputModule} from '@gosuite/input';
import {CheckboxModule} from '@gosuite/checkbox';
import {RangeDatepickerModule} from '@gosuite/range-datepicker';
import {IconModule} from '@gosuite/icons';
import {BarLoaderModule} from '@gosuite/preloader';

import {WjGridModule} from 'wijmo/wijmo.angular2.grid';
import {WjInputModule} from 'wijmo/wijmo.angular2.input';
import {WjGridFilterModule} from 'wijmo/wijmo.angular2.grid.filter';
import {WjCoreModule} from 'wijmo/wijmo.angular2.core';

import {MaterializeModule} from 'angular2-materialize';

import {AppComponent} from './app.component';
import {SelectTopboxComponent} from './select-topbox/select-topbox.component';
import {WizardComponent} from './wizard/wizard.component';
import {ImagePickerComponent} from './image-picker/image-picker.component';
import {DeeplinkPreviewComponent} from './deeplink-preview/deeplink-preview.component';
import {TravelPeriodHotelComponent} from './travel-period-hotel/travel-period-hotel.component';
import {HotelSelectionComponent} from './hotel-offer-selection/hotel-selection/hotel-selection.component';

import {AuthGuard} from './auth.guard';
import {routing} from './app.routing';

import {AuthService} from './services/auth.service';
import {TopboxService} from './services/topbox.service';
import {VanessaService} from './services/vanessa.service';
import {HotelviewService} from './services/hotelview.service';
import {WizardService} from './wizard/wizard.service';
import {SearchService} from './services/search.service';

import {ButtonGroupModule} from './shared/components/button-group/button-group.module';
import {CategoryStarsModule} from './shared/components/category-stars/category-stars.module';

import {OfferSelectionComponent} from './hotel-offer-selection/offer-selection/offer-selection.component';
import {TypeMapper} from './shared/mappings/type-mapper';
import {OfferBoxComponent} from './deeplink-preview/offer-box/offer-box.component';
import {StateManagerService} from './services/state-manager.service';

@NgModule({
    declarations: [
        AppComponent,
        SelectTopboxComponent,
        WizardComponent,
        ImagePickerComponent,
        DeeplinkPreviewComponent,
        TravelPeriodHotelComponent,
        HotelSelectionComponent,
        OfferSelectionComponent,
        OfferBoxComponent
    ],
    imports: [
        BrowserModule,
        MaterializeModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        SelectModule,
        MultiSelectModule,
        InputModule,
        CheckboxModule,
        AutocompleteModule,
        ButtonGroupModule,
        RangeDatepickerModule,
        HttpClientModule,
        WjGridModule,
        WjInputModule,
        WjGridFilterModule,
        WjCoreModule,
        CategoryStarsModule,
        BarLoaderModule,
        IconModule,
        routing,
		ButtonModule,
    ],
    providers: [AuthGuard, AuthService, TopboxService, VanessaService, WizardService, HotelviewService, SearchService, TypeMapper, StateManagerService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
