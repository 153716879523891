import {Component, Input, OnInit} from '@angular/core';
import {Deeplink, DeeplinkImage} from '../../shared/models/deeplink/deeplink.model';
import {Group} from '../../shared/models/groups/groups.model';

@Component({
    selector: 'app-offer-box',
    templateUrl: './offer-box.component.html',
    styleUrls: ['./offer-box.component.scss']
})
export class OfferBoxComponent implements OnInit {
    private _starImgUrl: string = 'assets/images/star.svg';
    private _halfstarImgUrl: string = 'assets/images/star-half.svg';

    @Input() deeplink: Deeplink;
    @Input() isOffer: boolean;

    public _selectedGroup: Group;
    public _categoryArray: Array<number>;
    public _imageUrl: string;
    public _unevenCategory: boolean = false;

    @Input()
    set selectedImage(image: DeeplinkImage) {
        if (image) {
            this._imageUrl = image.url;
        }
    }

    @Input() set selectedGroup(group: Group) {
        if (group) {
            this._selectedGroup = group;

            if (parseInt(group.hotel.tags.CAT[0]) % 10 !== 0) {
                this._unevenCategory = true;
            } else {
                this._unevenCategory = false;
            }

            this._categoryArray = new Array(Math.floor(parseInt(group.hotel.tags.CAT[0]) / 10));
        }
    };

    ngOnInit() {
        this._imageUrl = this.deeplink.image ? this.deeplink.image.toString() : '';
    }
}
