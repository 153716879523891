import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';

import { MaterializeAction } from 'angular2-materialize';

import { WizardService } from './wizard.service';
import { VanessaService } from '../services/vanessa.service';
import { TopboxService } from '../services/topbox.service';

@Component({
	selector: 'app-wizard',
	templateUrl: './wizard.component.html',
	styleUrls: ['./wizard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WizardComponent implements AfterViewInit {
	modalActions$ = new EventEmitter<string|MaterializeAction>();
	private _disableNextButton: boolean = false;

	constructor(
		public wizardService: WizardService,
		public vanessaService: VanessaService,
		public topboxService: TopboxService
	) {}

	ngAfterViewInit() {
		this.wizardService.disableNextButton$.subscribe((val: boolean) => this._disableNextButton = val);
		this.wizardService.onStepChange();
	}

	onDisableNextButton(val: boolean) {
		this._disableNextButton = val;
	}
}
