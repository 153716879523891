export const ROOM_TYPES: Array<string> = [
	'GT04-RO/ST04-DO',
	'GT04-RO/ST04-SI',
	'GT04-RO/ST04-FA',
	'GT04-RO/ST04-SU',
	'GT04-RO/ST04-ST:GT04-FH/ST04-ST',
	'GT04-RO/ST04-3B',
	'GT04-FH/ST04-BU',
	'GT04-RO/ST04-AP:GT04-FH/ST04-AP',
	'GT04-FH/ST04-VI',
];

/**
 * SOME ROOM-TYPES WITH SHORT-CUTS
 */
// const bla: Array<Object> = [
// 	{ 'DZ': 'GT04-RO/ST04-DO' },
// 	{ 'EZ': 'GT04-RO/ST04-SI' },
// 	{ 'FA': 'GT04-RO/ST04-FA' },
// 	{ 'SU': 'GT04-RO/ST04-SU' },
// 	{ 'FH': 'GT04-RO/ST04-ST:GT04-FH/ST04-ST' },
// 	{ '3B': 'GT04-RO/ST04-3B' },
// 	{ 'BU': 'GT04-FH/ST04-BU' },
// 	{ 'AP': 'GT04-RO/ST04-AP:GT04-FH/ST04-AP' },
// 	{ 'VI': 'GT04-FH/ST04-VI' }
// ];
