import { EventEmitter, Injectable } from '@angular/core';

import { TopboxService } from '../services/topbox.service';
import { VanessaService } from '../services/vanessa.service';

@Injectable()
export class WizardService {
	public currentStep: number = 1;
	private _lastStep: number = 1;
	public showFooter: boolean = false;
	public showFinishFooter: boolean = false;
	public tabParam: string[] = ['select_tab', 'topbox-select'];
	public isSaving: boolean = false;
	public stepChange$: EventEmitter<number> = new EventEmitter();
	public disableNextButton$: EventEmitter<boolean> = new EventEmitter();

	private _allowedSteps = {
		1: true,
		2: false,
		3: false,
		4: false,
		5: false
	};

	public constructor(
		private topboxService: TopboxService,
		private vanessaService: VanessaService
	) {
		this.vanessaService.groupIsSelected$
			.subscribe((isSelected: boolean) => this._allowedSteps = {...this._allowedSteps, 5: isSelected});
	}

	private skipNextStep() {
		this.currentStep++;
		this._lastStep = this.currentStep - 1;
	}

	private skipPreviousStep() {
		this.currentStep--;
		this._lastStep = this.currentStep + 1;
	}

	previousStep(): void {
		if (this.currentStep === 5 && this.vanessaService.showAllOffers) {
			this.skipPreviousStep();
			this.currentStep--;
		} else {
			this.currentStep--;
			this._lastStep = this.currentStep + 1;
		}

		this.onStepChange();
	}

	nextStep(): void {
		// if (this.currentStep === 5) {
		//
		// 	console.log('save me');
		//
		// 	return;
		// } else {
		if (this.currentStep === 3 && this.vanessaService.showAllOffers) {
			this.skipNextStep();
			this.currentStep++;
		} else {
			this.currentStep++;
			this._lastStep = this.currentStep - 1;
		}

		this.onStepChange();
		// }
	}

	setShowFooter(status: boolean) {
		this.showFooter = status;
	}

	setFinishFooter(status: boolean) {
		this.showFinishFooter = status;
	}

	public changeStep(step: number): void {
		if (this.stepChangeIsAllowed(step)) {
			this._lastStep = step === 1 ? 1 : this.currentStep;
			this.currentStep = step;

			this.onStepChange();
		}
	}

	public stepChangeIsAllowed(step: number): boolean {
		return this._allowedSteps[step];
	}

	public onStepChange(): void {
		this.stepChange$.emit(this._lastStep);
		this.disableNextButton$.emit(false);

		switch (this.currentStep) {
			case 1:
				this.topboxService.deeplinkSet$.next(false);
				this.vanessaService.groupIsSelected$.next(false);
				this.tabParam = ['select_tab', 'topbox-select'];
				this.setShowFooter(false);
				this.setFinishFooter(false);
				this._allowedSteps = {
					1: true,
					2: false,
					3: false,
					4: false,
					5: false
				};

				break;
			case 2:
				this.tabParam = ['select_tab', 'travel-period-hotel'];
				this.setShowFooter(true);
				this.setFinishFooter(false);

				this._allowedSteps = {...this._allowedSteps, 2: true, 3: true, 4: true};

				break;
			case 3:
				this.vanessaService.getAvailableGroups();
				this.tabParam = ['select_tab', 'hotel-selection'];
				this.setShowFooter(true);
				this.setFinishFooter(false);

				break;
			case 4:
				this.vanessaService.getAvailableOffers();
				this.tabParam = ['select_tab', 'offer-selection'];
				this.setShowFooter(true);
				this.setFinishFooter(false);

				break;
			case 5:
				this.topboxService.buildDeeplinkUrl();
				this.tabParam = ['select_tab', 'deeplink-preview'];
				this.setShowFooter(false);
				this.setFinishFooter(true);

				break;
			case 6:
				// SAVE ALL
				this.isSaving = true;
				this.setFinishFooter(false);
				this.topboxService.saveEditDeeplink().subscribe(() => {
						this.topboxService.images = [];
						this.topboxService.newImage = null;
						this.topboxService.deeplinkChecked = false;
						this.topboxService.deeplinkSet$.next(false);

						this.vanessaService.showAllOffers = true;
						this.vanessaService.selectedGroup = null;
						this.vanessaService.selectedHotelCode = '';
						this.vanessaService.groupIsSelected$.next(false);

						this.isSaving = false;
						this._allowedSteps = {
							1: true,
							2: false,
							3: false,
							4: false,
							5: false
						};

						this.onStepChange();
					}
				);

				this.currentStep = 1;
				this._lastStep = 1;

				break;
		}
	}
}
