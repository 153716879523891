import {Injectable} from '@angular/core';
import {TYPE_MAPPINGS} from './mappings/type-mappings';
import * as _ from 'lodash';

@Injectable()
export class TypeMapper {
    private _mappings: Object = TYPE_MAPPINGS;

    public map(code: string, type: string): string {
        return this._mappings[type][code];
    }

    public has(code: string, type: string): boolean {
        return _.has(this._mappings[type], code);
    }
}

