export const ROOM_TYPE_MAPPING = {
    'GT04-RO/ST04-DO': 'Doppelzimmer',
    'GT04-RO/ST04-SI': 'Einzelzimmer',
    'GT04-RO/ST04-FA': 'Familienzimmer',
    'GT04-RO/ST04-SU': 'Suite',
    'GT04-RO/ST04-ST:GT04-FH/ST04-ST': 'Studio',
    'GT04-RO/ST04-3B': 'Drei-Bett-Zimmer',
    'GT04-FH/ST04-BU': 'Bungalow',
    'GT04-RO/ST04-AP:GT04-FH/ST04-AP': 'Appartement',
    'GT04-FH/ST04-VI': 'Villa'
}
