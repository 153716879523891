import {DropdownItem} from '@gosuite/selects';
import * as moment from 'moment';
import {QueryTypes, QUERY_LIMIT} from '../query/query-type.model';
import {Group} from '../groups/groups.model';

export const CFG: string = '0144280000000000';

export const INITIAL_DEEPLINK: Deeplink = {
    id: 0,
    name: 'Deeplink',
    parameters: {
        start_date: moment().add(14, 'days').format('YYYY-MM-DD'),
        end_date: moment().add(28, 'days').format('YYYY-MM-DD'),
        relativeDateFrom: 14,
        relativeDateTo: 28,
        adult_count: 2,
        children_ages: '',
        ref: null,
        cfg: CFG,
        type: 'PA',
        limit: QUERY_LIMIT,
        offset: 0,
        orderBy: 'price',
        orderDirections: 'asc',
        maxPrice: null,
        lang: 'de',
        tags: {
            minDuration: 6,
            maxDuration: 8,
            destination: [],
            departure: [],
            category: null,
            board: [],
            rating: null,
            operator: [],
            roomType: [],
            attributes: [],
            transfer: [],
            hotelName: [],
            twoWay: true,
            wellnessAttributes: [],
            sportAttributes: [],
            targetGroupAttributes: [],
            hotelChain: [],
            nonStopFlight: false,
            railAndFly: false,
            seaView: false,
            giataId: null,
        },
        tagsString: '',
        selectDate: '1',
        version: 2,
        offerId: null,
        offerBoxRegionName: null,
        offerBoxRegionLabel: 'in der Region',
        offerBoxHotelName: null,
        offerBoxCategory: null,
        offerBoxPrice: null,
        offerBoxDuration: null,
        offerBoxHotelBoard: null,
        selectedGroup: null,
    },
    image: '',
};

export interface DeeplinkPool {
    id: number;
    name: string;
    site: Site;
    deeplinks: Deeplink[];
}

export interface Deeplink {
    id: number;
    name: string;
    parameters: DeeplinkParameters;
    image: string | DeeplinkImage;
}

export interface Site {
    id: number;
    agent: number;
    layout: string;
}

export interface DeeplinkParameters {
    start_date: string;
    end_date: string;
    relativeDateFrom: number;
    relativeDateTo: number;
    adult_count: number;
    children_ages: string;
    ref: number;
    cfg: string;
    type: 'PA'|'NF'|'NH';
    limit: number;
    offset: number;
    orderBy: string;
    orderDirections: string;
    maxPrice: number;
    lang: string;
    tags: DeeplinkTags;
    tagsString: string;
    selectDate: string;
    version: number;
    offerId?: string;
    offerBoxRegionName: string;
    offerBoxRegionLabel: string;
    offerBoxHotelName: string;
    offerBoxPrice: number;
    offerBoxDuration: number;
    offerBoxCategory: string;
    offerBoxHotelBoard: string;
    selectedGroup: Group;
}

export interface DeeplinkTags {
    minDuration: number;
    maxDuration: number;
    destination: DropdownItem[];
    departure: DropdownItem[];
    category: number;
    board: DropdownItem[];
    rating: number;
    operator: DropdownItem[];
    roomType: DropdownItem[];
    attributes: DropdownItem[];
    transfer: DropdownItem[];
    hotelName: Array<DropdownItem>;
    twoWay: boolean;
    wellnessAttributes: DropdownItem[];
    sportAttributes: DropdownItem[];
    targetGroupAttributes: DropdownItem[];
    hotelChain: DropdownItem[];
    nonStopFlight: boolean;
    railAndFly: boolean;
    seaView: boolean;
    giataId: string;
}

export interface DeeplinkImage {
    url: string;
    name: string;
    id?: number;
    dev?: boolean;
}
