<bar-loader *ngIf="vanessaService.showOffersLoader$ | async" class="bar-loader-segment"></bar-loader>
<div *ngIf="source.length && !(vanessaService.showOffersLoader$ | async)"
	 class="heading-container">
	<span class="heading">Angebotsauswahl</span>
</div>
<wj-flex-grid
	#grid
	platGridHover
	[headersVisibility]="'Column'"
	[isReadOnly]="true"
	[itemsSource]="source"
	[selectionMode]="'Row'"
	[showAlternatingRows]="true"
	(selectionChanged)="selectRow(grid.selectedItems[0])"
	(itemsSourceChanged)="setSelection(); onItemsSourceChanged(grid)"
	(loadedRows)="setSelection()"
	(updatedLayout)="updatedLayout(grid)"
	(initialized)="initGrid(grid, $event)"
	class="custom-flexgrid custom-flexgrid--indent-first-col selection-grid"
>
	<ng-container *ngIf="vanessaService.currentQueryType === _queryTypes.PA">
		<wj-flex-grid-column
			#departureCell
			[binding]="'transports.outbound.departureCode'"
			header="Ab"
			[minWidth]="70"
			[width]="70"
		>
			<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
				<div [title]="translateAirportCode(cell.item?.transports?.outbound?.departureCode)">
					{{ cell.item?.transports?.outbound?.departureCode }}
				</div>
			</ng-template>
		</wj-flex-grid-column>


		<wj-flex-grid-column
			#destinationCell
			[binding]="'transports.outbound.destinationCode'"
			header="Ziel"
			[minWidth]="70"
			[width]="70"
		>
		</wj-flex-grid-column>
	</ng-container>
	<wj-flex-grid-column
		#startDateCell
		[binding]="'startDate'"
		[format]="'dd.MM.yyyy'"
		header="Hinreise"
		[minWidth]="100"
		[width]="100"
	>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#endDateCell
		[binding]="'endDate'"
		[format]="'dd.MM.yyyy'"
		header="Rückreise"
		[minWidth]="100"
		[width]="100"
	>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#durationCell
		[align]="'right'"
		[binding]="'duration'"
		header="Dauer"
		[minWidth]="80"
		[width]="80"
	>
	</wj-flex-grid-column>

	<ng-container *ngIf="vanessaService.currentQueryType === _queryTypes.PA">
		<wj-flex-grid-column
			#outboundFlightCell
			[binding]="'transports.outbound.flightInfo'"
			header="Hinflug"
			[minWidth]="80"
			[width]="'*'"
		>
		</wj-flex-grid-column>


		<wj-flex-grid-column
			#inboundFlightCell
			[binding]="'transports.inbound.flightInfo'"
			header="Rückflug"
			[minWidth]="80"
			[width]="'*'"
		>
		</wj-flex-grid-column>
	</ng-container>


	<wj-flex-grid-column
		#flightClassCell
		header="FK"
		[minWidth]="60"
		[width]="'*'"
		*ngIf="vanessaService.currentQueryType === _queryTypes.PA"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			{{getFlightClass(cell.item)}}
		</ng-template>
	</wj-flex-grid-column>

	<wj-flex-grid-column
		#tourOperatorCell
		header="VA"
		[binding]="'tourOperator'"
		[minWidth]="60"
		[width]="60"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr
				title="Veranstalter"
			>
				{{cell.col.header}}
			</abbr>
		</ng-template>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#kindoftravel
		[binding]="'kindOfTravel'"
		header="-"
		[minWidth]="60"
		[width]="60"
	>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#roomCell
		[binding]="'hotel.room._'"
		header="Zimmer"
		[minWidth]="80"
		[width]="'*'"
	>

		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<div class="custom-cell">
				<abbr class="truncate" [title]="cell.item.hotel.room.code + ' (' + cell.item.hotel.room._ + ')'">
					{{cell.item.hotel.room._}}
				</abbr>
			</div>
		</ng-template>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#roomCodeCell
		i18n-header="@@xenaOffersRoomCode"
		[binding]="'hotel.room.code'"
		header="Code"
		[minWidth]="80"
		[width]="'*'"
		*ngIf="vanessaService.currentQueryType === _queryTypes.NH"
	>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#boardCell
		header="VPF"
		[binding]="'hotel.board.code'"
		[minWidth]="80"
		[width]="80"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr title="Verpflegung"
			>
				{{cell.col.header}}
			</abbr>
		</ng-template>

		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<div [title]="translateBoardCode(cell.item?.hotel?.board?.code)">
				{{ getBoardCodeValue(cell.item?.hotel?.board?.code) }}
			</div>
		</ng-template>
	</wj-flex-grid-column>


	<wj-flex-grid-column
		#transferCell
		*ngIf="vanessaService.currentQueryType === _queryTypes.PA; else elseBlock">
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<div class="custom-cell custom-cell-space-between">
				<abbr title="Transfer">
					<svg icon type="transportation"></svg>
				</abbr>
				<abbr title="Mietwagen">
					<svg icon type="rentalcar"></svg>
				</abbr>
				<abbr title="Rail&Fly">
					<svg icon type="rail-fly"></svg>
				</abbr>
				<abbr title="Direktflug">
					<svg icon type="plane">></svg>
				</abbr>
				<abbr title="Eignung für Personen mit eingeschränkter Mobilität">
					<svg icon type="wheelchair">></svg>
				</abbr>
			</div>
		</ng-template>

		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<div class="custom-cell custom-cell-space-between">
				<ng-container [ngSwitch]="cell.item.hotel?.tags?.GT14[0]">
					<svg *ngSwitchCase="'TR'" icon type="check"></svg>
					<span *ngSwitchDefault class="empty">-</span>
				</ng-container>

				<ng-container [ngSwitch]="cell.item.hotel?.tags?.GT14[0]">
					<svg *ngSwitchCase="'HC'" icon type="check"></svg>
					<span *ngSwitchDefault class="empty">-</span>
				</ng-container>

				<ng-container [ngSwitch]="cell.item.hotel?.tags?.GT08 && cell.item.hotel?.tags?.GT08[0]">
					<svg *ngSwitchCase="'ARDE/ST08-RAFL'" icon type="check"></svg>
					<span *ngSwitchDefault class="empty">-</span>
				</ng-container>

				<ng-container [ngSwitch]="hasNumberStops(cell.item, 'IN', 0) && hasNumberStops(cell.item, 'OUT', 0)">
					<svg *ngSwitchCase="true" icon type="check"></svg>
					<span *ngSwitchDefault class="empty">-</span>
				</ng-container>

				<ng-container [ngSwitch]="isHandiCappedAccessible(cell.item)">
					<svg *ngSwitchCase="true" icon type="check"></svg>
					<span *ngSwitchDefault class="empty">-</span>
				</ng-container>
			</div>
		</ng-template>
	</wj-flex-grid-column>
	<ng-template #elseBlock>
		<wj-flex-grid-column
			#transferCell
			[minWidth]="37"
			[width]="37"
		>
			<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
				<div class="custom-cell custom-cell-space-between">
					<abbr title="Eignung für Personen mit eingeschränkter Mobilität"
						  i18n-title="@@handiCappedAccessible">
						<svg icon type="wheelchair">></svg>
					</abbr>
				</div>
			</ng-template>

			<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
				<div class="custom-cell custom-cell-space-between">
					<ng-container [ngSwitch]="isHandiCappedAccessible(cell.item)">
						<svg *ngSwitchCase="true" icon type="check"></svg>
						<span *ngSwitchDefault class="empty">-</span>
					</ng-container>
				</div>
			</ng-template>
		</wj-flex-grid-column>
	</ng-template>


	<wj-flex-grid-column
		#priceCell
		[align]="'right'"
		[binding]="'price'"
		header="€"
		[minWidth]="70"
		[width]="70"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr title="Preis pro Person"
			>
				{{cell.col.header}}
			</abbr>
		</ng-template>

	</wj-flex-grid-column>


	<wj-flex-grid-column
		[align]="'right'"
		[binding]="'totalPrice'"
		header="€€"
		[minWidth]="70"
		[width]="70"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-cell="cell">
			<abbr title="Gesamtpreis"
			>
				{{cell.col.header}}
			</abbr>
		</ng-template>

		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
			<ng-container *ngIf="
                 !(_validations[cell.item.id]?.inProgress) &&
                 _validations[cell.item.id]?.response &&
                 _validations[cell.item.id]?.response.totalPrice
             ">
                 <span>
                     {{_validations[cell.item.id]?.response.totalPrice.toFixed(2)}}
                 </span>

			</ng-container>
		</ng-template>
	</wj-flex-grid-column>

	<wj-flex-grid-column
		[align]="'right'"
		[allowDragging]="false"
		[allowResizing]="false"
		[allowSorting]="false"
		header="Status"
		[minWidth]="180"
		[width]="180"
	>
		<ng-template wjFlexGridCellTemplate [cellType]="'Cell'" [cellOverflow]="'visible'" let-cell="cell">
			<bar-loader *ngIf="_validations[cell.item.id]?.inProgress" style="height: 16px"></bar-loader>

			<ng-container *ngIf="
				!(_validations[cell.item.id]?.inProgress) &&
				_validations[cell.item.id]?.response
			">

				<span class="available" *ngIf="_validations[cell.item.id]?.available; else elseBlock">Verfügbar</span>
				<ng-template #elseBlock>
					<span class="not-available">Ausgebucht</span>
				</ng-template>

			</ng-container>
		</ng-template>
	</wj-flex-grid-column>
</wj-flex-grid>

