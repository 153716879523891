/*export const BOARD_TYPES: Array<string> = [
	'GT06-XX',
	'GT06-AO',
	'GT06-BR:GT06-BR/ST06-AM:GT06-BR/ST06-BU:GT06-BR/ST06-CO:GT06-BR/ST06-EN',
	'GT06-HB:GT06-HB/ST06-DINE:GT06-HB/ST06-FLEX:GT06-HB/ST06-LUNC',
	'GT06-HB:GT06-HB/ST06-PL',
	'GT06-FB',
	'GT06-FB:GT06-FB/ST06-PL:GT06-FB/ST06-UL',
	'GT06-AI:GT06-AI/ST06-PREM',
	'GT06-AI:GT06-AI/ST06-UL',
];*/

export const BOARD_TYPES: Array<Object> = [
	{ 'X': 'GT06-XX' },
	{ 'Ü': 'GT06-AO' },
	{ 'ÜF': 'GT06-BR:GT06-BR/ST06-AM:GT06-BR/ST06-BU:GT06-BR/ST06-CO:GT06-BR/ST06-EN' },
	{ 'HP': 'GT06-HB:GT06-HB/ST06-DINE:GT06-HB/ST06-FLEX:GT06-HB/ST06-LUNC' },
	{ 'HP+': 'GT06-HB:GT06-HB/ST06-PL' },
	{ 'VP': 'GT06-FB' },
	{ 'VP+': 'GT06-FB:GT06-FB/ST06-PL:GT06-FB/ST06-UL' },
	{ 'AI': 'GT06-AI:GT06-AI/ST06-PREM' },
	{ 'UAI': 'GT06-AI:GT06-AI/ST06-UL' },
];
