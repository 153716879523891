export const BOARD_TYPE_MAPPING = {
    'GT06-XX': 'Verpflegung laut Programm',
    'GT06-AO': 'Nur Übernachtung',
    'GT06-BR:GT06-BR/ST06-AM:GT06-BR/ST06-BU:GT06-BR/ST06-CO:GT06-BR/ST06-EN': 'Frühstück',
    'GT06-HB:GT06-HB/ST06-DINE:GT06-HB/ST06-FLEX:GT06-HB/ST06-LUNC': 'Halbpension',
    'GT06-HB:GT06-HB/ST06-PL': 'Halbpension Plus',
    'GT06-FB': 'Vollpension',
    'GT06-FB:GT06-FB/ST06-PL:GT06-FB/ST06-UL': 'Vollpension Plus',
    'GT06-AI:GT06-AI/ST06-PREM': 'All Inclusive',
    'GT06-AI:GT06-AI/ST06-UL': 'Ultra All Inclusive',
    'GT06-AI/ST06-UL': 'Ultra All Inclusive',
    // offer response uses different keys
    'GT06-AI': 'All Inclusive',
    'GT06-AI/ST06-PREM': 'All Inclusive Plus',
    'GT06-HB': 'Halbpension',
    'GT06-HB/ST06-PL': 'Halbpension Plus',
    'GT06-FB/ST06-PL': 'Vollpension Plus',
    'GT06-BR': 'Frühstück',
}
