import { Component, OnInit } from '@angular/core';
import {Deeplink} from '../shared/models/deeplink/deeplink.model';
import { TopboxService } from '../services/topbox.service';
import { WizardService } from '../wizard/wizard.service';
import {URLSearchParams} from '@angular/http';
import { TopBox } from '../shared/models/top-box.model';



@Component({
	selector: 'app-select-topbox',
	templateUrl: './select-topbox.component.html',
	styleUrls: ['./select-topbox.component.css']
})
export class SelectTopboxComponent implements OnInit {
	public topBoxes$ = this.topboxService.getDeeplinks();

	public constructor(
		public topboxService: TopboxService,
		public wizardService: WizardService
	) {}


	public ngOnInit(): void {
		const urlParams = new URLSearchParams(window.location.search);
	}

	public handleSpecialOfferItemClick(deepLink: Deeplink): void {
		this.topboxService.setEditDeeplink(deepLink);
		this.wizardService.nextStep();
	}

	public handleGenerateDeeplinkButton(): void {
		this.topboxService.setEditDeeplink(
			{
				id: null,
				name: null,
				parameters: null,
				image: null
			}
		);

		this.wizardService.nextStep();
	}

	public deleteTopBox(topbox: TopBox) {
		if (topbox.id) {
			this.topboxService.deleteTopBox(topbox.id).subscribe(() => {
				this.topBoxes$ = this.topboxService.getDeeplinks();
			});
		}
	}
}
