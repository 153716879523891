<div class="offer-box">
    <div class="offer-box-section section-deeplink-name">
        <input type="text" class="deeplink-name" [(ngModel)]="deeplink.name" placeholder="Bitte Titel eingeben">
    </div>
    <div class="offer-box-section section-deeplink-image">
        <img class="deeplink-image" [src]="_imageUrl" alt="Deeplink Bild">
    </div>
    <div class="offer-box-section">
        <div class="section-price">
            <span *ngIf="isOffer">AB </span>
            <span class="hotel-price">{{_selectedGroup?.price}} €</span>
            <div>PRO PERSON</div>
        </div>
    </div>
    <div class="offer-box-section section-hotel-name">
        <div class="hotel-name">{{_selectedGroup?.hotel.name}}</div>
        <input type="text" [(ngModel)]="deeplink.parameters.offerBoxRegionLabel"> <input type="text" [(ngModel)]="deeplink.parameters.offerBoxRegionName">
    </div>
    <div class="offer-box-section section-category">
        <span *ngFor="let category of _categoryArray"><img class="category-svg" [src]="_starImgUrl"></span>
        <span *ngIf="_unevenCategory"><img class="category-svg" [src]="_halfstarImgUrl"></span>
    </div>
    <div class="offer-box-section section-duration">
        <span *ngIf="!isOffer; else durationElse">{{_selectedGroup?.duration}} Tage</span>
        <ng-template #durationElse>
            <span>{{deeplink.parameters.tags.minDuration}} bis {{deeplink.parameters.tags.maxDuration}} Tage</span>
        </ng-template>
    </div>
</div>
