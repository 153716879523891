import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

import {WjFlexGrid} from 'wijmo/wijmo.angular2.grid';
import {FlexGrid} from 'wijmo/wijmo.grid';
import {EventArgs} from 'wijmo/wijmo';

import {TypeMapper} from '../../shared/mappings/type-mapper';
import {
	BOARD_TYPE_NAME, DEPARTURE_TYPE_NAME,
	FLIGHT_CLASS_TYPE_NAME
} from '../../shared/mappings/mappings/type-mappings';

import {VanessaService} from '../../services/vanessa.service';
import {WizardService} from '../../wizard/wizard.service';
import {TopboxService} from '../../services/topbox.service';
import {StateManagerService} from '../../services/state-manager.service';

import {Deeplink, DeeplinkParameters} from '../../shared/models/deeplink/deeplink.model';
import {Group} from '../../shared/models/groups/groups.model';
import {ValidationResponseModel} from '../../shared/models/validation-response.model';

import {isArray, isObject, isString} from 'util';
import * as _ from 'lodash';
import {QueryTypes} from '../../shared/models/query/query-type.model';


@Component({
	selector: 'app-offer-selection',
	templateUrl: './offer-selection.component.html',
	styleUrls: ['./offer-selection.component.scss']
})
export class OfferSelectionComponent implements AfterViewInit, OnInit {
	public source: Array<Group> = [];
	private _tmpGroup: Group;
	private _step: number = 4;
	public _queryTypes = QueryTypes;

	@ViewChild(WjFlexGrid) grid: WjFlexGrid;
	@Output() disableNextButton$: EventEmitter<boolean> = new EventEmitter();

	private _validations = {};

	public boardCodeMap: Object = {
		'GT06-AI/ST06-UL': 'UAI',
		'GT06-AI': 'AI',
		'GT06-AI/ST06-PREM': 'AI+',
		'GT06-HB': 'HP',
		'GT06-HB/ST06-PL': 'HP+',
		'GT06-FB': 'VP',
		'GT06-FB/ST06-PL': 'VP+',
		'GT06-BR': 'ÜF',
		'GT06-AO': 'Ü',
		'GT06-XX': '-'
	};

	constructor(private typeMapper: TypeMapper, public vanessaService: VanessaService, private wizardService: WizardService,
				private topboxService: TopboxService, private stateManagerService: StateManagerService) {

		this.vanessaService.offersFoundSubject$.subscribe((groups) => {
			this.disableNextButton$.emit(true);

			this.hideGrid();
			this.source = groups;

			if (this.source.length) {
				this.showGrid();
			}

			this.vanessaService.showOffersLoader$.next(false);
		});

		this.wizardService.stepChange$.filter(step => step === this._step).subscribe(() => this.updateStates());
	}

	private hideGrid() {
		this.grid.headersVisibility = 0;
	}

	private showGrid() {
		this.grid.headersVisibility = 1;
	}

	ngAfterViewInit() {
		if (this.grid) {
			this.setSelection();
		}
	}

	ngOnInit() {
	}

	selectRow(offer: Group) {
		this.disableNextButton$.emit(true);

		if (offer && !offer['groupDescription']) {
			this._tmpGroup = offer

			if (this.vanessaService.selectedGroup !== this._tmpGroup) {
				this.vanessaService.selectedGroup = this._tmpGroup;
				this.vanessaService.selectedHotelCode = this._tmpGroup.hotel.tags.BT01[0];
				this.vanessaService.groupIsSelected$.next(true);

				this.topboxService.deeplinkChecked = false;
			}

			this.validateOffer(offer);
		}
	}

	validateOffer(offer: Group) {
		if (!this._validations[offer.id]) {
			this._validations[offer.id] = {
				inProgress: true,
				response: null,
				available: false
			};

			this.vanessaService.validationRequests$.push(this.vanessaService.validate(offer).subscribe(
				(res: ValidationResponseModel) => {

					this._validations[offer.id].response = res;
					this._validations[offer.id].inProgress = false;

					if (res.status.code === 'BA') {
						this._validations[offer.id].available = true;

						if (this.vanessaService.selectedGroup === offer) {
							this.disableNextButton$.emit(false);
						}
					}
				},
				(error) => console.log(error)));
		} else if (this._validations[offer.id].response && this._validations[offer.id].available) {
			this.disableNextButton$.emit(false);
		}
	}

	onItemsSourceChanged(grid: WjFlexGrid) {
		this.resetColumns(grid);
	}

	private updateStates(): void {
		if (this._tmpGroup) {
			const deeplink: Deeplink = this.stateManagerService.getActiveDeeplinkState(this._step),
				deeplinkParams: DeeplinkParameters = deeplink.parameters;

			deeplinkParams.offerId = this._tmpGroup.id;
			deeplinkParams.offerBoxDuration = this._tmpGroup.duration;
			deeplinkParams.offerBoxPrice = this._tmpGroup.price;
			deeplinkParams.offerBoxHotelBoard = this.typeMapper.map(this.getHotelBoardCode(this._tmpGroup), BOARD_TYPE_NAME);
			deeplinkParams.selectedGroup = this._tmpGroup;
			deeplinkParams.tags.giataId = this._tmpGroup.hotel.locationId;

			this.stateManagerService.setDeeplinkState(deeplink, this._step);
		}
	}

	setSelection() {
		if (this.grid.selectedRows.length) {
			this.initialGroupSelection();
		}
	}

	updatedLayout(grid: WjFlexGrid) {
		if (grid.selectedRows.length > 0) {
			grid.scrollIntoView(grid.selectedRows[0].index, 0);
		}
	}

	private initialGroupSelection() {
		this._tmpGroup = this.grid.selectedRows[0].dataItem;
	}

	/**
	 * Bound to the initialized event on the wijmo flexgrid
	 */
	initGrid(s: FlexGrid, e: EventArgs) {
		/**
		 * We need to auto size the first row (header) to recalculate the height,
		 * because Firefox has some trouble to get the correct height if we change
		 * paddings with CSS
		 */
		s.autoSizeRow(0, true);
	}

	public hasNumberStops(offer: Group, direction: 'IN' | 'OUT', stops: number): boolean {
		return isObject(offer)
			&& isObject(offer.hotel)
			&& isObject(offer.hotel.tags)
			&& isArray(offer.hotel.tags.STOPS)
			&& _.includes(offer.hotel.tags.STOPS, `${direction}-${stops}`)
			|| false;
	}

	public getFlightClass(offer: Group): string {
		if (isObject(offer) && offer.type !== this._queryTypes.NH) {
			return isObject(offer.hotel)
				&& isObject(offer.hotel.tags)
				&& isArray(offer.hotel.tags.GT17)
				&& this.typeMapper.map(`GT17-${offer.hotel.tags.GT17[0]}`, FLIGHT_CLASS_TYPE_NAME)
				|| this.typeMapper.map('GT17-FALLBACK', FLIGHT_CLASS_TYPE_NAME);
		}

		return null;
	}

	public isHandiCappedAccessible(offer: Group): boolean {
		return isObject(offer)
			&& isObject(offer.hotel)
			&& isObject(offer.hotel.tags)
			&& isArray(offer.hotel.tags.GT03)
			&& offer.hotel.tags.GT03.indexOf('WHUS') !== -1;
	}

	public translateBoardCode(code: string): string {
		if (isString(code) && this.typeMapper.has(code, BOARD_TYPE_NAME)) {
			return this.typeMapper.map(code, BOARD_TYPE_NAME);
		}

		return code;
	}

	public getBoardCodeValue(code: string): string {
		if (isString(code) && this.boardCodeMap.hasOwnProperty(code)) {
			return this.boardCodeMap[code];
		}

		return code;
	}

	public translateAirportCode(code: string): string {
		if (isString(code) && this.typeMapper.has(`DEP-${code}`, DEPARTURE_TYPE_NAME)) {
			return this.typeMapper.map(`DEP-${code}`, DEPARTURE_TYPE_NAME);
		}

		return code;
	}

	private getHotelBoardCode(offer: Group): string {
		return offer.hotel.tags.GT06.map(function (board) {
			return 'GT06-' + board;
		}).join(':');
	}

	private resetColumns(grid: WjFlexGrid) {
		grid.columns.forEach(column => column.visible = true);

		if (!grid.collectionView) {
			return;
		}

		grid.collectionView.groupDescriptions.clear();
		grid.collectionView.sortDescriptions.clear();
	}
}
