import {BOARD_TYPE_MAPPING} from './board-type-mapping';
import {ATTRIBUTE_TYPE_MAPPING} from './attribute-type-mapping';
import {SPORT_TYPE_MAPPING} from './sport-types-mapping';
import {TARGET_GROUP_TYPE_MAPPING} from './target-groups-type-mapping';
import {TRANSFER_TYPE_MAPPING} from './transfer-type-mapping';
import {WELLNESS_TYPE_MAPPING} from './wellness-type-mapping';
import {ROOM_TYPE_MAPPING} from './room-type-mapping';
import {FLIGHT_CLASS_TYPE_MAPPING} from './flight-class-type-mapping';
import {DEPARTURE_TYPE_MAPPING} from './departure-type-mapping';
import {REGION_TYPE_MAPPING} from './region-type-mapping';

export const BOARD_TYPE_NAME: string = 'board';
export const ATTRIBUTE_TYPE_NAME: string = 'attribute';
export const SPORT_TYPE_NAME: string = 'sport';
export const TARGET_GROUP_TYPE_NAME: string = 'target-group';
export const TRANSFER_TYPE_NAME: string = 'transfer';
export const WELLNESS_TYPE_NAME: string = 'wellness';
export const ROOM_TYPE_NAME: string = 'room';
export const FLIGHT_CLASS_TYPE_NAME: string = 'flight-class';
export const DEPARTURE_TYPE_NAME: string = 'departure';
export const REGION_TYPE_NAME: string = 'region';

export const TYPE_MAPPINGS = {
    [BOARD_TYPE_NAME]: BOARD_TYPE_MAPPING,
    [ATTRIBUTE_TYPE_NAME]: ATTRIBUTE_TYPE_MAPPING,
    [SPORT_TYPE_NAME]: SPORT_TYPE_MAPPING,
    [TARGET_GROUP_TYPE_NAME]: TARGET_GROUP_TYPE_MAPPING,
    [TRANSFER_TYPE_NAME]: TRANSFER_TYPE_MAPPING,
    [WELLNESS_TYPE_NAME]: WELLNESS_TYPE_MAPPING,
    [ROOM_TYPE_NAME]: ROOM_TYPE_MAPPING,
    [FLIGHT_CLASS_TYPE_NAME]: FLIGHT_CLASS_TYPE_MAPPING,
    [DEPARTURE_TYPE_NAME]: DEPARTURE_TYPE_MAPPING,
    [REGION_TYPE_NAME]: REGION_TYPE_MAPPING
}
