import { Observable } from 'rxjs/Rx';

import { DropdownItem } from '@gosuite/selects';

import { DepartureModel } from '../models/departure/departure.model';


export const DEPARTURES: Array<DepartureModel> = [
	{
		displayName: 'Hamburg',
		code: 'DEP-HAM',
		rawCode: 'HAM'
	},
	{
		displayName: 'Hannover',
		code: 'DEP-HAJ',
		rawCode: 'HAJ'
	},
	{
		displayName: 'Bremen',
		code: 'DEP-BRE',
		rawCode: 'BRE'
	},
	{
		displayName: 'Lübeck',
		code: 'DEP-LBC',
		rawCode: 'LBC'
	},
	{
		displayName: 'Berlin-Tegel',
		code: 'DEP-TXL',
		rawCode: 'TXL'
	},
	{
		displayName: 'Berlin-Schönefeld',
		code: 'DEP-SXF',
		rawCode: 'SXF'
	},
	{
		displayName: 'Leipzig',
		code: 'DEP-LEJ',
		rawCode: 'LEJ'
	},
	{
		displayName: 'Dresden',
		code: 'DEP-DRS',
		rawCode: 'DRS'
	},
	{
		displayName: 'Rostock-Laage',
		code: 'DEP-RLG',
		rawCode: 'RLG'
	},
	{
		displayName: 'Erfurt',
		code: 'DEP-ERF',
		rawCode: 'ERF'
	},
	{
		displayName: 'Cochstedt',
		code: 'DEP-CSO',
		rawCode: 'CSO'
	},
	{
		displayName: 'München',
		code: 'DEP-MUC',
		rawCode: 'MUC'
	},
	{
		displayName: 'Stuttgart',
		code: 'DEP-STR',
		rawCode: 'STR'
	},
	{
		displayName: 'Nürnberg',
		code: 'DEP-NUE',
		rawCode: 'NUE'
	},
	{
		displayName: 'Friedrichshafen',
		code: 'DEP-FDH',
		rawCode: 'FDH'
	},
	{
		displayName: 'Karlsruhe-Baden',
		code: 'DEP-FKB',
		rawCode: 'FKB'
	},
	{
		displayName: 'Memmingen',
		code: 'DEP-FMM',
		rawCode: 'FMM'
	},
	{
		displayName: 'Frankfurt',
		code: 'DEP-FRA',
		rawCode: 'FRA'
	},
	{
		displayName: 'Düsseldorf',
		code: 'DEP-DUS',
		rawCode: 'DUS'
	},
	{
		displayName: 'Köln/Bonn',
		code: 'DEP-CGN',
		rawCode: 'CGN'
	},
	{
		displayName: 'Münster/Osnabrück',
		code: 'DEP-FMO',
		rawCode: 'FMO'
	},
	{
		displayName: 'Dortmund',
		code: 'DEP-DTM',
		rawCode: 'DTM'
	},
	{
		displayName: 'Hahn',
		code: 'DEP-HHN',
		rawCode: 'HHN'
	},
	{
		displayName: 'Weeze',
		code: 'DEP-NRN',
		rawCode: 'NRN'
	},
	{
		displayName: 'Paderborn',
		code: 'DEP-PAD',
		rawCode: 'PAD'
	},
	{
		displayName: 'Saarbrücken',
		code: 'DEP-SCN',
		rawCode: 'SCN'
	},
	{
		displayName: 'Kassel',
		code: 'DEP-KSF',
		rawCode: 'KSF'
	},
	{
		displayName: 'Wien (Aut)',
		code: 'DEP-VIE',
		rawCode: 'VIE'
	},
	{
		displayName: 'Salzburg (Aut)',
		code: 'DEP-SZG',
		rawCode: 'SZG'
	},
	{
		displayName: 'Innsbruck (Aut)',
		code: 'DEP-INN',
		rawCode: 'INN'
	},
	{
		displayName: 'Linz (Aut)',
		code: 'DEP-LNZ',
		rawCode: 'LNZ'
	},
	{
		displayName: 'Graz (Aut)',
		code: 'DEP-GRZ',
		rawCode: 'GRZ'
	},
	{
		displayName: 'Klagenfurt (Aut)',
		code: 'DEP-KLU',
		rawCode: 'KLU'
	},
	{
		displayName: 'Lugano (CH)',
		code: 'DEP-LUG',
		rawCode: 'LUG'
	},
	{
		displayName: 'Zürich (CH)',
		code: 'DEP-ZRH',
		rawCode: 'ZRH'
	},
	{
		displayName: 'Genf (CH)',
		code: 'DEP-GVA',
		rawCode: 'GVA'
	},
	{
		displayName: 'Basel (CH)',
		code: 'DEP-BSL',
		rawCode: 'BSL'
	},
	{
		displayName: 'Bern (CH)',
		code: 'DEP-BRN',
		rawCode: 'BRN'
	},
	{
		displayName: 'Brüssel (B)',
		code: 'DEP-BRU',
		rawCode: 'BRU'
	},
	{
		displayName: 'Amsterdam (NL)',
		code: 'DEP-AMS',
		rawCode: 'AMS'
	},
	{
		displayName: 'Rotterdam (NL)',
		code: 'DEP-RTM',
		rawCode: 'RTM'
	},
	{
		displayName: 'Maastricht (NL)',
		code: 'DEP-MST',
		rawCode: 'MST'
	},
	{
		displayName: 'Eindhoven (NL)',
		code: 'DEP-EIN',
		rawCode: 'EIN'
	},
	{
		displayName: 'Groningen (NL)',
		code: 'DEP-GRQ',
		rawCode: 'GRQ'
	},
	{
		displayName: 'Luxemburg (L)',
		code: 'DEP-LUX',
		rawCode: 'LUX'
	},
	{
		displayName: 'Toulouse (F)',
		code: 'DEP-TLS',
		rawCode: 'TLS'
	},
	{
		displayName: 'Nantes (F)',
		code: 'DEP-NTE',
		rawCode: 'NTE'
	},
	{
		displayName: 'Nizza (F)',
		code: 'DEP-NCE',
		rawCode: 'NCE'
	},
	{
		displayName: 'Marseille (F)',
		code: 'DEP-MRS',
		rawCode: 'MRS'
	},
	{
		displayName: 'Montpellier (F)',
		code: 'DEP-MPL',
		rawCode: 'MPL'
	},
	{
		displayName: 'Lyon (F)',
		code: 'DEP-LYS',
		rawCode: 'LYS'
	},
	{
		displayName: 'Lille (F)',
		code: 'DEP-LIL',
		rawCode: 'LIL'
	},
	{
		displayName: 'Metz/Nancy (F)',
		code: 'DEP-ETZ',
		rawCode: 'ETZ'
	},
	{
		displayName: 'Bordeaux (F)',
		code: 'DEP-BOD',
		rawCode: 'BOD'
	},
	{
		displayName: 'Rennes (F)',
		code: 'DEP-RNS',
		rawCode: 'RNS'
	},
	{
		displayName: 'Paris (F)',
		code: 'DEP-CDG',
		rawCode: 'CDG'
	},
	{
		displayName: 'Brest (F)',
		code: 'DEP-BES',
		rawCode: 'BES'
	},
	{
		displayName: 'Strassburg (F)',
		code: 'DEP-SXB',
		rawCode: 'SXB'
	},
	{
		displayName: 'Mühlhausen (F)',
		code: 'DEP-MLH',
		rawCode: 'MLH'
	},
	{
		displayName: 'Prag (CZ)',
		code: 'DEP-PRG',
		rawCode: 'PRG'
	},
	{
		displayName: 'Mailand Linate (IT)',
		code: 'DEP-LIN',
		rawCode: 'LIN'
	},
	{
		displayName: 'Mailand Malpensa (IT)',
		code: 'DEP-MXP',
		rawCode: 'MXP'
	},
	{
		displayName: 'Warschau (PL)',
		code: 'DEP-WAW',
		rawCode: 'WAW'
	},
	{
		displayName: 'Poznan (PL)',
		code: 'DEP-POZ',
		rawCode: 'POZ'
	},
	{
		displayName: 'Gdansk (PL)',
		code: 'DEP-GDN',
		rawCode: 'GDN'
	},
	{
		displayName: 'Krakow (PL)',
		code: 'DEP-KRK',
		rawCode: 'KRK'
	},
	{
		displayName: 'Wroclaw (PL)',
		code: 'DEP-WRO',
		rawCode: 'WRO'
	},
	{
		displayName: 'Rzeszów-Jasionka (PL)',
		code: 'DEP-RZE',
		rawCode: 'RZE'
	},
	{
		displayName: 'Stettin-Goleniów (PL)',
		code: 'DEP-SZZ',
		rawCode: 'SZZ'
	},
	{
		displayName: 'Katowice (PL)',
		code: 'DEP-KTW',
		rawCode: 'KTW'
	},
	{
		displayName: 'Budapest (HU)',
		code: 'DEP-BUD',
		rawCode: 'BUD'
	},
	{
		displayName: 'Bukarest (RO)',
		code: 'DEP-OTP',
		rawCode: 'OTP'
	},
	{
		displayName: 'Cluj (RO)',
		code: 'DEP-CLJ',
		rawCode: 'CLJ'
	},
	{
		displayName: 'London (GB)',
		code: 'DEP-LHR',
		rawCode: 'LHR'
	},
	{
		displayName: 'Ljubljana (SI)',
		code: 'DEP-LJU',
		rawCode: 'LJU'
	},
	{
		displayName: 'Sofia (BG)',
		code: 'DEP-SOF',
		rawCode: 'SOF'
	},
	{
		displayName: 'Sarajevo (BA)',
		code: 'DEP-SJJ',
		rawCode: 'SJJ'
	},
	{
		displayName: 'Zagreb (HR)',
		code: 'DEP-ZAG',
		rawCode: 'ZAG'
	},
	{
		displayName: 'Belgrad (SR)',
		code: 'DEP-BEG',
		rawCode: 'BEG'
	}
];

export const DeparturesObservable: Observable<DropdownItem[]> = Observable.of(DEPARTURES.map(dep => ({
	value: dep.code,
	rawValue: dep.rawCode,
	valueLabel: dep.rawCode,
	displayName: dep.displayName,
	displayNameShort: dep.rawCode
})));
