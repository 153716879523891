import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

import { Deeplink, DeeplinkImage, DeeplinkPool, DeeplinkParameters, INITIAL_DEEPLINK } from '../shared/models/deeplink/deeplink.model';

import { VanessaService } from './vanessa.service';

import { QueryTypes } from '../shared/models/query/query-type.model';
import { StateManagerService } from './state-manager.service';
import { parseFromDeeplinkModel } from '../shared/models/query/search-form.model';
import { TopBox } from '../shared/models/top-box.model';


@Injectable()
export class TopboxService {
	private _cfg: string = '0999930000000000';
	private _apiUrl: string = 'https://api.go-suite.com/urania-topboxen/v1';
	private _uraniaUrl: string = 'https://schmetterling-urania.com/#/offers?';

	public deeplinkPool: DeeplinkPool;
	public deeplinkUrl: string;

	public deeplinkSet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public deeplinkChecked: boolean = false;

	public newImage: DeeplinkImage = null;
	public images: DeeplinkImage[];

	public constructor(
		private http: HttpClient,
		private vanessaService: VanessaService,
		public stateManagerService: StateManagerService) {
	}

	public setCfg(cfg: string) {
		this._cfg = cfg;
	}

	public getDeeplinks(): Observable<TopBox[]> {
		return this.http.get<TopBox[]>(`${this._apiUrl}/${this._cfg}`);
	}

	public deleteTopBox(id: string): Observable<void> {
		return this.http.delete<void>(`${this._apiUrl}/${this._cfg}/${id}`);
	}

	saveEditDeeplink(): Observable<void> {
		const deeplink: Deeplink = this.stateManagerService.getActiveDeeplinkState(6);
		const newTopBox: TopBox = {
			text: deeplink.name || deeplink.parameters.offerBoxRegionName,
			imageSrc: this.newImage.url,
			link: {
				step: 'hotels',
				attributes: {
					adult_count: deeplink.parameters.adult_count,
					type: deeplink.parameters.type,
					tags: deeplink.parameters.tagsString,
				},
			},
		};

		if (!newTopBox.id) {
			newTopBox.id = String((new Date()).getTime());
			return this.http.post<void>(`${this._apiUrl}/${this._cfg}/`, JSON.stringify(newTopBox));
		} else {
			return this.http.put<void>(`${this._apiUrl}/${this._cfg}/${newTopBox.id}`, JSON.stringify(newTopBox));
		}
	}

	public buildDeeplinkUrl(): void {
		const deeplinkParams: DeeplinkParameters = this.stateManagerService.getActiveDeeplinkState(5).parameters;
		const params = new URLSearchParams();
		let uraniaUrl: string;

		params.append('start_date', deeplinkParams.start_date);
		params.append('end_date', deeplinkParams.end_date);
		params.append('adult_count', deeplinkParams.adult_count.toString());
		params.append('children_ages', deeplinkParams.children_ages);
		params.append('type', (deeplinkParams.type || QueryTypes.PA).toUpperCase());
		params.append('limit', deeplinkParams.limit.toString());
		params.append('offset', deeplinkParams.offset.toString());
		params.append('orderBy', deeplinkParams.orderBy);
		params.append('orderDirection', deeplinkParams.orderDirections);
		params.append('lang', deeplinkParams.lang);
		params.append('cfg', this._cfg);
		params.append('version', deeplinkParams.version.toString());

		if (typeof deeplinkParams.ref === 'number' && !isNaN(deeplinkParams.ref)) {
			params.append('ref', deeplinkParams.ref.toString());
		}

		if (typeof deeplinkParams.maxPrice === 'number' && !isNaN(deeplinkParams.maxPrice) && deeplinkParams.maxPrice > 0) {
			params.append('maxPrice', deeplinkParams.maxPrice.toString());
		}

		if (deeplinkParams.offerId) {
			params.append('id', deeplinkParams.offerId);
		}

		uraniaUrl = this._uraniaUrl + params.toString() + '&' + deeplinkParams.tagsString;

		this.deeplinkUrl = uraniaUrl;
	}

	public setEditDeeplink(deeplink: Deeplink): void {
		const initialDeeplink: Deeplink = {...INITIAL_DEEPLINK};
		deeplink = {...deeplink};

		deeplink.parameters = _.assign(initialDeeplink.parameters, deeplink.parameters);

		this.stateManagerService.setDeeplinkState(deeplink, 1);

		const deeplinkParams: DeeplinkParameters = this.stateManagerService.getActiveDeeplinkState(1).parameters;
		this.stateManagerService.setCurrentSearchFormState(parseFromDeeplinkModel(deeplinkParams), 1);

		if (deeplinkParams.type) {
			this.vanessaService.currentQueryType = deeplinkParams.type;
		}

		if (deeplinkParams.selectDate) {
			this.vanessaService.currentSelectDate = deeplinkParams.selectDate;
		}

		if (deeplinkParams.selectedGroup) {
			this.vanessaService.selectedGroup = deeplinkParams.selectedGroup;
			this.vanessaService.groupIsSelected$.next(true);
		}

		if (deeplinkParams.offerId) {
			this.vanessaService.showAllOffers = false;
		}

		this.deeplinkSet$.next(true);
	}
}
