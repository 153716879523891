import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DeeplinkImage} from '../shared/models/deeplink/deeplink.model';
import {TopboxService} from './topbox.service';
import {BehaviorSubject, Subscription} from 'rxjs/Rx';

@Injectable()
export class HotelviewService {
    private _apiEndpoint = '//proxy.schmetterling-argus.de/hotelview-api/';
    public showPicturesLoader$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private _subscriptions: Subscription[] = [];
    private _siteId: string = '';

    constructor(private http: HttpClient, private topboxService: TopboxService) {
        this.topboxService.deeplinkSet$.filter(val => val === true).subscribe(() => {
            this._siteId = !isDevMode() ? this.topboxService.deeplinkPool.site.id.toString() : '';
        })
    }

    public getImages(giataId: any, operator: string) {
        this._subscriptions.forEach(value => value.unsubscribe());
        this._subscriptions = [];

        this.showPicturesLoader$.next(true);

        this._subscriptions.push(this.http.get(this._apiEndpoint + 'hotel?location_id=' + giataId + '&operator=' + operator + '&limit=1')
            .mergeMap(res => {
                let catalogueId = res['_embedded'].hotel[0].id

                return this.http.get(this._apiEndpoint + 'image/' + catalogueId);
            })
            .subscribe(res => {
                let availableImages = res['_embedded'];
                let supportedImages: DeeplinkImage[] = [];
                let tmpSiteId = this._siteId;

                if (this._siteId) {
                    tmpSiteId = '_' + this._siteId;
                }

                availableImages.map(function (img) {
                    if (typeof img['800'] !== 'undefined') {
                        let imgName = img['id'] + '_' + img['800'].match(/(\d+$)/)[1] + tmpSiteId + '.jpg';

                        let image: DeeplinkImage = {
                            url: img['800'],
                            name: imgName.replace(/\s/g, '_'),
                            dev: isDevMode()
                        };

                        supportedImages.push(image);
                    }
                }, this);

                this.topboxService.images = supportedImages;
                this.showPicturesLoader$.next(false);
            }, () => {
                this.topboxService.images = [];
                this.showPicturesLoader$.next(false);
            }));
    }
}
