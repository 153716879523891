import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AuthService {
    private _token: string;
    private _oAuth2Url: string = '//attacus.sr-vice.de/oAuth2/auth/implicit/';
    private _oAuth2LogoutUrl = '//attacus.sr-vice.de/oAuth2/auth/logout/';
    private _clientId: number = 2;
    private _scopes: Array<string> = ['personal', 'agent', 'acl'];
    private _expiration: string;
    private _dateFormat: string = 'YYYY/MM/DD HH:mm:ss';

    constructor(private http: HttpClient) {
        this._expiration = this.getExpiration();
    }

    isLoggedIn(): boolean {
        let today = moment().format(this._dateFormat),
            expiration = moment(this._expiration, this._dateFormat);

        return (this._token !== 'null' && this._token !== '' && this._expiration !== '' && expiration.isValid()
            && expiration.format(this._dateFormat) !== '' && moment(today, this._dateFormat).diff(expiration, 'seconds') <= 0) ? true : false;
    }

    login() {
        window.location.href = this._oAuth2Url + '?client_id=' + this._clientId + '&scope=' + this._scopes.join('%20')
            + '&response_type=token&state=&redirect_uri=' + window.location.href;

    }

    logout() {
        for (let item in sessionStorage) {
            sessionStorage.removeItem(item);
        }

        window.location.href = this._oAuth2LogoutUrl + '?client_id=' + this._clientId + '&scope=' + this._scopes.join('%20')
            + '&response_type=token&state=&redirect_uri=' + window.location.href;
    }

    getHashFragment(search: string) {
        let hashes: Array<string> = (window.location.hash.substr(1)) ? window.location.hash.substr(1).split('&') : [],
            fragments: Array<string> = [];

        if (hashes.length > 0) {
            for (let hash of hashes) {
                let splitted: Array<string> = hash.split('=');
                if (splitted[0] === search) {
                    return splitted[1];
                }
            }
        }

        return '';
    }

    getToken(): string {
        return ('token' in sessionStorage) ? sessionStorage.getItem('token') : '';
    }

    getExpiration(): string {
        return (sessionStorage.getItem('expiration') !== null) ? sessionStorage.getItem('expiration') : '';
    }

    setToken(token: string) {
        sessionStorage.setItem('token', token);
        this._token = token;
    }

    setExpiration(expires_in: string) {
        let today = moment(),
            expiration = moment().add(expires_in, 'seconds');
        if (expiration.isValid()) {
            sessionStorage.setItem('expiration', expiration.format(this._dateFormat));
            this._expiration = sessionStorage.getItem('expiration');
        } else {
            sessionStorage.setItem('expiration', '');
            this._expiration = '';
        }
    }
}
