export const QueryTypes = {
    PA: 'PA',
    NH: 'NH',
    NF: 'NF'
};

export const QUERY_LIMIT = 500;

export const SelectDateTypes = {
    absolute: '1',
    relative: '2'
}
