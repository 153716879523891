import {Injectable} from '@angular/core';
import {InitialSearchFormState, SearchFormParameters} from '../shared/models/query/search-form.model';
import {Deeplink, INITIAL_DEEPLINK} from '../shared/models/deeplink/deeplink.model';
import * as _ from 'lodash';

@Injectable()
export class StateManagerService {
    private currentSearchFormStates: Object = {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null
    };

    private previousSearchFormStates: Object = {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null
    };

    private deeplinkStates: Object = {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null
    }

    public constructor() {
        this.setCurrentSearchFormState(InitialSearchFormState, 1);
        this.setDeeplinkState(INITIAL_DEEPLINK, 1);
    }

    public isEqual<T>(state1: T, state2: T): boolean {
        return _.isEqual(state1, state2);
    }

    public setCurrentSearchFormState(searchFormState: SearchFormParameters, currentStep: number): void {
        if (!this.isEqual(searchFormState, this.getActiveSearchFormState(currentStep))) {
            for (let i = currentStep; i <= Object.keys(this.currentSearchFormStates).length; i++) {
                this.currentSearchFormStates[i] = _.assign(this.currentSearchFormStates[i], searchFormState);
            }
        }
    }

    public setPreviousSearchFormState(searchFormState: SearchFormParameters, currentStep: number): void {
        if (!this.isEqual(searchFormState, this.getPreviousSearchFormState(currentStep))) {
            for (let i = currentStep; i <= Object.keys(this.previousSearchFormStates).length; i++) {
                this.previousSearchFormStates[i] = _.assign(this.previousSearchFormStates[i], searchFormState);
            }
        }
    }

    public getActiveSearchFormState(currentStep: number): SearchFormParameters {
        return {...this.currentSearchFormStates[currentStep]};
    }

    public getPreviousSearchFormState(currentStep: number): SearchFormParameters {
        return {...this.previousSearchFormStates[currentStep]};
    }

    public setDeeplinkState(deeplinkState: Deeplink, currentStep: number): void {
        if (!this.isEqual(deeplinkState, this.getActiveDeeplinkState(currentStep))) {
            for (let i = currentStep; i <= Object.keys(this.deeplinkStates).length; i++) {
                this.deeplinkStates[i] = _.assign(this.deeplinkStates[i], deeplinkState);
            }
        }
    }

    public getActiveDeeplinkState(currentStep: number): Deeplink {
        return {...this.deeplinkStates[currentStep]};
    }
}
