import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

import {DeeplinkImage} from '../shared/models/deeplink/deeplink.model';

@Component({
  	selector: 'app-image-picker',
  	templateUrl: './image-picker.component.html',
	styleUrls: ['./image-picker.component.css']
})

export class ImagePickerComponent implements AfterViewInit {
	@Input() images: DeeplinkImage[];
	@Input() loading: boolean = true;
	@Output() selectedImageChange$: EventEmitter<DeeplinkImage> = new EventEmitter<DeeplinkImage>();

	set selectImage(index: number) {
		this.selectedImageChange$.emit(this.images[index]);
	}

	ngAfterViewInit() {
	}
}
