import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {DropdownItem} from '@gosuite/selects';

import {DestinationRegionModel} from '../shared/models/destination/destination-region.model';
import {DestinationCityModel} from '../shared/models/destination/destination-city.model';
import {
    DestinationHotelModel,
    DestinationHotelRequestModel
} from '../shared/models/destination/destination-hotel.model';
import {HotelChainResponseModel} from '../shared/models/hotel-chain/hotel-chain-response.model';
import {IataCodeModel} from '../shared/models/iata-code.model';

import {INTERNATIONAL_IATA_CODES} from '../shared/data/international-iata-codes';
import {METROPOLITAN_AREA_CODES} from '../shared/data/metropolitan-area-codes';
import {DEPARTURES} from '../shared/types/departures-types';

import {uniqBy, compact} from 'lodash';
import {sprintf} from 'sprintf-js';
import {isString} from 'util';
import {QueryTypes} from '../shared/models/query/query-type.model';


@Injectable()
export class SearchService {
    private _baseUrl: string = 'https://proxy.schmetterling-argus.de/bombyx-request-webservice/3/';
    private _regionsEndpoint: string = 'search/region?q=%(q)s&type=%(type)s&lang=%(lang)s';
    private _citiesEndpoint: string = 'search/city?q=%(q)s&type=%(type)s';
    private _hotelsEndpoint: string = 'search/hotels?v2&q=%(searchString)s';
    private _hotelChainEndpoint: string = 'search/hotel_chain?q=%s';


    public constructor(private http: HttpClient) {
    }

    public searchDepartureAndZip(searchStr: string): Observable<Array<DropdownItem>> {
        searchStr = searchStr.trim().toUpperCase();

        const departures = this.searchDeparture(searchStr);

        return departures.map(list => uniqBy(list, item => item.value));
    }

    protected searchDeparture(searchStr: string): Observable<Array<DropdownItem>> {
        return Observable.of(DEPARTURES.filter(departure => {
            return departure.rawCode.startsWith(searchStr)
                || departure.displayName.toUpperCase().startsWith(searchStr);
        }).map(departure => ({
            displayName: departure.displayName,
            displayNameShort: departure.rawCode,
            value: departure.code,
            valueLabel: departure.rawCode,
            rawValue: departure.rawCode
        })));
    }


    public searchRegion(searchStr: string, type: string): Observable<Array<DropdownItem>> {
        let lang: string = 'de';
        let url: string = this._baseUrl + this._regionsEndpoint;

        url = sprintf(url, {
            q: searchStr,
            type: type,
            lang: lang
        });

        return this.http.get<Array<DestinationRegionModel>>(url)
            .map(res => res.map(r => ({
                value: r.id,
                rawValue: r.id,
                displayName: r.name,
                displayNameShort: r.name,
                valueLabel: 'Region'
            })))
            .catch(() => Observable.of([]));
    }

    public searchCity(searchStr: string, type: string): Observable<Array<DropdownItem>> {
        let url = this._baseUrl + this._citiesEndpoint;

        url = sprintf(url, {
            q: searchStr,
            type: type
        });

        return this.http.get<Array<DestinationCityModel>>(url)
            .map(res => res.map((r: DestinationCityModel) => ({
                value: r.id,
                rawValue: r.id,
                displayName: r.name,
                displayNameShort: r.name,
                subtitle: '',
                valueLabel: 'Stadt'
            })))
            .catch(() => Observable.of([]));
    }


    public getHotels(searchStr: string): Observable<Array<DropdownItem>> {
        if (isString(searchStr) && searchStr.length >= 2) {
            let url: string = this._baseUrl + this._hotelsEndpoint;

            url = sprintf(url, {
                searchString: searchStr
            });

            return this.http.get<DestinationHotelRequestModel>(url)
                .map(res => res.data)
                .map(res => res.map((r: DestinationHotelModel) => ({
                    value: r.ids.map(id => `BT01-${id}`).join(':'),
                    rawValue: r.ids.map(id => `BT01-${id}`).join(':'),
                    displayName: `${r.hotelName}, ${r.cityName}`,
                    displayNameShort: r.hotelName,
                    valueLabel: 'Hotel'
                })))
                .catch(() => Observable.of([]));
        }

        return Observable.of([]);
    }

    public searchHotelChain(searchStr: string): Observable<Array<DropdownItem>> {
        let url = this._baseUrl + this._hotelChainEndpoint;

        url = sprintf(url, searchStr);

        return this.http.get<HotelChainResponseModel>(url)
            .map(res => res.data.map(r => ({
                value: r.id,
                rawValue: r.id,
                displayName: r.name,
                displayNameShort: r.name
            })))
            .catch(() => Observable.of([]));
    }

    public searchIataCode(searchStr: string, type: string): Observable<Array<DropdownItem>> {
        switch (type) {
            case QueryTypes.NH:
                return Observable.of(INTERNATIONAL_IATA_CODES
                    .filter(item => item.code.indexOf(searchStr.toUpperCase()) !== -1 && item.regionId)
                    .map(item => ({
                        value: item.regionId,
                        rawValue: item.code,
                        displayName: item.code,
                        displayNameShort: item.code,
                        subtitle: '',
                        valueLabel: ''
                    })));

            case QueryTypes.NF:
                return Observable.of(INTERNATIONAL_IATA_CODES
                    .filter(item => item.code.indexOf(searchStr.toUpperCase()) !== -1)
                    .map(item => ({
                        value: 'BT10-' + item.code,
                        rawValue: item.code,
                        displayName: item.code,
                        displayNameShort: item.code,
                        subtitle: '',
                        valueLabel: ''
                    }))
                );

            default:
                return Observable.of(INTERNATIONAL_IATA_CODES.filter(item => item.code.indexOf(searchStr.toUpperCase()) !== -1)
                    .map(item => ({
                        value: 'DST-' + item.code,
                        rawValue: item.code,
                        displayName: item.code,
                        displayNameShort: item.code,
                        subtitle: '',
                        valueLabel: ''
                    })));
        }
    }

    public searchMetropolitanAreaCodes(searchStr: string, type: string): Observable<Array<DropdownItem>> {
        switch (type) {
            case QueryTypes.NH:
                return Observable.of(compact(METROPOLITAN_AREA_CODES
                    .filter(item => item.code.indexOf(searchStr.toUpperCase()) !== -1)
                    .map(item => {
                        const regionIds: Array<string> = [];

                        item.airports.forEach(iataCode => {
                            const iataCodeModel: IataCodeModel = INTERNATIONAL_IATA_CODES.find(iata => iata.code === iataCode);

                            if (iataCodeModel && iataCodeModel.regionId) {
                                regionIds.push(iataCodeModel.regionId);
                            }
                        });

                        return !regionIds.length
                            ? null
                            : {
                                value: regionIds.join(':'),
                                rawValue: item.code,
                                displayName: item.code,
                                displayNameShort: item.airports.join(', '),
                                subtitle: item.airports.join(', '),
                                valueLabel: ''
                            };
                    })
                ));

            case QueryTypes.NF:
                return Observable.of(METROPOLITAN_AREA_CODES
                    .filter(item => item.code.indexOf(searchStr.toUpperCase()) !== -1)
                    .map(item => ({
                        value: item.airports.map(code => 'BT10-' + code).join(':'),
                        rawValue: item.code,
                        displayName: item.code,
                        displayNameShort: item.airports.join(', '),
                        subtitle: item.airports.join(', '),
                        valueLabel: ''
                    }))
                );

            default:
                return Observable.of(METROPOLITAN_AREA_CODES
                    .filter(item => item.code.indexOf(searchStr.toUpperCase()) !== -1)
                    .map(item => ({
                        value: item.airports.map(code => 'DST-' + code).join(':'),
                        rawValue: item.code,
                        displayName: item.code,
                        displayNameShort: item.airports.join(', '),
                        subtitle: item.airports.join(', '),
                        valueLabel: ''
                    }))
                );
        }
    }

    public searchCityIataAndRegion(searchStr: string, type: string): Observable<Array<DropdownItem>> {
        if (type.toUpperCase() === QueryTypes.NF) {
            return Observable.forkJoin(
                this.searchIataCode(searchStr, type),
                this.searchMetropolitanAreaCodes(searchStr, type),
                this.searchRegion(searchStr, type)
            )
                .map(responses => responses[0].concat(responses[1], responses[2]));
        }

        return Observable.forkJoin(
            this.searchIataCode(searchStr, type),
            this.searchMetropolitanAreaCodes(searchStr, type),
            this.searchRegion(searchStr, type),
            this.searchCity(searchStr, type)
        )
            .map(responses => responses[0].concat(responses[1], responses[2], responses[3]))
            .map(list => uniqBy(list, item => item.value));
    }
}
