import { AttributesGroupModel } from '../models/attributes-group.model';

export const ATTRIBUTE_GROUPS: Array<AttributesGroupModel> = [
	{
		displayName: 'Lage',
		attributes: [
			'GT03-BEAC/ST03-DIRE',
			'GT03-BEAC/ST03-SAND',
		],
	},
	{
		displayName: 'Ausstattung',
		attributes: [
			'GT05-AICO',
			'GT03-CAPA',
			'GT03-INTE',
			'GT03-WHAC',
			'GT03-POOL',
		],
	},
	{
		displayName: 'Wellness & Sport',
		attributes: [
			// 'GT03-SPAA',
			'GT03-SAUN',
			'GT08-WELL',
			'GT03-DIVE',
			'GT03-GOLF',
			'GT03-TENN',
			'GT03-GYMN',
		],
	},
	// {
	// 	displayName: 'Hotel Themen',
	// 	attributes: [
	// 		'GT08-MUSI',
	// 		'GT03-FAMI',
	// 		'GT03-CLTO',
	// 		'GT02-HC/ST02-BC',
	// 		'GT02-HC/ST02-AC',
	// 	],
	// },
];


export const EXCLUSIVE_ATTRIBUTES: { [s: string]: Array<string> } = {

	// if setSelected       -> remove
	'GT03-BEAC/ST03-DIRE': ['GT03-BEAC/ST03-SAND'],
	'GT03-BEAC/ST03-SAND': ['GT03-BEAC/ST03-DIRE'],
};
