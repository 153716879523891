export const TRANSFER_TYPES: Array<string> = [
	'GT14-WT',
	'GT14-TR',
	'GT14-HC'
];

/**
 * WITH SHORT-CUTS
 */
/*export const TRANSFER_TYPES: Array<Object> = [
	{ 'WT': 'GT14-WT' },
	{ 'TR': 'GT14-TR' },
	{ 'HC': 'GT14-HC' }
];*/
