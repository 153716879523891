import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isArray } from 'util';


@Component({
	selector: 'category-stars',
	templateUrl: './category-stars.component.html',
	styleUrls: ['./category-stars.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryStarsComponent {
	public categoryStars: Array<boolean>;

	@Input() public set value(cat: Array<string>) {
		if (isArray(cat) && cat.length === 1) {
			this.categoryStars = CategoryStarsComponent.createCategoryArray(cat);
		} else {
			this.categoryStars = null;
		}
	}


	public static createCategoryArray(category: Array<string>): Array<boolean> {
		const stars: Array<boolean> = [];
		const score: number = parseInt(category[0], 10);

		for (let i = 0; i < ((score - score % 10) / 10); i++) {
			stars.push(true);
		}

		if (score % 10 !== 0) {
			stars.push(false);
		}

		return stars;
	}
}
