export const SPORT_TYPES: Array<string> = [
	'GT03-AERO',
	'GT03-ARCH',
	'GT03-BABO',
	'GT03-BADM',
	'GT03-BASK',
	'GT03-BESP',
	'GT03-BEVO',
	'GT03-BILL',
	'GT03-BOAL',
	'GT03-BOCC',
	'GT03-CANO',
	'GT03-CATA',
	'GT03-DART',
	'GT03-FITN',
	'GT03-GOLF',
	'GT03-GYMN',
	'GT03-HORI',
	'GT03-MINI',
	'GT03-PEBO',
	'GT03-SAIL',
	'GT03-SQUA',
	'GT03-SURF',
	'GT03-TATE',
	'GT03-TENN',
	'GT03-WATE',
	'GT03-WIND',
	// 'ST03-MOBI',
];
