import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {DropdownItem, FilterSets} from '@gosuite/selects';

import {SearchService} from './search.service';

import {SearchFormParameters, toHTTPQuery, validateHTTPQuery} from '../shared/models/query/search-form.model';
import {Group} from '../shared/models/groups/groups.model';
import {GroupsResponseModel} from '../shared/models/groups/groups-response.model';
import {ValidationResponseModel} from '../shared/models/validation-response.model';
import {TourOperatorModel} from '../shared/models/tour-operator.model';
import {CFG} from '../shared/models/deeplink/deeplink.model';

import {TypeMapper} from '../shared/mappings/type-mapper';
import {ROOM_TYPES} from '../shared/types/room-types';
import {ATTRIBUTE_GROUPS} from '../shared/types/attributes-types';
import {TRANSFER_TYPES} from '../shared/types/transfer-types';
import {WELLNESS_TYPES} from '../shared/types/wellness-types';
import {SPORT_TYPES} from '../shared/types/sport-types';
import {TOUR_OPERATORS} from '../shared/types/tour-operators-types';
import {TARGET_GROUP_TYPES} from '../shared/types/target-group-types';
import {BOARD_TYPES} from '../shared/types/board-types';
import {
    ATTRIBUTE_TYPE_NAME, BOARD_TYPE_NAME, REGION_TYPE_NAME, ROOM_TYPE_NAME, SPORT_TYPE_NAME, TARGET_GROUP_TYPE_NAME,
    TRANSFER_TYPE_NAME, WELLNESS_TYPE_NAME
} from '../shared/mappings/mappings/type-mappings';
import {DeparturesObservable} from '../shared/types/departures-types';

import {isArray, isNullOrUndefined, isObject} from 'util';
import {sprintf} from 'sprintf-js';
import * as moment from 'moment';

import {QueryTypes, SelectDateTypes} from '../shared/models/query/query-type.model';
import {StateManagerService} from './state-manager.service';


@Injectable()
export class VanessaService {
    private _baseUrl: string = 'https://schmetterling-urania.com/webservice/';
    private _hotelApiEndpoint: string = 'groups?sid=%(sid)s';
    private _offerApiEndpoint: string = 'termins?sid=%(sid)s';
    private _validationApiEndpoint: string = 'validate?sid=%(sid)s';
    private _loginUrl: string = `https://schmetterling-urania.com/webservice/login/urania.master/NUNe0aKVS99H/%cfg%/2`;
    private _cfg: string = CFG;

    public showDestinationPreloader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showDeparturePreloader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showHotelNamePreloader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showHotelChainPreloader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showGroupsLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showOffersLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public hotelChains$: Observable<DropdownItem[]>;
    public departures$: Observable<DropdownItem[]>;
    public defaultDepartures$: Observable<DropdownItem[]>;
    public destinations$: Observable<DropdownItem[]>;
    public hotels$: Observable<DropdownItem[]>;
    public touroperators$: Observable<DropdownItem[]>;

    public selectedGroup: Group = null;
    public selectedHotelCode: string = '';
    public groupIsSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public boardTypes: DropdownItem[];
    public roomTypes: DropdownItem[];
    public attributesTypes: DropdownItem[] = [];
    public transferTypes: DropdownItem[];
    public targetGroupTypes: DropdownItem[];
    public wellnessTypes: DropdownItem[];
    public sportTypes: DropdownItem[];
    public filters: FilterSets[];

    public currentQueryType: string = QueryTypes.PA;
    public currentSelectDate: string = SelectDateTypes.absolute;
    public showAllOffers: boolean = true;

    public groupsFoundSubject$: Subject<Array<Group>> = new Subject<Array<Group>>();
    public offersFoundSubject$: Subject<Array<Group>> = new Subject<Array<Group>>();

    public validationRequests$: Subscription[] = [];

    public constructor(private http: HttpClient, private searchService: SearchService, private typeMapper: TypeMapper,
                       private stateManagerService: StateManagerService) {
        this.filters = [
            {
                sets: [
                    {
                        name: 'Fairplay-Veranstalter',
                        flags: ['FAIRPLAY'],
                    },
                    {
                        name: 'Keine X-Veranstalter',
                        flags: ['X', 'TOP-SET'],
                        filterReverse: true
                    }
                ]
            }
        ];

        this.boardTypes = BOARD_TYPES.map(boardGt => {
            const rawValue: string = Object.keys(boardGt)[0];

            return {
                value: boardGt[rawValue],
                rawValue: rawValue,
                valueLabel: rawValue,
                displayName: this.typeMapper.map(boardGt[rawValue], BOARD_TYPE_NAME),
                displayNameShort: rawValue
            };
        });

        this.roomTypes = ROOM_TYPES.map(roomGt => ({
            value: roomGt,
            rawValue: roomGt,
            displayName: this.typeMapper.map(roomGt, ROOM_TYPE_NAME),
            displayNameShort: this.typeMapper.map(roomGt, ROOM_TYPE_NAME),
        }));

        ATTRIBUTE_GROUPS.forEach(group => group.attributes.forEach(attributeGt => {
            this.attributesTypes.push({
                value: attributeGt,
                rawValue: attributeGt,
                displayName: this.typeMapper.map(attributeGt, ATTRIBUTE_TYPE_NAME),
                displayNameShort: this.typeMapper.map(attributeGt, ATTRIBUTE_TYPE_NAME)
            });
        }));

        this.transferTypes = TRANSFER_TYPES.map(transferGt => ({
            value: transferGt,
            rawValue: transferGt,
            displayName: this.typeMapper.map(transferGt, TRANSFER_TYPE_NAME),
            displayNameShort: this.typeMapper.map(transferGt, TRANSFER_TYPE_NAME),
        }));

        this.wellnessTypes = WELLNESS_TYPES.map(wellnessGt => ({
            value: wellnessGt,
            rawValue: wellnessGt,
            displayName: this.typeMapper.map(wellnessGt, WELLNESS_TYPE_NAME),
            displayNameShort: this.typeMapper.map(wellnessGt, WELLNESS_TYPE_NAME)
        }));

        this.sportTypes = SPORT_TYPES.map(sportGt => ({
            value: sportGt,
            rawValue: sportGt,
            displayName: this.typeMapper.map(sportGt, SPORT_TYPE_NAME),
            displayNameShort: this.typeMapper.map(sportGt, SPORT_TYPE_NAME)
        }));

        this.touroperators$ = this.getTouroperators();

        this.targetGroupTypes = TARGET_GROUP_TYPES.map(targetGroupGt => ({
            value: targetGroupGt,
            rawValue: targetGroupGt,
            displayName: this.typeMapper.map(targetGroupGt, TARGET_GROUP_TYPE_NAME),
            displayNameShort: this.typeMapper.map(targetGroupGt, TARGET_GROUP_TYPE_NAME)
        }));

        // sets default departures$
        this.defaultDepartures$ = DeparturesObservable;
        this.departures$ = this.defaultDepartures$;
    }

    public setCfg(cfg: string) {
    	this._cfg = cfg;
	}

    public login(): Observable<any> {
        return this.http.get(this._loginUrl.replace('%cfg%', this._cfg), {withCredentials: true}).map(res => res['token']);
    }

    public getDeparturesBySearchStr(searchStr: string): Observable<Array<DropdownItem>> {
        this.showDeparturePreloader$.next(true);

        return this.searchService.searchDepartureAndZip(searchStr)
            .do(() => this.showDeparturePreloader$.next(false));
    }

    public getDestinationsBySearchStr(searchStr: string): Observable<Array<DropdownItem>> {
        this.showDestinationPreloader$.next(true);

        return this.searchService.searchCityIataAndRegion(searchStr, this.currentQueryType)
            .do(() => this.showDestinationPreloader$.next(false));
    }

    public getHotelChainsBySearchStr(searchStr: string): Observable<Array<DropdownItem>> {
        this.showHotelChainPreloader$.next(true);

        return this.searchService.searchHotelChain(searchStr)
            .do(() => this.showHotelChainPreloader$.next(false));
    }

    public getHotelGiataIdOrCode(searchStr: string): Observable<Array<DropdownItem>> {
        if (searchStr.startsWith('#')) {
            return this._getBookingCode(searchStr);
        }

        this.showHotelNamePreloader$.next(true);

        return Observable.forkJoin(
            this._getGiataId(searchStr),
            this.searchService.getHotels(searchStr)
        )
            .map(responses => responses[0].concat(responses[1]))
            .do(() => this.showHotelNamePreloader$.next(false));
    }

    public groups(removeHotels: boolean = false): Observable<GroupsResponseModel> {
        let tmpQuery: SearchFormParameters = this.stateManagerService.getActiveSearchFormState(2);

        if (removeHotels) {
            tmpQuery.hotelName = [];
        }

        this.showGroupsLoader$.next(true);

        return this._query<GroupsResponseModel>(`${this._baseUrl + this._hotelApiEndpoint}&${toHTTPQuery(tmpQuery)}`)
            .map((res: GroupsResponseModel) => this._groupsStringDateToDate(res))
            .map((res: GroupsResponseModel) => this._groupsOperatorsObjToArray(res))
            .map((res: GroupsResponseModel) => this._transformTagsToMap(res))
            .map((res: GroupsResponseModel) => this._extractImportantAttributes(res))
            .map((res: GroupsResponseModel) => this._mapRegionCodeToRegionName(res));
    }

    public getAvailableGroups(): void {
        if (!this.stateManagerService.isEqual(this.stateManagerService.getActiveSearchFormState(2),
                this.stateManagerService.getPreviousSearchFormState(2))) {

            this.selectedGroup = null;
            this.selectedHotelCode = '';

            this.validationRequests$.forEach((val) => val.unsubscribe())
            this.validationRequests$ = [];

            this.groupsFoundSubject$.next([]);

            this.groups().retryWhen(() => {
                return this.groups(true).map((groups: GroupsResponseModel) => {
                    this.groupsFoundSubject$.next(groups.data);
                })
                    .catch(() => {
                        this.groupsFoundSubject$.next([]);

                        return Observable.of([]);
                    });
            }).subscribe((groups: GroupsResponseModel) => {
                this.groupsFoundSubject$.next(groups.data)
            });

            this.stateManagerService.setPreviousSearchFormState(this.stateManagerService.getActiveSearchFormState(2), 2);
        }
    }

    public validate(offer: Group): Observable<ValidationResponseModel> {
        const offerType: string = offer.type === 'XF' ? QueryTypes.PA : offer.type,
            query = validateHTTPQuery(offer.id, offerType, offer.tourOperator, this.stateManagerService.getActiveSearchFormState(4));

        return this._query<ValidationResponseModel>(`${this._baseUrl + this._validationApiEndpoint}&${query}`);
    }

    public offers(bt01: string): Observable<GroupsResponseModel> {
        const tmpQuery: SearchFormParameters = this.stateManagerService.getActiveSearchFormState(4);
        // tmpQuery.destination = tmpQuery.destination.filter(item => (item.value.startsWith('DST-') || item.value.startsWith('CSTM-')));
        tmpQuery.tags += (tmpQuery.tags.length > 0 ? ',BT01-' : 'BT01-') + bt01;

        this.showOffersLoader$.next(true);

        return this._query<GroupsResponseModel>(`${this._baseUrl + this._offerApiEndpoint}&${toHTTPQuery(tmpQuery)}`)
            .map((res: GroupsResponseModel) => this._groupsStringDateToDate(res))
            .map((res: GroupsResponseModel) => this._updateTransportFlightInfo(res))
            .map((res: GroupsResponseModel) => this._groupsOperatorsObjToArray(res))
            .map((res: GroupsResponseModel) => this._mapTagsToHotelAttributes(res))
            .map((res: GroupsResponseModel) => this._mapRegionCodeToRegionName(res));
    }

    public getAvailableOffers(): void {
        if (this.selectedHotelCode !== this.selectedGroup.hotel.tags.BT01[0]) {
            this.validationRequests$.forEach((val) => val.unsubscribe());
            this.validationRequests$ = [];

            this.offersFoundSubject$.next([]);

            this.offers(this.selectedGroup.hotel.tags.BT01[0]).subscribe(
                (groups: GroupsResponseModel) => this.offersFoundSubject$.next(groups.data),
                () => this.offersFoundSubject$.next([])
            );
        }
    }

    private _query<T>(url: string): Observable<T> {
        return this.login().switchMap((token) => this.http.get<T>(
            sprintf(
                url,
                {sid: token}
            ),
            {
                observe: 'response',
            })
            .map((response: HttpResponse<T>) => {
                if (!response.ok) {
                    throw new Error(response.body.toString());
                }

                if (typeof response.body['count'] !== 'undefined' && !response.body['count']) {
                    throw new Error('No results');
                }

                return response.body;
            })
        );
    }

    private _getBookingCode(searchStr: string): Observable<Array<DropdownItem>> {
        if (searchStr.startsWith('#')) {
            return Observable.of([{
                value: 'CODE-' + searchStr,
                rawValue: searchStr,
                displayName: searchStr,
                displayNameShort: searchStr,
                valueLabel: 'Code'
            }]);
        }

        return Observable.of([]);
    }

    private _getGiataId(searchStr: string): Observable<Array<DropdownItem>> {
        if (Number(searchStr)) {
            return Observable.of([{
                value: 'BT01-' + searchStr,
                rawValue: searchStr,
                displayName: searchStr,
                displayNameShort: searchStr,
                valueLabel: 'Giata ID'
            }]);
        }

        return Observable.of([]);
    }

    public getTouroperators(): Observable<DropdownItem[]> {
        const operators: DropdownItem[] = this._filterTourOperators([this.currentQueryType], TOUR_OPERATORS);

        return Observable.of(operators);
    }

    public changeType(type: string): void {
        this.currentQueryType = type;

        this.touroperators$ = this.getTouroperators();
    }

    private _transformTagsToMap(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {
            if (isObject(g.hotel) && g.hotel.tags && g.hotel.tags.GT03) {
                g.hotel.attributesMap = {};

                (g.hotel.tags.GT03 || []).forEach(gt03Tag => {
                    if (gt03Tag.indexOf('/') !== -1) {
                        g.hotel.attributesMap[gt03Tag.split('/')[0]] = true;
                    } else {
                        g.hotel.attributesMap[gt03Tag] = true;
                    }
                });
            }

            return g;
        });

        return groups;
    }

    private _extractImportantAttributes(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {

            g.hotel.importantAttributes = {
                beach: (g.hotel.attributesMap || {})['BEAC'],
                family: (g.hotel.attributesMap || {})['FAMI'],
                pool: (g.hotel.attributesMap || {})['POOL'],
                wellness: this._hasWellnessTag(g),
                sport: this._hasSportTag(g),
            };

            return g;
        });

        return groups;
    }

    private _groupsStringDateToDate(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {
            if (moment(g.startDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']).isValid()) {
                g.startDate = new Date(moment(g.startDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']).toISOString());
            }

            if (moment(g.endDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']).isValid()) {
                g.endDate = new Date(moment(g.endDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']).toISOString());
            }

            return g;
        });

        return groups;
    }

    private _groupsOperatorsObjToArray(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {
            const operators: Array<string> = [];

            for (const key in g.alternateTourOperators) {
                if (!isNullOrUndefined(g.alternateTourOperators[key])) {
                    operators.push(g.alternateTourOperators[key]);
                }
            }

            g.alternateTourOperators = operators;
            g.alternateTourOperatorsCount = operators.length;

            return g;
        });

        return groups;
    }

    private _updateTransportFlightInfo(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {
            g.transports.outbound.departureTime = (g.transports.outbound.departureDate || '00:00').substr(-5);
            g.transports.outbound.arrivalTime = (g.transports.outbound.arrivalDate || '00:00').substr(-5);
            g.transports.outbound.flight = `${(g.transports.outbound.carrier || 'XX')} ${(g.transports.outbound.flightNumber || '0000')}`;

            g.transports.inbound.departureTime = (g.transports.inbound.departureDate || '00:00').substr(-5);
            g.transports.inbound.arrivalTime = (g.transports.inbound.arrivalDate || '00:00').substr(-5);
            g.transports.inbound.flight = `${(g.transports.inbound.carrier || 'XX')} ${(g.transports.inbound.flightNumber || '0000')}`;

            g.transports.outbound.flightInfo = `${g.transports.outbound.departureTime} - ${g.transports.outbound.arrivalTime} | ${g.transports.outbound.flight}`;
            g.transports.inbound.flightInfo = `${g.transports.inbound.departureTime} - ${g.transports.inbound.arrivalTime} | ${g.transports.inbound.flight}`;
            return g;
        });

        return groups;
    }

    private _mapTagsToHotelAttributes(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {
            if (isObject(g.hotel) && g.hotel.board && g.hotel.tags && g.hotel.tags.GT06) {
                g.hotel.board.code = g.hotel.tags.GT06 && g.hotel.tags.GT06[0] && (`GT06-${g.hotel.tags.GT06[0]}`) || '';
            }

            return g;
        });

        return groups;
    }

    private _mapRegionCodeToRegionName(groups: GroupsResponseModel): GroupsResponseModel {
        groups.data = groups.data.map(g => {
            if (!g.hotel.region._) {
                g.hotel.region._ = this.typeMapper.map(g.hotel.region.id, REGION_TYPE_NAME)
            }

            return g;
        });

        return groups;
    }

    private _hasWellnessTag(group: Group): boolean {
        for (const tag of ['MASS', 'SAUN', 'SOLA', 'SPAA']) {
            if ((group.hotel.attributesMap || {})[tag]) {
                return true;
            }
        }

        return false;
    }

    private _hasSportTag(group: Group): boolean {
        for (const tag of ['ARCH', 'BABO', 'BADM', 'BASK', 'BESP', 'BEVO', 'BILL', 'BOAL', 'BOCC', 'CANO', 'CATA', 'DART', 'GOLF', 'MINI', 'PEBO', 'SAIL', 'SQUA', 'SURF', 'TATE', 'TENN', 'WATE', 'WIND']) {
            if ((group.hotel.attributesMap || {})[tag]) {
                return true;
            }
        }

        return false;
    }

    private _filterTourOperators(filterFlags: Array<string>, operators: Array<TourOperatorModel>): Array<DropdownItem> {
        const filteredOperators: Array<DropdownItem> = [];

        if (isArray(filterFlags) && filterFlags.length > 0) {
            operators.forEach(operator => {
                const hasAllFlags: boolean = filterFlags.some(flag => operator.flags.indexOf(flag) !== -1);

                if (hasAllFlags) {
                    filteredOperators.push({
                        value: operator.code,
                        rawValue: operator.rawCode,
                        displayName: operator.name,
                        displayNameShort: operator.rawCode,
                        flags: operator.flags,
                        valueLabel: operator.rawCode
                    });
                }
            });
        } else {
            operators.forEach(operator => {
                filteredOperators.push({
                    value: operator.code,
                    rawValue: operator.rawCode,
                    displayName: operator.name,
                    displayNameShort: operator.rawCode,
                    flags: operator.flags,
                    valueLabel: operator.rawCode
                });
            });
        }

        return filteredOperators;
    }
}
