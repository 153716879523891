import {URLSearchParams} from '@angular/http';

import * as moment from 'moment';
import * as _ from 'lodash';
import {compact} from 'lodash';
import {isNumber, isObject, isString} from 'util';

import {Dates} from '@gosuite/range-datepicker';
import {DropdownItem} from '@gosuite/selects';
import {GT_RAIL_AND_FLY} from '../../types/rail-and-fly-type';
import {INTERNATIONAL_IATA_CODES} from '../../data/international-iata-codes';
import {GT_SEA_VIEW} from '../../types/sea-view-type';
import {Deeplink, DeeplinkParameters, INITIAL_DEEPLINK} from '../deeplink/deeplink.model';
import {QUERY_LIMIT, QueryTypes} from './query-type.model';

export const GT_RATING_PREFIX: string = 'RATING-';
export const GT_DEPARTURE_PREFIX: string = 'DEP-';
export const GT_DESTINATION_PREFIX: string = 'DST-';
export const GT_BOOKING_CODE_PREFIX: string = 'CODE-';
export const GT_CATEGORY_PREFIX: string = 'GT03-STAR/ST03-STAR';
const NON_STOP_FLIGHT_TAG: string = 'STOPS-IN-0,STOPS-OUT-0';

export interface SearchFormParameters {
    selectDate: string;
    tags: string;
    relativeDateFrom: number;
    relativeDateTo: number;
    date: Dates;
    minDuration: number;
    maxDuration: number;
    destination: DropdownItem[];
    departure: DropdownItem[];
    adultCount: number;
    category: number;
    board: DropdownItem[];
    childAge1: number;
    childAge2: number;
    childAge3: number;
    childAge4: number;
    rating: number;
    operator: DropdownItem[];
    roomType: DropdownItem[];
    attributes: DropdownItem[];
    offset: number;
    type: 'PA' | 'NH' | 'NF';
    transfer: DropdownItem[];
    hotelName: Array<DropdownItem>;
    twoWay: boolean;
    wellnessAttributes: DropdownItem[];
    sportAttributes: DropdownItem[];
    targetGroupAttributes: DropdownItem[];
    hotelChain: DropdownItem[];
    nonStopFlight: boolean;
    railAndFly: boolean;
    seaView: boolean;
    maxPrice: number;
    lang: string;
}

export const InitialSearchFormState: SearchFormParameters = {
    selectDate: '1',
    tags: '',
    date: new Dates(moment().add(14, 'days').toDate(), moment().add(28, 'days').toDate()),
    relativeDateFrom: 14,
    relativeDateTo: 28,
    minDuration: 6,
    maxDuration: 8,
    destination: [],
    departure: [],
    adultCount: 2,
    category: null,
    board: [],
    childAge1: null,
    childAge2: null,
    childAge3: null,
    childAge4: null,
    rating: null,
    operator: [],
    roomType: [],
    attributes: [],
    offset: 0,
    type: 'PA',
    transfer: [],
    hotelName: [],
    twoWay: true,
    wellnessAttributes: [],
    sportAttributes: [],
    targetGroupAttributes: [],
    hotelChain: [],
    nonStopFlight: false,
    railAndFly: false,
    seaView: false,
    maxPrice: null,
    lang: 'de'
};

export function validateHTTPQuery(id: string, type: string, operator: string, q: SearchFormParameters) {
    const childAges = compact([q.childAge1, q.childAge2, q.childAge3, q.childAge4]).join(',');
    return `id=${id}&adult_count=${q.adultCount}&children_ages=${childAges}&tags=TOP-${operator}&type=${(type === QueryTypes.NH
        || type === QueryTypes.NF) ? type : QueryTypes.PA}`;
}

export function toHTTPQuery(q: SearchFormParameters, tagsOnly: boolean = false, checkForUnknownDestination?: boolean): string {
    return params(q, tagsOnly, checkForUnknownDestination).toString().replace(/%/g, '%%');
}

export function params(q: SearchFormParameters, tagsOnly: boolean = false, checkForUnknownDestination?: boolean): URLSearchParams {
    let hotelName: DropdownItem = _.cloneDeep(q.hotelName)[0];
    const duration = [];

    if (q.twoWay) {
        if (q.minDuration && q.maxDuration) {
            for (let i = q.minDuration; i <= q.maxDuration; i++) {
                duration.push('DUR-' + i.toString());
            }
        }
    } else {
        duration.push('DUR-0');
    }

    let tags = (q.tags.length > 0) ? q.tags.split(',') : [];

    if (q.destination && q.destination.length > 0) {
        const destination: Array<DropdownItem> = q.destination.map(des => ({
                value: matchCustomTypes(des.value, false),
                rawValue: des.rawValue,
                displayName: '',
                displayNameShort: ''
            })),
            bt10: string = destination.filter(dest => isString(dest.value) && dest.value.startsWith('BT10-'))
                .map(dest => dest.value).join(':'),
            bt09: string = destination.filter(dest => isString(dest.value) && dest.value.startsWith('BT09-'))
                .map(dest => dest.value).join(':'),
            dst: string = destination.filter(dest => isString(dest.value) && dest.value.startsWith('DST-')
                && dest.rawValue.length === 3).map(dest => dest.value.toUpperCase()).join(':');

        let destinations: Array<string> = _.compact([].concat(bt10.length ? bt10 : null, bt09.length ? bt09 : null,
            dst.length ? dst : null));

        const unmappedValues: string = destination.filter(dest => isString(dest.value) &&
            dest.value.startsWith('DST-') && dest.rawValue.length !== 3).map(dest => dest.value.toUpperCase()).join(':');

        if (destinations.length === 0 && unmappedValues.length > 0 && checkForUnknownDestination) {
            destinations = [unmappedValues];
        }

        tags.push(destinations.join(','));
    }

    if (isObject(hotelName)) {
        if (isString(hotelName.rawValue) && hotelName.rawValue.startsWith('T:')) {
            tags.push(hotelName.rawValue.replace('T:', ''));
            hotelName = null;
        } else if (isString(hotelName.value) && hotelName.value.startsWith('BT01-')) {
            let realVal = hotelName.value.match(/BT01-\d+/);
            tags.push(realVal[0]);
            hotelName = null;
        } else if (isString(hotelName.value) && hotelName.value.startsWith('NEW-') && Number(hotelName.rawValue)) {
            tags.push('BT01-' + hotelName.rawValue);
            hotelName = null;
        }
    }

    if (q.departure && q.departure.length > 0) {
        // convert to TAGS
        tags.push(q.departure.map(item => matchCustomTypes(item.value, true)).join(':'));
    }

    if (q.category && isNumber(q.category)) {
        const minCategory: number = q.category * 10,
            category: Array<string> = [];

        for (let i = minCategory; i <= 70; i += 5) {
            if (i % 10 === 0) {
                category.push(GT_CATEGORY_PREFIX + (i / 10));
            } else {
                category.push(GT_CATEGORY_PREFIX + i);
            }
        }

        tags.push(category.join(':'));
    }

    if (q.board && q.board.length > 0) {
        tags.push(q.board.map(item => item.value).join(':'));
    }

    if (q.transfer && q.transfer.length > 0) {
        tags.push(q.transfer.map(item => item.value).join(':'));
    }

    if (q.operator && q.operator.length > 0) {
        tags.push(q.operator.map(item => item.value).join(':'));
    }

    if (q.roomType && q.roomType.length > 0) {
        tags.push(q.roomType.map(item => item.value).join(':'));
    }

    if (q.railAndFly) {
        tags.push(GT_RAIL_AND_FLY);
    }

    if (q.seaView) {
        tags.push(GT_SEA_VIEW);
    }

    if (q.rating && isNumber(q.rating)) {
        const minRating: number = q.rating,
            rating: Array<string> = [];

        for (let i = minRating; i <= 100; i += 10) {
            rating.push(GT_RATING_PREFIX + i);
        }

        tags.push(rating.join(':'));
    }

    if (q.attributes && q.attributes.length > 0) {
        tags.push(q.attributes.map(item => item.value).join(':'));
    }

    if (q.sportAttributes && q.sportAttributes.length > 0) {
        tags.push(q.sportAttributes.map(item => item.value).join(':'));
    }

    if (q.wellnessAttributes && q.wellnessAttributes.length > 0) {
        tags.push(q.wellnessAttributes.map(item => item.value).join(':'));
    }

    if (q.targetGroupAttributes && q.targetGroupAttributes.length > 0) {
        tags.push(q.targetGroupAttributes.map(item => item.value).join(':'));
    }

    if (q.hotelChain && q.hotelChain.length > 0) {
        tags.push(q.hotelChain.map(item => item.value).join(':'));
    }

    if (q.nonStopFlight) {
        tags.push(NON_STOP_FLIGHT_TAG);
    }

    tags = tags.concat(duration.join(':'));
    tags = tags.filter(item => item !== '');

    const searchParams = new URLSearchParams();

    if (tagsOnly) {
        searchParams.append('tags', tags.join(','));
    } else {
        searchParams.append('start_date', moment(q.date.startDate).format('YYYY-MM-DD'));
        searchParams.append('end_date', moment(q.date.endDate).format('YYYY-MM-DD'));
        searchParams.append('type', (q.type || QueryTypes.PA).toUpperCase());
        searchParams.append('tags', tags.join(','));
        searchParams.append('limit', QUERY_LIMIT.toString());
        searchParams.append('offset', q.offset.toString());
        searchParams.append('orderBy', 'price');
        searchParams.append('orderDirection', 'asc');
        searchParams.append('adult_count', q.adultCount.toString());
        searchParams.append('lang', q.lang);

        const childAges: string = parseChildAgesToString(q);

        if (childAges.length > 0) {
            searchParams.append('children_ages', childAges);
        }

        if (isObject(hotelName)) {
            if (
                isString(hotelName.value)
                && (
                    hotelName.value.startsWith(GT_BOOKING_CODE_PREFIX)
                    || (
                        isString(hotelName.rawValue)
                        && hotelName.value.startsWith('NEW-')
                        && hotelName.rawValue.startsWith('#')
                    )
                )
            ) {
                searchParams.append('hotel_code', hotelName.rawValue.replace('#', ''));
            } else {
                searchParams.append('hotel_name', hotelName.rawValue);
            }
        }

        if (q.maxPrice && isNumber(q.maxPrice) && !isNaN(q.maxPrice) && q.maxPrice > 0) {
            searchParams.append('maxPrice', q.maxPrice.toString());
        }
    }

    return searchParams;
}

export function parseToDeeplinkModel(searchFormModel: SearchFormParameters, sourceModel?: Deeplink): Deeplink {
    if (!sourceModel) {
        sourceModel = _.cloneDeep(INITIAL_DEEPLINK);
    }

    let deeplinkParams: DeeplinkParameters = sourceModel.parameters;

    deeplinkParams.start_date = moment(searchFormModel.date.startDate).format('YYYY-MM-DD');
    deeplinkParams.end_date = moment(searchFormModel.date.endDate).format('YYYY-MM-DD');
    deeplinkParams.relativeDateFrom = searchFormModel.relativeDateFrom;
    deeplinkParams.relativeDateTo = searchFormModel.relativeDateTo;
    deeplinkParams.children_ages = parseChildAgesToString(searchFormModel);
    deeplinkParams.adult_count = searchFormModel.adultCount;
    deeplinkParams.type = searchFormModel.type;
    deeplinkParams.offset = searchFormModel.offset;
    deeplinkParams.maxPrice = searchFormModel.maxPrice;
    deeplinkParams.selectDate = searchFormModel.selectDate;
    deeplinkParams.lang = searchFormModel.lang;

    Object.keys(deeplinkParams.tags).map(function (value) {
        if (value !== 'giataId') {
            deeplinkParams.tags[value] = searchFormModel[value];
        }
    });

    deeplinkParams.tagsString = toHTTPQuery(searchFormModel, true);

    return sourceModel;
}

function parseChildAgesToString(q: SearchFormParameters): string {
    const childAges: Array<number> = [];

    for (let i = 1; i < 5; i++) {
        const age: number = parseInt(q['childAge' + i], 10);

        if (age >= 0 && age < 18) {
            childAges.push(age);
        }
    }

    return childAges.join(',');
}

function matchCustomTypes(value: string, dep: boolean): string {
    if (value.startsWith('CSTM-')) {
        value = value.replace('CSTM-', '').toUpperCase();

        INTERNATIONAL_IATA_CODES.forEach(item => {
            if (value === item.code) {
                value = (dep ? GT_DEPARTURE_PREFIX : GT_DESTINATION_PREFIX) + item.code;
            }
        });
    }

    return value;
}

export function parseFromDeeplinkModel(deeplinkParams: DeeplinkParameters): SearchFormParameters {
    let searchFormParameters: SearchFormParameters = _.cloneDeep(InitialSearchFormState);

    if (typeof deeplinkParams.start_date !== 'undefined' && typeof deeplinkParams.end_date !== 'undefined') {
        searchFormParameters.date = new Dates(new Date(deeplinkParams.start_date), new Date(deeplinkParams.end_date));
    }

    if (typeof deeplinkParams.relativeDateFrom !== 'undefined' && typeof deeplinkParams.relativeDateTo !== 'undefined') {
        searchFormParameters.relativeDateFrom = deeplinkParams.relativeDateFrom;
        searchFormParameters.relativeDateTo = deeplinkParams.relativeDateTo;
    }

    if (typeof deeplinkParams.tags !== 'undefined') {
        Object.keys(deeplinkParams.tags).map(function (value) {
            if (value !== 'giataId') {
                searchFormParameters[value] = deeplinkParams.tags[value];
            }
        });
    }

    searchFormParameters.adultCount = typeof deeplinkParams.adult_count !== 'undefined'
        ? deeplinkParams.adult_count
        : searchFormParameters.adultCount;


    if (typeof deeplinkParams.children_ages !== 'undefined' && deeplinkParams.children_ages !== '') {
        let childrenAges: Array<string> = deeplinkParams.children_ages.split(',');

        childrenAges.map(function (value, index) {
            searchFormParameters['childAge' + (index + 1)] = value;
        });
    }

    searchFormParameters.type = typeof deeplinkParams.type !== 'undefined' ? deeplinkParams.type : searchFormParameters.type;
    searchFormParameters.lang = typeof deeplinkParams.lang !== 'undefined' ? deeplinkParams.lang : searchFormParameters.lang;
    searchFormParameters.offset = typeof deeplinkParams.offset !== 'undefined' ? deeplinkParams.offset : searchFormParameters.offset;
    searchFormParameters.maxPrice = typeof deeplinkParams.maxPrice !== 'undefined'
		? deeplinkParams.maxPrice
		: searchFormParameters.maxPrice;
    searchFormParameters.selectDate = typeof deeplinkParams.selectDate !== 'undefined'
		? deeplinkParams.selectDate
		: searchFormParameters.selectDate;

    return searchFormParameters;
}
