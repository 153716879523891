import {
	AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Output
} from '@angular/core';
import {VanessaService} from '../../services/vanessa.service';
import {TopboxService} from '../../services/topbox.service';
import {FlexGrid} from 'wijmo/wijmo.grid';
import {EventArgs} from 'wijmo/wijmo';
import {WjFlexGrid} from 'wijmo/wijmo.angular2.grid';
import {WizardService} from '../../wizard/wizard.service';
import {Group} from '../../shared/models/groups/groups.model';
import {SearchFormParameters, toHTTPQuery} from '../../shared/models/query/search-form.model';
import {Deeplink, DeeplinkParameters} from '../../shared/models/deeplink/deeplink.model';
import {BOARD_TYPE_NAME} from '../../shared/mappings/mappings/type-mappings';
import {TypeMapper} from '../../shared/mappings/type-mapper';
import {StateManagerService} from '../../services/state-manager.service';


@Component({
	selector: 'app-hotel-selection',
	templateUrl: './hotel-selection.component.html',
	styleUrls: ['./hotel-selection.component.scss'],
})

export class HotelSelectionComponent implements AfterViewInit, OnInit {
	public source: Array<Group> = [];
	@Output() disableNextButton$: EventEmitter<boolean> = new EventEmitter();

	@ViewChild(WjFlexGrid) grid: WjFlexGrid;

	private _tmpGroup: Group;
	private _step: number = 3;

	constructor(public vanessaService: VanessaService, private topboxService: TopboxService,
				private wizardService: WizardService, private stateManagerService: StateManagerService,
				private typeMapper: TypeMapper) {

		this.vanessaService.groupsFoundSubject$.subscribe((groups) => {
			this.disableNextButton$.emit(true);
			this.hideGrid();
			this.source = groups;

			if (this.source.length) {
				this.disableNextButton$.emit(false);
				this.showGrid();
			}

			this.vanessaService.showGroupsLoader$.next(false);
		});

		this.wizardService.stepChange$.filter(step => step === this._step).subscribe(() => this.updateStates());
	}

	private hideGrid() {
		this.grid.headersVisibility = 0;
	}

	private showGrid() {
		this.grid.headersVisibility = 1;
	}

	ngOnInit() {
	}

	public ngAfterViewInit() {
		this.setSelection();
	}

	public selectRow(rows: any[]) {
		const row: any = rows[0] ? rows[0] : null;

		if (row) {
			this._tmpGroup = row.dataItem;

			if (this.vanessaService.selectedGroup !== this._tmpGroup) {
				this.vanessaService.selectedGroup = this._tmpGroup;
				this.vanessaService.groupIsSelected$.next(true);
				this.topboxService.deeplinkChecked = false;
			}

		}
	}

	public setSelection() {
		if (this.grid.selectedRows.length) {
			this.initialGroupSelection();
		}

		this.updatedLayout(this.grid);
	}

	public updatedLayout(grid: WjFlexGrid) {
		if (grid.selectedRows.length) {
			grid.scrollIntoView(grid.selectedRows[0].index, 0);
		}
	}

	private initialGroupSelection() {
		this._tmpGroup = this.grid.selectedRows[0].dataItem;
	}

	private updateStates(): void {
		if (this._tmpGroup) {
			const searchFormParams: SearchFormParameters = this.stateManagerService.getActiveSearchFormState(this._step),
				deeplink: Deeplink = this.stateManagerService.getActiveDeeplinkState(this._step),
				deeplinkParams: DeeplinkParameters = deeplink.parameters;

			searchFormParams.tags = '';

			if (searchFormParams.hotelName.length === 0) {
				searchFormParams.tags += (searchFormParams.tags.length > 0 ? ',BT01-' : 'BT01-') + this._tmpGroup.hotel.tags.BT01;
			}

			deeplinkParams.tagsString = toHTTPQuery(searchFormParams, true);
			deeplinkParams.offerId = null;
			deeplinkParams.offerBoxHotelName = this._tmpGroup.hotel.name;
			deeplinkParams.offerBoxRegionName = this._tmpGroup.hotel.region._;
			deeplinkParams.offerBoxDuration = this._tmpGroup.duration;
			deeplinkParams.offerBoxCategory = this._tmpGroup.hotel.tags.CAT[0];
			deeplinkParams.offerBoxPrice = this._tmpGroup.price;
			deeplinkParams.offerBoxHotelBoard = this.typeMapper.map(this.getHotelBoardCode(this._tmpGroup), BOARD_TYPE_NAME);
			deeplinkParams.selectedGroup = this._tmpGroup;
			deeplinkParams.tags.giataId = this._tmpGroup.hotel.locationId;

			this.stateManagerService.setCurrentSearchFormState(searchFormParams, this._step);
			this.stateManagerService.setDeeplinkState(deeplink, this._step);
		}
	}

	private getHotelBoardCode(group: Group): string {
		return group.hotel.tags.GT06.map(function (board) {
			return 'GT06-' + board;
		}).join(':');
	}

	/**
	 * Bound to the initialized event on the wijmo flexgrid
	 */
	initGrid(s: FlexGrid, e: EventArgs) {
		/**
		 * We need to auto size the first row (header) to recalculate the height,
		 * because Firefox has some trouble to get the correct height if we change
		 * paddings with CSS
		 */
		s.autoSizeRow(0, true);
	}

}
