<div class="row">
    <div>
        <div class="heading-container"><span class="heading">Vorschau</span></div>
        <div class="app-container">

            <div class="col">
                <app-offer-box
					[deeplink]="_deeplink"
					[selectedGroup]="vanessaService.selectedGroup"
					[isOffer]="vanessaService.showAllOffers"
			   		[selectedImage]="topboxService.newImage"
                ></app-offer-box>
            </div>

            <div class="col s6">
                <select (change)="onSelectedOperatorChange($event.target.value)" style="display: block">
                    <option
						*ngFor="let operator of _availableGroupOperators" value="{{operator}}"
						[selected]="operator == _selectedGroupOperator"
					>
                        {{operator}}
                    </option>
                </select>

                <app-image-picker
                        [images]="topboxService.images"
                        (selectedImageChange$)="onSelectedImageChange($event)"
                        [loading]="hotelviewService.showPicturesLoader$ | async"
                ></app-image-picker>

				<p>
					<a class="deeplink" [href]="topboxService.deeplinkUrl">Deeplink</a><br>
				</p>

				<textarea cols="80" rows="5">{{topboxService.deeplinkUrl}}</textarea>

            </div>
        </div>
    </div>
</div>
