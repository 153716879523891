export const REGION_TYPE_MAPPING = {
    'BT10-1': 'Mallorca',
    'BT10-2': 'Ibiza',
    'BT10-3': 'Menorca',
    'BT10-4': 'Formentera',
    'BT10-5': 'Gran Canaria',
    'BT10-6': 'Fuerteventura',
    'BT10-7': 'Teneriffa',
    'BT10-8': 'Lanzarote',
    'BT10-9': 'La Palma',
    'BT10-10': 'La Gomera',
    'BT10-11': 'El Hierro',
    'BT10-12': 'Costa Brava',
    'BT10-13': 'Costa Daurada',
    'BT10-14': 'Costa del Azahar',
    'BT10-15': 'Costa Blanca',
    'BT10-16': 'Costa Cálida',
    'BT10-17': 'Golf von Almeria',
    'BT10-18': 'Costa del Sol',
    'BT10-19': 'Costa de la Luz',
    'BT10-20': 'Spanisches Inland',
    'BT10-21': 'Nordspanien-Atlantikküste',
    'BT10-22': 'Barcelona und Umgebung',
    'BT10-23': 'Madrid und Umgebung',
    'BT10-24': 'Algarve',
    'BT10-25': 'Costa da Caparica',
    'BT10-26': 'Costa do Estoril',
    'BT10-27': 'Costa de Prata',
    'BT10-28': 'Lissabon & Umgebung',
    'BT10-29': 'Porto',
    'BT10-30': 'Costa Verde',
    'BT10-31': 'Portugal Inland',
    'BT10-32': 'Madeira',
    'BT10-33': 'Azoren',
    'BT10-34': 'Kap Verden',
    'BT10-35': 'Türkische Riviera - Antalya',
    'BT10-36': 'Türkische Ägäis - Izmir',
    'BT10-37': 'Südl. Ägäis - Dalaman',
    'BT10-38': 'Bodrum',
    'BT10-39': 'Istanbul & Umgebung',
    'BT10-40': 'Türkisches Landesinnere',
    'BT10-41': 'Kilikische Küste - Adana',
    'BT10-42': 'Blaue Reisen',
    'BT10-43': 'Hurghada',
    'BT10-44': 'Marsa Alam',
    'BT10-45': 'Sharm el Sheikh',
    'BT10-46': 'Luxor',
    'BT10-47': 'Kairo & Umgebung',
    'BT10-48': 'Rund um den Nil',
    'BT10-49': 'Assuan',
    'BT10-50': 'Chalkidiki',
    'BT10-51': 'Thrakien',
    'BT10-52': 'Makedonien',
    'BT10-53': 'Olympische Riviera',
    'BT10-54': 'Pilion - Thessalien',
    'BT10-55': 'Athen & Umgebung',
    'BT10-56': 'Epiros',
    'BT10-57': 'Peloponnes',
    'BT10-58': 'Zentral Griechenland',
    'BT10-59': 'Kreta',
    'BT10-60': 'Rhodos',
    'BT10-61': 'Kos',
    'BT10-62': 'Korfu',
    'BT10-63': 'Lefkas & Meganisi',
    'BT10-64': 'Kefalonia',
    'BT10-65': 'Zakynthos',
    'BT10-66': 'Thassos',
    'BT10-67': 'Skiathos & Skopelos',
    'BT10-68': 'Lesbos',
    'BT10-69': 'Chios',
    'BT10-70': 'Samos',
    'BT10-71': 'Ikaria',
    'BT10-72': 'Karpathos',
    'BT10-73': 'Patmos',
    'BT10-74': 'Symi',
    'BT10-75': 'Tilos',
    'BT10-76': 'Amorgos',
    'BT10-77': 'Andros',
    'BT10-78': 'Folegandros',
    'BT10-79': 'Mykonos',
    'BT10-80': 'Naxos',
    'BT10-81': 'Paros & Serifos & Sifnos',
    'BT10-82': 'Santorin',
    'BT10-83': 'Tinos',
    'BT10-84': 'Euböa',
    'BT10-85': 'Ägina',
    'BT10-86': 'Poros',
    'BT10-87': 'Hydra - Spetses',
    'BT10-88': 'Weitere Griechische Inseln',
    'BT10-89': 'Zypern',
    'BT10-90': 'Nordzypern',
    'BT10-91': 'Monastir',
    'BT10-92': 'Hammamet',
    'BT10-93': 'Insel Djerba',
    'BT10-94': 'Tunesien Norden',
    'BT10-95': 'Agadir',
    'BT10-96': 'Casablanca',
    'BT10-97': 'Marokko Inland',
    'BT10-98': 'Istrien',
    'BT10-99': 'Kvarner Bucht',
    'BT10-100': 'Norddalmatien',
    'BT10-101': 'Mitteldalmatien',
    'BT10-102': 'Süddalmatien',
    'BT10-103': 'Insel Brac',
    'BT10-104': 'Insel Krk',
    'BT10-105': 'Insel Hvar',
    'BT10-106': 'Kroatische Inseln',
    'BT10-107': 'Insel Mljet',
    'BT10-108': 'Slowenien',
    'BT10-109': 'Burgas (Sonnenstrand)',
    'BT10-110': 'Varna (Goldstrand)',
    'BT10-111': 'Sofia (Landesinnere)',
    'BT10-112': 'Sardinien',
    'BT10-113': 'Sizilien',
    'BT10-114': 'Ischia',
    'BT10-115': 'Capri',
    'BT10-116': 'Elba',
    'BT10-117': 'Gardasee & Oberitalienische Seen',
    'BT10-118': 'Rom & Umland',
    'BT10-119': 'Neapel & Umland',
    'BT10-120': 'Toskana & Ligurien',
    'BT10-121': 'Lombardei & Aostatal & Piemont',
    'BT10-122': 'Südtirol',
    'BT10-123': 'Venedig & Nördliche Adria',
    'BT10-124': 'Emilia-Romagna',
    'BT10-125': 'Rimini & Marken & Abruzzen',
    'BT10-126': 'Apulien',
    'BT10-127': 'Kalabrien',
    'BT10-128': 'Umbrien',
    'BT10-129': 'Basilikata',
    'BT10-130': 'Malta',
    'BT10-131': 'Gozo & Comino',
    'BT10-132': 'Wien',
    'BT10-135': 'Zillertal',
    'BT10-136': 'Region Seefeld',
    'BT10-137': 'Stubaital',
    'BT10-139': 'Salzburg',
    'BT10-140': 'Salzburg Land',
    'BT10-141': 'Salzkammergut',
    'BT10-142': 'Steiermark',
    'BT10-143': 'Vorarlberg',
    'BT10-144': 'Burgenland',
    'BT10-146': 'Niederösterreich',
    'BT10-147': 'Graubünden',
    'BT10-148': 'Tessin',
    'BT10-149': 'Wallis',
    'BT10-150': 'Genf',
    'BT10-151': 'Waadt',
    'BT10-152': 'Bern & Berner Oberland',
    'BT10-153': 'Basel',
    'BT10-154': 'Luzern',
    'BT10-155': 'Obwalden',
    'BT10-156': 'Uri',
    'BT10-157': 'Schwyz',
    'BT10-158': 'Zürich',
    'BT10-159': 'Thurgau',
    'BT10-160': 'St. Gallen',
    'BT10-161': 'Paris',
    'BT10-162': 'Disneyland',
    'BT10-163': 'Korsika',
    'BT10-164': 'Nizza',
    'BT10-165': 'Monaco',
    'BT10-166': 'Provence - Alpes - Cote d´Azur',
    'BT10-167': 'Languedoc Roussillon',
    'BT10-168': 'Atlantikküste',
    'BT10-169': 'Bretagne',
    'BT10-170': 'Normandie & Nordküste',
    'BT10-171': 'Frankreich Osten',
    'BT10-172': 'Elsass',
    'BT10-173': 'Burgund',
    'BT10-174': 'Französische Alpen',
    'BT10-175': 'Mittelmeerküste',
    'BT10-176': 'weitere Angebote Frankreich',
    'BT10-177': 'Belgien',
    'BT10-178': 'Niederlande',
    'BT10-179': 'Luxemburg',
    'BT10-180': 'London',
    'BT10-181': 'Schottland',
    'BT10-182': 'England',
    'BT10-183': 'Wales',
    'BT10-184': 'Guernsey & Jersey',
    'BT10-185': 'Nordirland',
    'BT10-186': 'Irland',
    'BT10-187': 'Dänemark',
    'BT10-188': 'Schweden',
    'BT10-189': 'Norwegen',
    'BT10-190': 'Finnland',
    'BT10-191': 'Lappland',
    'BT10-192': 'Island',
    'BT10-193': 'St. Petersburg & Umgebung',
    'BT10-194': 'Moskau & Umgebung',
    'BT10-195': 'Russische Riviera',
    'BT10-196': 'Ukraine & Krim',
    'BT10-197': 'Georgien',
    'BT10-198': 'Weissrussland',
    'BT10-199': 'Estland',
    'BT10-200': 'Lettland',
    'BT10-201': 'Litauen',
    'BT10-202': 'Polen',
    'BT10-203': 'Tschechien',
    'BT10-204': 'Slowakei',
    'BT10-205': 'Ungarn',
    'BT10-207': 'Bosnien-Herzegowina',
    'BT10-208': 'Rumänien',
    'BT10-209': 'Dubai',
    'BT10-210': 'Sharjah',
    'BT10-211': 'Ajman',
    'BT10-212': 'Abu Dhabi',
    'BT10-213': 'Ras Al Khaimah',
    'BT10-214': 'Fujairah',
    'BT10-215': 'Umm Al Quwain',
    'BT10-216': 'Al Ain',
    'BT10-217': 'Oman',
    'BT10-218': 'Katar & Bahrain',
    'BT10-219': 'Libanon',
    'BT10-220': 'Jordanien',
    'BT10-221': 'Israel',
    'BT10-222': 'Sri Lanka',
    'BT10-223': 'Malediven',
    'BT10-224': 'Seychellen',
    'BT10-225': 'Mauritius',
    'BT10-226': 'Madagaskar',
    'BT10-227': 'Reunion',
    'BT10-228': 'Phuket',
    'BT10-230': 'Südthailand',
    'BT10-231': 'Koh Samui',
    'BT10-232': 'Bangkok',
    'BT10-233': 'Golf von Siam',
    'BT10-235': 'Zentralthailand',
    'BT10-236': 'Nordthailand',
    'BT10-237': 'Thailändische Inseln',
    'BT10-238': 'Festland',
    'BT10-239': 'Kuala Lumpur',
    'BT10-240': 'Kuantan & Umgebung',
    'BT10-241': 'Langkawi',
    'BT10-242': 'Penang',
    'BT10-243': 'Kleine Malaysische Inseln',
    'BT10-244': 'Kuching',
    'BT10-245': 'Kota Kinabalu',
    'BT10-246': 'Brunei',
    'BT10-247': 'Singapore',
    'BT10-248': 'Bali',
    'BT10-249': 'Lombok',
    'BT10-250': 'Java',
    'BT10-251': 'Jakarta',
    'BT10-252': 'Sumatra',
    'BT10-253': 'Sulawesi',
    'BT10-254': 'Manila & Umgebung',
    'BT10-255': 'Cebu & Boracay-Region',
    'BT10-256': 'Vietnam',
    'BT10-257': 'Kambodscha',
    'BT10-258': 'Laos',
    'BT10-259': 'Myanmar',
    'BT10-260': 'Taiwan',
    'BT10-261': 'Hong Kong',
    'BT10-262': 'Macao',
    'BT10-263': 'China',
    'BT10-264': 'Nepal',
    'BT10-265': 'Indien',
    'BT10-266': 'Südkorea',
    'BT10-267': 'Japan',
    'BT10-268': 'West Autralien',
    'BT10-269': 'Nord Australien',
    'BT10-270': 'Queensland',
    'BT10-271': 'New South Wales',
    'BT10-272': 'Victoria',
    'BT10-273': 'Süd Australien',
    'BT10-274': 'Tasmanien',
    'BT10-275': 'Nordinsel',
    'BT10-276': 'Südinsel',
    'BT10-277': 'Morea',
    'BT10-278': 'Fidschi',
    'BT10-279': 'Polynesien',
    'BT10-280': 'Tahiti',
    'BT10-281': 'Florida',
    'BT10-282': 'Kalifornien',
    'BT10-283': 'Hawaii',
    'BT10-284': 'Nevada',
    'BT10-285': 'New York',
    'BT10-286': 'Alabama',
    'BT10-287': 'Alaska',
    'BT10-288': 'Arizona',
    'BT10-289': 'Arkansas',
    'BT10-290': 'Colorado',
    'BT10-291': 'Connecticut',
    'BT10-292': 'Delaware',
    'BT10-293': 'Georgia',
    'BT10-294': 'Idaho',
    'BT10-295': 'Illinois',
    'BT10-296': 'Indiana',
    'BT10-297': 'Iowa',
    'BT10-298': 'Kansas',
    'BT10-299': 'Kentucky',
    'BT10-300': 'Louisiana',
    'BT10-301': 'Maine',
    'BT10-302': 'Maryland',
    'BT10-303': 'Massachusetts',
    'BT10-304': 'Michigan',
    'BT10-305': 'Minnesota',
    'BT10-306': 'Mississippi',
    'BT10-307': 'Missouri',
    'BT10-308': 'Montana',
    'BT10-309': 'Nebraska',
    'BT10-310': 'New Hampshire',
    'BT10-311': 'New Jersey',
    'BT10-312': 'New Mexico',
    'BT10-313': 'North Carolina',
    'BT10-314': 'North Dakota',
    'BT10-315': 'Ohio',
    'BT10-316': 'Oklahoma',
    'BT10-317': 'Oregon',
    'BT10-318': 'Pennsylvania',
    'BT10-319': 'Rhode Island',
    'BT10-320': 'South Carolina',
    'BT10-321': 'South Dakota',
    'BT10-322': 'Tennessee',
    'BT10-323': 'Texas',
    'BT10-324': 'Utah',
    'BT10-325': 'Vermont',
    'BT10-326': 'Virginia',
    'BT10-327': 'Washington',
    'BT10-328': 'West Virginia',
    'BT10-329': 'Wisconsin',
    'BT10-330': 'Wyoming',
    'BT10-331': 'Ontario',
    'BT10-332': 'Québec',
    'BT10-333': 'Alberta',
    'BT10-334': 'British Columbia',
    'BT10-335': 'Neufundland & Labrador',
    'BT10-336': 'Manitoba',
    'BT10-337': 'Saskatchewan',
    'BT10-338': 'Yukon',
    'BT10-339': 'Nordwest-Territorien',
    'BT10-340': 'Nunavut',
    'BT10-341': 'Nova Scotia',
    'BT10-342': 'New Brunswick',
    'BT10-343': 'Prince Edward Island',
    'BT10-344': 'Nordküste',
    'BT10-345': 'Ostküste',
    'BT10-346': 'Südküste',
    'BT10-347': 'Varadero & Havanna',
    'BT10-348': 'Holguin',
    'BT10-349': 'Cayo Coco',
    'BT10-350': 'Cayo Largo',
    'BT10-352': 'Jamaika',
    'BT10-353': 'Puerto Rico',
    'BT10-354': 'Barbados',
    'BT10-355': 'Antigua & Barbuda',
    'BT10-356': 'St. Lucia',
    'BT10-357': 'Bahamas',
    'BT10-358': 'Aruba & Bonaire & Curacao',
    'BT10-359': 'Trinidad & Tobago',
    'BT10-360': 'Martinique',
    'BT10-361': 'St. Vincent & Grenadinen',
    'BT10-362': 'Grenada',
    'BT10-363': 'Virgin Islands & Anguilla',
    'BT10-364': 'St.Maarten NL & St. Eustatius & Saba',
    'BT10-365': 'St. Martin FR',
    'BT10-366': 'Barbuda',
    'BT10-367': 'Guadeloupe',
    'BT10-368': 'Cayman Islands',
    'BT10-369': 'Turks- & Caicos-Inseln',
    'BT10-370': 'Bermuda',
    'BT10-371': 'Quintana Roo',
    'BT10-372': 'Pazifische Küste',
    'BT10-373': 'Mexiko Stadt & Umgebung',
    'BT10-374': 'Restl. Mexico',
    'BT10-375': 'Costa Rica',
    'BT10-376': 'Guatemala',
    'BT10-377': 'Panama',
    'BT10-378': 'Honduras',
    'BT10-379': 'Recife',
    'BT10-380': 'Rio de Janeiro &amp; Umgebung',
    'BT10-381': 'restliches Brasilien',
    'BT10-382': 'Argentinien',
    'BT10-383': 'Chile',
    'BT10-384': 'Peru',
    'BT10-385': 'Ecuador',
    'BT10-386': 'Kolumbien',
    'BT10-390': 'Tansania & Sansibar',
    'BT10-391': 'Mosambik',
    'BT10-392': 'Johannesburg & Umgebung',
    'BT10-393': 'Kapstadt & Umgebung',
    'BT10-394': 'Suncity',
    'BT10-395': 'Namibia',
    'BT10-396': 'Sambia & Simbabwe',
    'BT10-397': 'Gambia',
    'BT10-398': 'Bodensee-Oberschwaben',
    'BT10-400': 'Hohenlohe-Schwäbisch Hall',
    'BT10-401': 'Kraichgau-Stromberg',
    'BT10-402': 'Kurpfalz',
    'BT10-403': 'Liebliches Taubertal',
    'BT10-405': 'Region Stuttgart',
    'BT10-406': 'Schwarzwald',
    'BT10-407': 'Schwäbische Alb',
    'BT10-409': 'Allgäu & Bayerisch-Schwaben',
    'BT10-410': 'Bayerischer Wald',
    'BT10-411': 'Bayerisches Golf- und Thermenland',
    'BT10-412': 'Fichtelgebirge',
    'BT10-413': 'Frankenalb',
    'BT10-414': 'Frankenwald',
    'BT10-415': 'Fränkische Schweiz',
    'BT10-416': 'Fränkisches Seenland',
    'BT10-417': 'Fränkisches Weinland',
    'BT10-419': 'Naturpark Altmühltal',
    'BT10-421': 'Oberes Maintal & Coburger Land',
    'BT10-423': 'Oberpfälzer Wald',
    'BT10-425': 'Region München',
    'BT10-426': 'Region Nürnberg',
    'BT10-427': 'Rhön (Bayern)',
    'BT10-428': 'Romantisches Franken',
    'BT10-430': 'Spessart-Mainland',
    'BT10-431': 'Steigerwald',
    'BT10-432': 'Region Berlin',
    'BT10-433': 'Barnimer Land',
    'BT10-434': 'Dahme-Seengebiet',
    'BT10-435': 'Elbe-Elster-Land',
    'BT10-436': 'Fläming',
    'BT10-437': 'Havelland',
    'BT10-438': 'Märkisch-Oderland',
    'BT10-439': 'Niederlausitz',
    'BT10-440': 'Oder-Spree-Seengebiet',
    'BT10-441': 'Prignitz',
    'BT10-442': 'Ruppiner Land',
    'BT10-443': 'Spreewald',
    'BT10-444': 'Uckermark',
    'BT10-447': 'Region Hamburg',
    'BT10-453': 'Odenwald',
    'BT10-456': 'Rhön (Hessen)',
    'BT10-458': 'Taunus',
    'BT10-459': 'Vogelsberg',
    'BT10-464': 'Westerwald',
    'BT10-465': 'Fischland-Darss-Zingst',
    'BT10-468': 'Insel Usedom',
    'BT10-469': 'Schwerin',
    'BT10-470': 'Mecklenburgische Schweiz',
    'BT10-471': 'Mecklenburgische Seenplatte',
    'BT10-472': 'Ostseeküste Mecklenburg',
    'BT10-473': 'Vorpommern',
    'BT10-475': 'Braunschweiger Land',
    'BT10-480': 'Emsland',
    'BT10-481': 'Grafschaft Bentheim',
    'BT10-484': 'Harz',
    'BT10-485': 'Lüneburger Heide',
    'BT10-486': 'Mittelweser',
    'BT10-489': 'Nordsee (Niedersachsen)',
    'BT10-491': 'Osnabrücker Land',
    'BT10-493': 'Ostfriesland',
    'BT10-496': 'Weserbergland',
    'BT10-497': 'Bergisches Land',
    'BT10-501': 'Münsterland',
    'BT10-502': 'Niederrhein',
    'BT10-503': 'Ruhrgebiet',
    'BT10-504': 'Sauerland',
    'BT10-505': 'Siegerland-Wittgenstein',
    'BT10-506': 'Teutoburger Wald',
    'BT10-508': 'Eifel',
    'BT10-509': 'Hunsrück',
    'BT10-513': 'Naheland',
    'BT10-514': 'Pfalz',
    'BT10-515': 'Rheinhessen',
    'BT10-517': 'Westerwald',
    'BT10-518': 'Merzig-Wadern',
    'BT10-519': 'Neunkirchen',
    'BT10-520': 'Region Saarbrücken',
    'BT10-521': 'Saarlouis',
    'BT10-522': 'St. Wendeler Land',
    'BT10-523': 'Erzgebirge',
    'BT10-528': 'Sächsisches Burgen- und Heideland ',
    'BT10-529': 'Sächsische Schweiz',
    'BT10-530': 'Sächsisches Elbland',
    'BT10-531': 'Vogtland',
    'BT10-533': 'Altmark',
    'BT10-534': 'Anhalt - Dessau - Wittenberg',
    'BT10-535': 'Elbe-Börde-Heide',
    'BT10-537': 'Harz',
    'BT10-539': 'Binnenland',
    'BT10-542': 'Holsteinische Schweiz',
    'BT10-552': 'Eichsfeld',
    'BT10-553': 'Harz',
    'BT10-555': 'Ostthüringen',
    'BT10-556': 'Rhön (Thüringen)',
    'BT10-559': 'Thüringer Wald',
    'BT10-560': 'Vogtland',
    'BT10-561': 'Kärnten',
    'BT10-562': 'Glarus',
    'BT10-563': 'Afrika',
    'BT10-564': 'Ägypten',
    'BT10-565': 'Asien',
    'BT10-566': 'Australien / Neuseeland / Südsee',
    'BT10-567': 'Balearen',
    'BT10-568': 'Benelux',
    'BT10-569': 'Bulgarien',
    'BT10-570': 'Deutschland',
    'BT10-571': 'Dominikanische Republik',
    'BT10-572': 'Frankreich',
    'BT10-573': 'Griechenland Festland',
    'BT10-574': 'Griechische Inseln',
    'BT10-575': 'Großbritannien / Irland',
    'BT10-576': 'Indischer Ozean',
    'BT10-577': 'Italien / Malta',
    'BT10-578': 'Kanaren',
    'BT10-579': 'Karibik',
    'BT10-580': 'Kroatien / Slowenien',
    'BT10-581': 'Kuba',
    'BT10-582': 'Mexiko',
    'BT10-583': 'Mittelamerika',
    'BT10-584': 'Naher Osten',
    'BT10-585': 'Österreich',
    'BT10-586': 'Osteuropa / Baltikum / Russland',
    'BT10-587': 'Portugal / Madeira / Azoren',
    'BT10-588': 'Schweiz',
    'BT10-589': 'Skandinavien / Island',
    'BT10-590': 'Spanien Festland',
    'BT10-591': 'Südamerika',
    'BT10-592': 'Thailand',
    'BT10-593': 'Tunesien / Marokko',
    'BT10-594': 'Türkei',
    'BT10-595': 'USA / Kanada / Hawaii',
    'BT10-596': 'VAE / Arab. Halbinsel',
    'BT10-597': 'Zypern',
    'BT10-626': 'Australien',
    'BT10-627': 'Baden-Württemberg',
    'BT10-628': 'Bayern',
    'BT10-629': 'Berlin',
    'BT10-630': 'Borneo / Brunei',
    'BT10-631': 'Borneo / Malaysia',
    'BT10-632': 'Brandenburg',
    'BT10-633': 'Brasilien',
    'BT10-634': 'Bremen',
    'BT10-635': 'Burgenland',
    'BT10-636': 'China',
    'BT10-637': 'Estland',
    'BT10-638': 'Finnland',
    'BT10-639': 'Großbritannien',
    'BT10-640': 'Hamburg',
    'BT10-641': 'Hessen',
    'BT10-642': 'Indonesien',
    'BT10-643': 'Irland',
    'BT10-644': 'Island',
    'BT10-645': 'Italien',
    'BT10-646': 'Kanada',
    'BT10-647': 'Kenia',
    'BT10-648': 'Kroatien',
    'BT10-649': 'Lettland',
    'BT10-650': 'Litauen',
    'BT10-651': 'Malaysia',
    'BT10-652': 'Malta',
    'BT10-653': 'Marokko',
    'BT10-654': 'Mecklenburg-Vorpommern',
    'BT10-655': 'Neuseeland',
    'BT10-656': 'Niederösterreich',
    'BT10-657': 'Niedersachsen',
    'BT10-658': 'Nordrhein-Westfalen',
    'BT10-659': 'Oberösterreich',
    'BT10-660': 'Philippinen',
    'BT10-661': 'Rheinland-Pfalz',
    'BT10-662': 'Russland',
    'BT10-663': 'Saarland',
    'BT10-664': 'Sachsen',
    'BT10-665': 'Sachsen-Anhalt',
    'BT10-666': 'Salzburg',
    'BT10-667': 'Schleswig-Holstein',
    'BT10-668': 'Slowenien',
    'BT10-669': 'Steiermark',
    'BT10-670': 'Südsee',
    'BT10-671': 'Südafrika',
    'BT10-672': 'Thüringen',
    'BT10-673': 'Tirol',
    'BT10-674': 'Tunesien',
    'BT10-675': 'USA',
    'BT10-676': 'Vereinigte Arabische Emirate',
    'BT10-677': 'Vorarlberg',
    'BT10-678': 'Wien',
    'BT10-691': 'Ostkap',
    'BT10-692': 'Nordkap',
    'BT10-693': 'Durban & Umgebung',
    'BT10-694': 'Nordwesten',
    'BT10-695': 'Isla Margarita/Venezuela',
    'BT10-696': 'Liechtenstein',
    'BT10-697': 'Andorra',
    'BT10-698': 'Usbekistan',
    'BT10-699': 'Zentralkroatien',
    'BT10-703': 'Botswana',
    'BT10-704': 'Malawi',
    'BT10-705': 'Syrien',
    'BT10-707': 'Äthiopien',
    'BT10-708': 'Sao Tome und Principe',
    'BT10-710': 'Albanien',
    'BT10-712': 'Slawonien',
    'BT10-713': 'Alpenregion Tegernsee Schliersee',
    'BT10-714': 'Ammersee-Lech',
    'BT10-715': 'Bayerischer Jura',
    'BT10-716': 'Berchtesgadener Land',
    'BT10-717': 'Chiemgau',
    'BT10-718': 'Chiemsee-Alpenland',
    'BT10-720': 'Haßberge',
    'BT10-721': 'Inn-Salzach',
    'BT10-725': 'Pfaffenwinkel',
    'BT10-727': 'Starnberger 5-Seenland',
    'BT10-728': 'Tölzer Land',
    'BT10-729': 'Zugspitz Region',
    'BT10-731': 'Nordhessen',
    'BT10-732': 'Rheingau',
    'BT10-733': 'Land zwischen Elbe und Weser',
    'BT10-734': 'Bonn und Rhein-Sieg',
    'BT10-735': 'Die Bergischen Drei',
    'BT10-736': 'Düsseldorf und Kreis Mettmann',
    'BT10-737': 'Köln und Rhein-Erft-Kreis',
    'BT10-738': 'Romantischer Rhein',
    'BT10-739': 'Ahr',
    'BT10-740': 'Hainich',
    'BT10-741': 'Ostsee',
    'BT10-742': 'Nordsee (Schleswig-Holstein)',
    'BT10-743': 'Odenwald',
    'BT10-744': 'Frankfurt Rhein-Main',
    'BT10-745': 'Lahntal',
    'BT10-746': 'Spessart - Kinzigtal',
    'BT10-747': 'Insel Rügen und Hiddensee',
    'BT10-748': 'Region Hannover',
    'BT10-749': 'Oldenburg und Oldenburger Münsterland',
    'BT10-750': 'Eifel und Region Aachen',
    'BT10-751': 'Mosel-Saar',
    'BT10-752': 'Oberlausitz',
    'BT10-753': 'Saale - Unstrut',
    'BT10-754': 'Kyffhäuser',
    'BT10-755': 'Saale - Thüringer Schiefergebirge',
    'BT10-756': 'Herzogtum Lauenburg',
    'BT10-757': 'Region Bremen',
    'BT10-758': 'Saarpfalz-Kreis',
    'BT10-759': 'Uruguay',
    'BT10-760': 'St. Kitts und Nevis',
    'BT10-763': 'Plitvicer Seen',
    'BT10-772': 'Washington D.C.',
    'BT10-777': 'Nordfriesland',
    'BT10-778': 'Senegal',
    'BT10-780': 'Äthiopien',
    'BT10-782': 'Japan',
    'BT10-1008': ' Rundreisen Zentralafrika',
    'BT10-786': 'Sambia',
    'BT10-787': 'Simbabwe',
    'BT10-1007': ' Rundreisen Westafrika',
    'BT10-1006': ' Rundreisen Nordafrika',
    'BT10-794': 'Bolivien',
    'BT10-795': 'Bergstraße',
    'BT10-1004': ' Rundreisen Südafrika/Botswana/Namibia/Sambia',
    'BT10-805': 'Altes Land',
    'BT10-806': 'Bremer Umland',
    'BT10-807': 'Ostfriesische Inseln',
    'BT10-811': 'Potsdam',
    'BT10-812': 'Region Bremerhaven',
    'BT10-813': 'Bayerisch Schwaben',
    'BT10-814': 'Lahntal',
    'BT10-815': 'Aargau',
    'BT10-816': 'Serbien',
    'BT10-817': 'Montenegro',
    'BT10-818': 'Paraguay',
    'BT10-819': 'Bahia',
    'BT10-820': 'Centre',
    'BT10-1013': 'Thessaloniki',
    'BT10-1012': 'Aserbaidschan',
    'BT10-828': 'Mazedonien',
    'BT10-830': 'Jura',
    'BT10-831': 'Rundreisen',
    'BT10-996': 'Rundreisen Großbritannien/Irland',
    'BT10-994': 'Rundreisen Deutschland/Österreich/Schweiz',
    'BT10-993': 'Rundreisen Mitteleuropa',
    'BT10-995': 'Rundreisen Benelux/Frankreich',
    'BT10-991': 'Nicaragua',
    'BT10-990': 'Rundreisen Südosteuropa',
    'BT10-999': 'Rundreisen Naher Osten & Vorderasien',
    'BT10-858': 'Rundreisen Skandinavien',
    'BT10-998': 'Rundreisen Osteuropa',
    'BT10-988': 'Rundreisen Indien & Sri Lanka',
    'BT10-868': 'Rundreisen USA & Kanada',
    'BT10-1000': 'Rundreisen Südostasien',
    'BT10-987': 'Rundreisen China & Japan',
    'BT10-889': 'Rundreisen Zentralasien',
    'BT10-891': 'Rundreisen Mittel- & Südamerika',
    'BT10-893': 'Rundreisen Afrika',
    'BT10-989': 'Rundreisen Südeuropa',
    'BT10-897': 'Rundreisen Australien & Neuseeland',
    'BT10-986': 'Rundreisen Karibik',
    'BT10-899': 'Algerien',
    'BT10-900': 'Halbinsel Samana',
    'BT10-901': 'Landesinnere',
    'BT10-903': 'Baja California',
    'BT10-904': 'Golf von Mexiko',
    'BT10-905': 'Halbinsel Yucatán',
    'BT10-906': 'Jalisco',
    'BT10-907': 'Riviera Maja & Insel Cozumel',
    'BT10-908': 'Tabasco',
    'BT10-909': 'Carnica Region Rosental',
    'BT10-910': 'Liesertal-Maltatal',
    'BT10-911': 'Region Villach - Faaker See - Ossiacher See ',
    'BT10-912': 'Mittelkärnten',
    'BT10-913': 'Lavanttal',
    'BT10-914': 'Wörthersee',
    'BT10-915': 'Millstätter See',
    'BT10-916': 'Hohe Tauern',
    'BT10-917': 'Region Bad Kleinkirchheim',
    'BT10-918': 'Klopeiner See - Südkärnten',
    'BT10-919': 'Nassfeld-Pressegger See - Lesachtal',
    'BT10-920': 'Katschberg-Rennweg',
    'BT10-921': 'Achensee',
    'BT10-922': 'Alpbachtal Seenland',
    'BT10-923': 'Defereggental',
    'BT10-924': 'Ferienland Kufstein',
    'BT10-925': 'Hall-Wattens',
    'BT10-926': 'Imst',
    'BT10-927': 'St. Anton am Arlberg',
    'BT10-928': 'Hochpustertal',
    'BT10-929': 'Innsbruck und Umgebung',
    'BT10-930': 'Kaiserwinkl',
    'BT10-931': 'Hohe Salve Region',
    'BT10-932': 'Brixental',
    'BT10-933': 'St. Johann in Tirol',
    'BT10-934': 'Lechtal',
    'BT10-935': 'Naturparkregion Reutte',
    'BT10-936': 'Osttirol',
    'BT10-937': 'Lienzer Dolomiten',
    'BT10-938': 'Mitteltirol',
    'BT10-939': 'Westtirol',
    'BT10-940': 'Ötztal',
    'BT10-941': 'Mayrhofen-Hippach',
    'BT10-942': 'Paznaun-Ischgl',
    'BT10-943': 'Pillerseetal',
    'BT10-944': 'Pitztal',
    'BT10-945': 'Serfaus-Fiss-Ladis',
    'BT10-946': 'Silberregion Karwendel',
    'BT10-947': 'Sonnenplateau Mieming',
    'BT10-948': 'Tannheimer Tal',
    'BT10-949': 'Tux',
    'BT10-950': 'Tiroler Oberland',
    'BT10-951': 'Wilder Kaiser',
    'BT10-952': 'Wildschönau',
    'BT10-953': 'Wipptal',
    'BT10-954': 'Tiroler Zugspitz Arena',
    'BT10-955': 'Attersee - Attergau',
    'BT10-956': 'Bad Hall - Kremsmünster',
    'BT10-957': 'Böhmerwald',
    'BT10-958': 'Dachstein Salzkammergut',
    'BT10-959': 'Donau Oberösterreich',
    'BT10-960': 'Hausruckviertel',
    'BT10-961': 'Innviertel',
    'BT10-962': 'Mondsee - Irrsee',
    'BT10-963': 'Mühlviertel',
    'BT10-964': 'Mühlviertler Alm',
    'BT10-965': 'Nationalpark Ennstal',
    'BT10-966': 'Nationalpark Kalkalpen',
    'BT10-967': 'Oberes Kremstal',
    'BT10-968': 'Pyhrn-Priel',
    'BT10-969': 'Salzkammergut',
    'BT10-970': 'Steyrtal',
    'BT10-971': 'Traunsee',
    'BT10-972': 'Vitalwelt Bad Schallerbach',
    'BT10-973': 'Wolfgangsee',
    'BT10-975': 'Belize',
    'BT10-976': 'Mittelägypten & Libysche Wüste',
    'BT10-977': 'Mittelmeerküste',
    'BT10-978': 'Rotes Meer',
    'BT10-1016': 'Curacao',
    'BT10-981': 'Saint-Barthelemy',
    'BT10-1015': 'Bonaire',
    'BT10-1014': 'Aruba',
    'BT10-1003': 'Rundreisen Ägypten/Nilkreuzfahrten',
    'BT10-1001': 'Rundreisen Ostafrika',
    'BT10-985': 'Kitzbühel',
    'BT10-1009': 'Moldawien',
    'BT10-1010': 'Schaffhausen',
    'BT10-1011': 'Pyrenäen',
    'BT10-1017': 'Kreuzfahrten',
}
