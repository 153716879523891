<div class="image-loader" *ngIf="loading; else notLoading">
    <div class="preloader-wrapper active">
        <div class="spinner-layer spinner-smg-only">
            <div class="circle-clipper left">
                <div class="circle"></div>
            </div>
            <div class="gap-patch">
                <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
                <div class="circle"></div>
            </div>
        </div>
    </div>
</div>

<ng-template #notLoading>
    <div class="images">
        <label *ngFor="let image of images; let i = index">
            <input [(ngModel)]="selectImage" type="radio" [value]="i"/>
            <img src="{{image['url']}}">
        </label>
    </div>
</ng-template>
