import {TourOperatorModel} from '../models/tour-operator.model';

// TODO: think this file could be removed :)

export const TOUR_OPERATORS: Array<TourOperatorModel> = [
        {
            rawCode: '4YBK',
            code: 'TOP-4YBK',
            name: '4yourbooking',
            uraniaName: '4yourbooking GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: '5VF',
            code: 'TOP-5VF',
            name: '5 vor Flug',
            uraniaName: '5 vor Flug GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'AGR',
            code: 'TOP-AGR',
            name: 'Anton Götten Reisen',
            uraniaName: 'Anton Götten Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'AIDA',
            code: 'TOP-AIDA',
            name: 'AIDA Cruises',
            uraniaName: 'AIDA Cruises',
            enabled: '1',
            crs: '',
            directBooking: '1',
            flags: ['NEO', 'AMADEUS', 'MERLIN', 'NX']
        },
        {
            rawCode: 'AIR',
            code: 'TOP-AIR',
            name: 'Air Marin',
            uraniaName: 'air marin',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ALD',
            code: 'TOP-ALD',
            name: 'Aldiana',
            uraniaName: 'Aldiana GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'ALL',
            code: 'TOP-ALL',
            name: 'Alltours',
            uraniaName: 'Alltours Flugreisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'AME',
            code: 'TOP-AME',
            name: 'Ameropa',
            uraniaName: 'AMEROPA-REISEN GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'ANEX',
            code: 'TOP-ANEX',
            name: 'ANEX Tour',
            uraniaName: 'Anex Tour GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NH', 'PA', 'X', 'NEO', 'SalesMarket-DE']
        },
        {
            rawCode: 'AOVO',
            code: 'TOP-AOVO',
            name: 'aovo Reisen',
            uraniaName: 'aovo Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'AMADEUS', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'AROS',
            code: 'TOP-AROS',
            name: 'A-ROSA',
            uraniaName: 'A-ROSA',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NEO', 'AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'ATID',
            code: 'TOP-ATID',
            name: 'Airtours Deutschland',
            uraniaName: 'TUI Deutschland GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'KATI', 'IRISPLUS', 'IX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ATK',
            code: 'TOP-ATK',
            name: 'Attika Reisen',
            uraniaName: 'Attika Reisen AG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'AZA',
            code: 'TOP-AZA',
            name: 'Azamara Cruises',
            uraniaName: 'Azamara Cruises',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'BAV',
            code: 'TOP-BAV',
            name: 'Bavaria Fernreisen',
            uraniaName: 'Bavaria Fernreisen',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'BCH',
            code: 'TOP-BCH',
            name: 'BENTOUR Reisen',
            uraniaName: 'Bentour T¸rkei Reisen AG Suisse888 (Schweiz)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'BIG',
            code: 'TOP-BIG',
            name: 'BigX-tra Touristik',
            uraniaName: 'BigXtra Touristik GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'BTM',
            code: 'TOP-BTM',
            name: 'Bodenmais',
            uraniaName: 'Bodenmais Tourismus und Marketing GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'BU',
            code: 'TOP-BU',
            name: 'Bucher Reisen',
            uraniaName: 'Bucher Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'BYE',
            code: 'TOP-BYE',
            name: 'byebye.de',
            uraniaName: 'BYE.bye GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'CED',
            code: 'TOP-CED',
            name: 'CroisiEurope',
            uraniaName: 'CroisiEurope',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'CBM',
            code: 'TOP-CBM',
            name: 'Club Blaues Meer Reisen',
            uraniaName: 'Club Blaues Meer Reisen e. K.',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'CDE',
            code: 'TOP-CDE',
            name: 'Corendon International Travel B.V.',
            uraniaName: 'Corendon Touristic GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'CEL',
            code: 'TOP-CEL',
            name: 'Celebrity Cruises',
            uraniaName: 'Celebrity Cruises',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NEO', 'AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'CFI',
            code: 'TOP-CFI',
            name: 'Condor',
            uraniaName: 'Condor',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'CST',
            code: 'TOP-CST',
            name: 'Costa Kreuzfahrten',
            uraniaName: 'Costa Kreuzfahrten',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'DAF',
            code: 'TOP-DAF',
            name: 'HAPPY HOLIDAY',
            uraniaName: 'HAPPY HOLIDAY',
            enabled: '1',
            crs: 'NEO',
            directBooking: '1',
            flags: ['NH', 'PA', 'X', 'NEO', 'SalesMarket-DE']
        },
        {
            rawCode: 'DER',
            code: 'TOP-DER',
            name: 'DERTOUR',
            uraniaName: 'DER Touristik Frankfurt GmbH & Co. KG',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'DES',
            code: 'TOP-DES',
            name: 'D.E.S. Travelers',
            uraniaName: 'D.E.S. Travelers',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'DTA',
            code: 'TOP-DTA',
            name: 'dta Touristik',
            uraniaName: 'dta Touristik',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NF', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ECC',
            code: 'TOP-ECC',
            name: 'Ecco Reisen',
            uraniaName: 'ECCO-Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'ER',
            code: 'TOP-ER',
            name: 'Engel Reisen',
            uraniaName: 'Engel Reisen GmbH Co. KG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX']
        },
        {
            rawCode: 'ETI',
            code: 'TOP-ETI',
            name: 'Express Travel International',
            uraniaName: 'Express Travel International Suisse (Schweiz)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'EXPE',
            code: 'TOP-EXPE',
            name: 'TravelNow',
            uraniaName: 'TravelNow',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'AMADEUS', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FER',
            code: 'TOP-FER',
            name: 'FERIEN Touristik',
            uraniaName: 'FERIEN Touristik GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'X', 'NEO', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'FIT',
            code: 'TOP-FIT',
            name: 'FIT Reisen',
            uraniaName: 'FIT Gesellschaft f¸r gesundes Reisen mbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FLT',
            code: 'TOP-FLT',
            name: 'Flamingo Tours',
            uraniaName: 'Flamingo Tours GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FLYD',
            code: 'TOP-FLYD',
            name: '1-2-Fly Deutschland',
            uraniaName: 'FLYD (1-2-Fly)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'KATI', 'IRISPLUS', 'IX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FOR',
            code: 'TOP-FOR',
            name: 'For You Travel',
            uraniaName: 'For You Travel GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FOX',
            code: 'TOP-FOX',
            name: 'Fox-Tours',
            uraniaName: 'FOX-TOURS Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FTC',
            code: 'TOP-FTC',
            name: 'FTI Cruises',
            uraniaName: 'FTI Cruises',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NEO', 'AMADEUS', 'MERLIN', 'NX']
        },
        {
            rawCode: 'FTI',
            code: 'TOP-FTI',
            name: 'FTI Touristik',
            uraniaName: 'FTI Touristik GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'KATI', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'FUV',
            code: 'TOP-FUV',
            name: 'Fit und Vital Reisen',
            uraniaName: 'Fit und Vital Reisen',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'GMI',
            code: 'TOP-GMI',
            name: 'Germania',
            uraniaName: 'Germania Fluggesellschaft mbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'GRUB',
            code: 'TOP-GRUB',
            name: 'Gruber Reisen',
            uraniaName: 'GRUBER-reisen Veranstalter GmbH (÷sterreich)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'GT',
            code: 'TOP-GT',
            name: 'Goethe Travel GmbH',
            uraniaName: 'Goethe Travel GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'HDE',
            code: 'TOP-HDE',
            name: 'HOTEL DE',
            uraniaName: 'HOTEL DE AG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'AMADEUS', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'HERM',
            code: 'TOP-HERM',
            name: 'Maris Reisen',
            uraniaName: 'Maris Reisen GmbH & Co. KG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'HHT',
            code: 'TOP-HHT',
            name: 'H&H Touristik',
            uraniaName: 'H&H Touristik GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'IBO',
            code: 'TOP-IBO',
            name: 'Ibero Tours',
            uraniaName: 'IBERO TOURS GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'ICC',
            code: 'TOP-ICC',
            name: 'ICC Involatus Carrier Consulting',
            uraniaName: 'Involatus Carrier Consulting GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ITS',
            code: 'TOP-ITS',
            name: 'ITS Reisen',
            uraniaName: 'ITS Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ITSB',
            code: 'TOP-ITSB',
            name: 'ITS Reisen Billa',
            uraniaName: 'ITS Billa Reisen (÷sterreich)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ITSX',
            code: 'TOP-ITSX',
            name: 'ITS Reisen (X)',
            uraniaName: 'DER Touristik Kˆln GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ITT',
            code: 'TOP-ITT',
            name: 'ITT Ferien Pur',
            uraniaName: 'ITT GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'JAHN',
            code: 'TOP-JAHN',
            name: 'Jahn Reisen',
            uraniaName: 'Jahn Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'LMX',
            code: 'TOP-LMX',
            name: 'LMX Touristik',
            uraniaName: 'LMX Touristik',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NF', 'X', 'NEO', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'LUX',
            code: 'TOP-LUX',
            name: 'Luxair Tours',
            uraniaName: 'Luxair Tours GmbH (Luxemburg)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'MED',
            code: 'TOP-MED',
            name: 'Medina Reisen',
            uraniaName: 'MEDINA-REISEN GmbH & Co. KG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'MIS',
            code: 'TOP-MIS',
            name: 'Misir Sonnenlandreisen',
            uraniaName: 'Misir Sonnenlandreisen',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'MLA',
            code: 'TOP-MLA',
            name: 'Malta Direkt Reisen',
            uraniaName: 'Holiday Malta GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'MON',
            code: 'TOP-MON',
            name: 'Mondial Reisen',
            uraniaName: 'Mondial Reisen und Hotelreservierungs GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'MPR',
            code: 'TOP-MPR',
            name: 'Mediplus Reisen',
            uraniaName: 'Mediplus Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'MSC',
            code: 'TOP-MSC',
            name: 'MSC Kreuzfahrten',
            uraniaName: 'MSC Kreuzfahrten',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NEO', 'AMADEUS', 'MERLIN', 'NX']
        },
        {
            rawCode: 'MT',
            code: 'TOP-MT',
            name: 'Mouzenidis Travel',
            uraniaName: 'Mouzenidis Travel',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'MV',
            code: 'TOP-MV',
            name: 'Mount Vacation d.o.o.',
            uraniaName: 'Mount Vacation d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO']
        },
        {
            rawCode: 'MWR',
            code: 'TOP-MWR',
            name: 'Meiers Weltreisen',
            uraniaName: 'Meier`s Weltreisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'KATI', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'MYT',
            code: 'TOP-MYT',
            name: 'My TTravel',
            uraniaName: 'My TTravel GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'NCL',
            code: 'TOP-NCL',
            name: 'Norwegian Cruise Line',
            uraniaName: 'Norwegian Cruise Line',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'NEC',
            code: 'TOP-NEC',
            name: 'Neckermann Reisen',
            uraniaName: 'Neckermann Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'NER',
            code: 'TOP-NER',
            name: 'Neckermann Reisen Individual',
            uraniaName: 'Neckermann Reisen Individual',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'OASI',
            code: 'TOP-OASI',
            name: 'Oasis Travel',
            uraniaName: 'Oasis Travel GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'OFT',
            code: 'TOP-OFT',
            name: 'OFT Reisen',
            uraniaName: 'OFT REISEN Organisation f¸r Touristik GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'OGE',
            code: 'TOP-OGE',
            name: 'Öger Tours',
            uraniaName: 'ÖGER TOURS GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'OLI',
            code: 'TOP-OLI',
            name: 'Olimar',
            uraniaName: 'Olimar',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'PHX',
            code: 'TOP-PHX',
            name: 'Phoenix Reisen',
            uraniaName: 'Phoenix Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'PRIM',
            code: 'TOP-PRIM',
            name: 'Prima Reisen',
            uraniaName: 'Prima Reisen GmbH (÷sterreich)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'RCC',
            code: 'TOP-RCC',
            name: 'Royal Caribbean Cruise Line',
            uraniaName: 'Royal Caribbean Cruise Line',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'RHOM',
            code: 'TOP-RHOM',
            name: 'Rhomberg Reisen',
            uraniaName: 'Rhomberg Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'RIVA',
            code: 'TOP-RIVA',
            name: 'Riva Tours',
            uraniaName: 'I.D. Riva Tours GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SAS',
            code: 'TOP-SAS',
            name: 'Sun & Sea Travel',
            uraniaName: 'Sun & Sea Travel GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SIT',
            code: 'TOP-SIT',
            name: 'SITALIA',
            uraniaName: 'SITALIA GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SLR',
            code: 'TOP-SLR',
            name: 'Schauinsland Reisen',
            uraniaName: 'Schauinsland-Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'SLRD',
            code: 'TOP-SLRD',
            name: 'Schauinsland Reisen (X)',
            uraniaName: 'Schauinsland-Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'SMG',
            code: 'TOP-SMG',
            name: 'Schmetterling',
            uraniaName: 'Schmetterling AIR',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'AR', 'FH', 'KATI', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SNOW',
            code: 'TOP-SNOW',
            name: 'SnowTrex',
            uraniaName: 'SnowTrex',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SO',
            code: 'TOP-SO',
            name: 'Solaris Travel',
            uraniaName: 'Solaris Travel',
            enabled: '1',
            crs: 'NEO',
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SPOT',
            code: 'TOP-SPOT',
            name: 'Spot Reisen',
            uraniaName: 'Spot Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'SPRI',
            code: 'TOP-SPRI',
            name: 'Springer Reisen',
            uraniaName: 'Springer Helios Reiseveranstalter Gesellschaft m.b.H',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'ST',
            code: 'TOP-ST',
            name: 'Suntrips Reisen',
            uraniaName: 'SunTrips Reisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'STT',
            code: 'TOP-STT',
            name: 'Schmetterling Travel Team',
            uraniaName: 'Schmetterling Travel Team GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'SXS',
            code: 'TOP-SXS',
            name: 'SunExpress Deutschland',
            uraniaName: 'SunExpress Deutschland GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TFLY',
            code: 'TOP-TFLY',
            name: 'TUIFLY.com',
            uraniaName: 'TUIfly Vermarktungs GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'AMADEUS', 'MERLIN', 'IRISPLUS', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TIP',
            code: 'TOP-TIP',
            name: 'Tip-Flug',
            uraniaName: 'Tip Flug',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TISC',
            code: 'TOP-TISC',
            name: 'Tischler Reisen',
            uraniaName: 'Tischler Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'FAIRPLAY', 'SalesMarket-DE']
        },
        {
            rawCode: 'TJAX',
            code: 'TOP-TJAX',
            name: 'Travelix',
            uraniaName: 'DER Touristik Kˆln GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TMG',
            code: 'TOP-TMG',
            name: 'TMG Der Reisejoker',
            uraniaName: 'TMG Der Reisejoker GmbH',
            enabled: '1',
            crs: 'NEO',
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'NX']
        },
        {
            rawCode: 'TOC',
            code: 'TOP-TOC',
            name: 'Thomas Cook',
            uraniaName: 'Thomas Cook Reisen (K¸rzel TOC)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TOR',
            code: 'TOP-TOR',
            name: 'Thomas Cook Reisen Individual',
            uraniaName: 'Thomas Cook Reisen Individual',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TREX',
            code: 'TOP-TREX',
            name: 'TravelTrex',
            uraniaName: 'TravelTrex',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'TT',
            code: 'TOP-TT',
            name: 'TransOcean Kreuzfahrten',
            uraniaName: 'TransOcean Kreuzfahrten',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN']
        },
        {
            rawCode: 'TUIC',
            code: 'TOP-TUIC',
            name: 'TUI Cruises',
            uraniaName: 'TUI Cruises',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['AMADEUS', 'MERLIN', 'NEO']
        },
        {
            rawCode: 'TUID',
            code: 'TOP-TUID',
            name: 'TUI Deutschland',
            uraniaName: 'TUI Deutschland GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'KATI', 'AMADEUS', 'MERLIN', 'IRISPLUS', 'IX', 'SalesMarket-DE']
        },
        {
            rawCode: 'UNI',
            code: 'TOP-UNI',
            name: 'Unique World GmbH',
            uraniaName: 'Unique World GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX']
        },
        {
            rawCode: 'VFLY',
            code: 'TOP-VFLY',
            name: 'vfly',
            uraniaName: 'Vtours GmbH (VFLY)',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'VTO',
            code: 'TOP-VTO',
            name: 'Vtours GmbH',
            uraniaName: 'Vtours GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'X', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'VTOI',
            code: 'TOP-VTOI',
            name: 'Vtours International',
            uraniaName: 'Vtours International AG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'X', 'NEO', 'AMADEUS', 'MERLIN', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'X5VF',
            code: 'TOP-X5VF',
            name: '5 vor Flug (X)',
            uraniaName: '5 vor Flug GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XALL',
            code: 'TOP-XALL',
            name: 'Alltours (X)',
            uraniaName: 'alltours Flugreisen GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XBIG',
            code: 'TOP-XBIG',
            name: 'BigX-tra Touristik (X)',
            uraniaName: 'BigXtra Touristik GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XBU',
            code: 'TOP-XBU',
            name: 'Bucher Reisen (X)',
            uraniaName: 'Bucher Reisen',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XETI',
            code: 'TOP-XETI',
            name: 'Express Travel Interational (X)',
            uraniaName: 'Express Travel Interational',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XFLT',
            code: 'TOP-XFLT',
            name: 'Flamingo Tours (X)',
            uraniaName: 'Flamingo Tours GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XFTI',
            code: 'TOP-XFTI',
            name: 'FTI Touristik (X)',
            uraniaName: 'FTI Touristik GmbH',
            enabled: '1',
            crs: 'NEO',
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XJAH',
            code: 'TOP-XJAH',
            name: 'Jahn Reisen (X)',
            uraniaName: 'DER Touristik Kˆln GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XPOD',
            code: 'TOP-XPOD',
            name: 'TROPO',
            uraniaName: 'tropo GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'XPUR',
            code: 'TOP-XPUR',
            name: 'XPUR',
            uraniaName: 'XPUR GmbH',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'X', 'NEO', 'NX', 'SalesMarket-DE']
        },
        {
            rawCode: 'JAHN',
            code: 'TOP-JANA',
            name: 'Jahn Reisen',
            uraniaName: 'Jahn Reisen',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NF', 'NEO', 'AMADEUS', 'MERLIN', 'NX']
        },
        {
            rawCode: 'JT',
            code: 'TOP-JT',
            name: 'JT Touristik',
            uraniaName: 'JT Touristik – Eine Marke der Lidl Digital International GmbH & Co.KG',
            enabled: '1',
            crs: null,
            directBooking: '1',
            flags: ['NH', 'PA', 'NEO', 'NX', 'X', 'SalesMarket-DE']
        },
        {
            rawCode: 'PALM.SI',
            code: 'TOP-PALM.SI',
            name: 'Palma',
            uraniaName: 'Palma d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'KOMP.SI',
            code: 'TOP-KOMP.SI',
            name: 'Kompas',
            uraniaName: 'Kompas d.d.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'PALH.HR',
            code: 'TOP-PALH.HR',
            name: 'Palma Travel',
            uraniaName: 'Palma Travel d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'SONH.SI',
            code: 'TOP-SONH.SI',
            name: 'Sonček',
            uraniaName: 'Sonček d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'SON.SI',
            code: 'TOP-SON.SI',
            name: 'Sonček',
            uraniaName: 'Sonček d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'SJK.SI',
            code: 'TOP-SJK.SI',
            name: 'Sajko Turizem',
            uraniaName: 'Sajko Turizem d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'BON.SI',
            code: 'TOP-BON.SI',
            name: 'Bonus',
            uraniaName: 'Bonus, Sergeja Zubkov s.p.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'CENT.SI',
            code: 'TOP-CENT.SI',
            name: 'Center Turizem',
            uraniaName: 'Center Turizem d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'TALP.SI',
            code: 'TOP-TALP.SI',
            name: 'Transalp',
            uraniaName: 'Transalp d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'ZND.SI',
            code: 'TOP-ZND.SI',
            name: 'Žniders',
            uraniaName: 'Žniders d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'LTO.SI',
            code: 'TOP-LTO.SI',
            name: 'Destinacija Rogla',
            uraniaName: 'Destinacija Rogla',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'KZG.HR',
            code: 'TOP-KZG.HR',
            name: 'Kompas Zagreb',
            uraniaName: 'Kompas Zagreb d.d.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'UNIL.HR',
            code: 'TOP-UNIL.HR',
            name: 'Uniline',
            uraniaName: 'Uniline d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'MV.SI',
            code: 'TOP-MV.SI',
            name: 'Mount Vacation',
            uraniaName: 'Mount Vacation d.o.o.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'SalesMarket-SI']
        },
        {
            rawCode: 'CLEV',
            code: 'TOP-CLEV',
            name: 'clevertours.com',
            uraniaName: 'clevertours.com GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        },
        {
            rawCode: 'XANE',
            code: 'TOP-XANE',
            name: 'ANEX Tour',
            uraniaName: 'ANEX Tour GmbH',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['PA', 'NEO', 'SalesMarket-DE']
        },
        {
            rawCode: 'CAI',
            code: 'TOP-CAI',
            name: 'Corendon Airlines',
            uraniaName: 'Corendon Airlines',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NF', 'NEO', 'SalesMarket-DE']
        },
        {
            rawCode: 'LMXI',
            code: 'TOP-LMXI',
            name: 'Last Minute Express S.L.U.',
            uraniaName: 'Last Minute Express S.L.U.',
            enabled: '1',
            crs: null,
            directBooking: '0',
            flags: ['NH', 'PA', 'X', 'NEO', 'AMADEUS', 'MERLIN', 'SalesMarket-DE']
        }
];
