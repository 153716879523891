import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@gosuite/icons';

import { CategoryStarsComponent } from './category-stars/category-stars.component';


@NgModule({
	declarations: [ CategoryStarsComponent ],
	exports: [ CategoryStarsComponent ],
	imports: [
		CommonModule,
		IconModule,
	]
})
export class CategoryStarsModule {}
