export const TARGET_GROUP_TYPE_MAPPING = {
    // 'GT03-ADON',
    'GT03-BETO': 'Strandurlauber',
    // 'GT03-BUTR',
    'GT03-CITR': 'Städtereisender',
    'GT03-CLTO': 'Cluburlauber',
    // 'GT03-CRSH',
    // 'GT03-FAMI',
    'GT03-GOTO': 'Golfurlauber',
    // 'GT03-HOMO',
    // 'GT03-MOBI',
    'GT03-MOTO': 'Bergurlauber',
    'GT03-WOCO': 'Seminar-/Tagungsreisende'
}