export const TARGET_GROUP_TYPES: Array<string> = [
	// 'GT03-ADON',
	'GT03-BETO',
	// 'GT03-BUTR',
	'GT03-CITR',
	'GT03-CLTO',
	// 'GT03-CRSH',
	// 'GT03-FAMI',
	'GT03-GOTO',
	// 'GT03-HOMO',
	// 'GT03-MOBI',
	'GT03-MOTO',
	'GT03-WOCO'
];
