export const ATTRIBUTE_TYPE_MAPPING = {
    'GT03-BEAC/ST03-DIRE': 'Direkt am Strand',
    'GT03-BEAC/ST03-SAND': 'Sandstrand',
    'GT05-AICO': 'Klimaanlage',
    'GT03-CAPA': 'Parkplatz',
    'GT03-INTE': 'Internet',
    'GT03-WHAC': 'Behindertengerecht',
    'GT03-POOL': 'Pool',
    'GT03-SPAA': 'Spa',
    'GT03-SAUN': 'Sauna',
    'GT08-WELL': 'Wellness',
    'GT03-DIVE': 'Tauchen',
    'GT03-GOLF': 'Golf',
    'GT03-TENN': 'Tennis',
    'GT03-GYMN': 'Gymnastik',

}