<section class="generate-deep-link">
	<button (click)="handleGenerateDeeplinkButton()">Deeplink Generieren</button>
</section>

<!-- (click)="handleSpecialOfferItemClick(deeplink)" -->
<section class="special-offers">
	<div class="special-offers--container">
		<div class="special-offer" *ngFor="let topBox of (topBoxes$ | async)">

			<div *ngIf="topBox.id" class="special-offer--delete" (click)="deleteTopBox(topBox)">
				<svg icon type="material-delete"></svg>
			</div>

			<img class="special-offer--image" [src]="topBox.imageSrc">
			<div class="special-offer--text-overlay"></div>
			<span class="special-offer--text">{{topBox.text}}</span>
		</div>
	</div>
</section>
