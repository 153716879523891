import { Component, OnInit } from '@angular/core';

import { TopboxService } from './services/topbox.service';
import { VanessaService } from './services/vanessa.service';

@Component({
 	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
	public constructor(private topboxService: TopboxService, private vanessaService: VanessaService) {}

	public ngOnInit() {
		const cfgParam = (window.location.search || '')
			.substring(1)
			.split('&')
			.map(tmp => tmp.split('='))
			.find(tmp => tmp[0] === 'cfg');

		if (cfgParam && cfgParam[1]) {
			this.topboxService.setCfg(cfgParam[1]);
			this.vanessaService.setCfg(cfgParam[1]);
		}
	}
}
